import React from 'react';
import { connect } from 'react-redux';
import GridActionsCell from '../../../../shared/GridActionsCell/GridActionsCell';

class ActionsCell extends React.Component {

    render() {
        const { rowDetail, selectedRows, activeDepartment, microteams } = this.props;

        return <GridActionsCell
            rowDetail={rowDetail}
            selectedRows={selectedRows}
            isDelegate={activeDepartment?.isDelegate}
            microteams={microteams}
        />
    }
};

const mapStateToProps = state => ({
    selectedRows: state.mydepartments.selectedRows,
    microteams: state.session.activeDepartment.microteams,
    activeDepartment: state.session.activeDepartment
})

export default connect(mapStateToProps)(ActionsCell);