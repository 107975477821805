import React from 'react';
import { connect } from 'react-redux';
import { setConsolidatedShowRecruiters } from '../../../../../store/actions';
import { validatePermission } from '../../../../../utils/auth';
import GridRecruitersCell from '../../../../shared/GridRecruitersCell/GridRecruitersCell';

class RecruitersCell extends React.Component {

    render() {
        const { recruiters, user, isDelegate, showRecruiters, setConsolidatedShowRecruiters } = this.props;
        const showEdit = isDelegate || validatePermission('EDIT_RECRUITERS', user.roleName);
        return <GridRecruitersCell
            recruiters={recruiters}
            showEdit={showEdit}
            onClickEdit={() => setConsolidatedShowRecruiters(!showRecruiters)}
        />
    }
};

const mapStateToProps = state => ({
    user: state.session.user,
    showRecruiters: state.consolidated.showRecruiters,
    isDelegate: state.consolidated.isDelegate
})

const mapDispatchToProps = (dispatch, props) => ({
    setConsolidatedShowRecruiters: (i) => dispatch(setConsolidatedShowRecruiters(i))
})

export default connect(mapStateToProps, mapDispatchToProps)(RecruitersCell);