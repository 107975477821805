import React from 'react';
import { connect } from 'react-redux';
import { PrimaryButton, DefaultButton, Dropdown, Panel, PanelType, Spinner, Icon } from '@fluentui/react';
import apiService from '../../../../services/api-service';
import { Page } from '../../../../constants/constants';
import { setUser, addNotification } from '../../../../store/actions';

class PreferredHomePage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            showChangePage: false,
            preferredPage: null,
            preferredDepartment: null,
            selectedPage: null,
            selectedDepartment: null,
            departments: []
        }

        this.setHomePage = this.setHomePage.bind(this);
        this.cancelHomePage = this.cancelHomePage.bind(this);
        this.changePage = this.changePage.bind(this);
        this.changeDepartment = this.changeDepartment.bind(this);
        this.renderDepartmentOption = this.renderDepartmentOption.bind(this);

        this.pages = [
            { key: Page.consolidatedOrders, text: "Consolidated Orders" },
            { key: Page.myDepartments, text: "My Departments" },
            { key: Page.allOrders, text: "All Orders" }
        ];
    }

    componentDidMount() {
        const { user } = this.props;

        const departments = user.officeLeaderDepartmentList.map(x => ({
            key: x.kf_financialhierarchyid,
            text: x.departmentName,
            data: x.isDelegate ? { icon: 'BusinessCenterLogo', style: { color: 'salmon' } } : undefined
        }));

        const preferredPage = this.pages.find(x => x.key === user.settings.preferredHomePage.page) || null;
        const preferredDepartment = departments.find(x => x.key === user.settings.preferredHomePage.data) || null;

        this.setState({
            departments,
            preferredPage,
            preferredDepartment,
            selectedPage: preferredPage,
            selectedDepartment: preferredDepartment
        });
    }

    setHomePage() {
        const { user } = this.props;
        const { selectedPage, selectedDepartment } = this.state;

        this.setState({ loading: true });

        apiService.saveDefaultHomePage(user.oid, selectedPage.key, selectedDepartment?.key || null)
            .then(data => {
                user.settings.preferredHomePage = {
                    page: selectedPage.key,
                    data: selectedDepartment?.key || null
                }
                this.props.setUser(user);
                this.props.addNotification({ type: 'success', text: 'Home Page changed' });
            })
            .finally(() => {
                this.setState({
                    preferredPage: selectedPage,
                    preferredDepartment: selectedDepartment,
                    showChangePage: false,
                    loading: false
                });
            });
    }

    cancelHomePage() {
        const { preferredPage, preferredDepartment } = this.state;

        this.setState({
            selectedPage: preferredPage,
            selectedDepartment: preferredPage?.key === Page.myDepartments ? preferredDepartment : null,
            showChangePage: false
        });
    }

    changePage(e, val) {
        this.setState({ selectedPage: val });
        if (val.key !== Page.myDepartments) {
            this.setState({ selectedDepartment: null });
        }
    }

    changeDepartment(e, val) {
        this.setState({ selectedDepartment: val })
    }

    renderDepartmentOption(option) {
        return (
            <div>
                {
                    option.data && option.data.icon &&
                    <Icon className='mr-2' style={option.data.style} iconName={option.data.icon} />
                }
                <span>{option.text}</span>
            </div>
        )
    }

    render() {
        const {
            preferredPage,
            preferredDepartment,
            departments,
            showChangePage,
            loading,
            selectedPage,
            selectedDepartment
        } = this.state;

        return (
            <>
                {/* <div className='ml-3'> */}
                <PrimaryButton
                    className='col-1 mb-1 ml-3'
                    iconProps={{ iconName: 'PageAdd' }}
                    onClick={() => this.setState({ showChangePage: true })}
                >Change</PrimaryButton>
                <div className='d-flex mb-1'>
                    <div className='col-1'>
                        <span className='small'>Page</span>
                    </div>
                    <div className='col-11' style={{ color: '#888' }}>
                        {preferredPage?.text}
                    </div>
                </div>
                {
                    preferredDepartment &&
                    <div className='d-flex mb-1'>
                        <div className='col-1'>
                            <span className='small'>Dept.</span>
                        </div>
                        <div className='col-11' style={{ color: '#888' }}>
                            {
                                preferredDepartment.data?.icon &&
                                <Icon className='mr-2' style={preferredDepartment.data.style} iconName={preferredDepartment.data.icon} />
                            }
                            {preferredDepartment.text}
                        </div>
                    </div>
                }
                {/* </div> */}

                <Panel
                    headerText="Preferred Home Page"
                    type={PanelType.medium}
                    isOpen={showChangePage}
                    onDismiss={this.cancelHomePage}
                    isFooterAtBottom
                    onRenderFooterContent={() =>
                        <div style={{ display: 'flex', gap: 5 }}>
                            <DefaultButton
                                className="col-6"
                                disabled={loading}
                                iconProps={{ iconName: 'Cancel' }}
                                onClick={this.cancelHomePage}
                            >Cancel</DefaultButton>
                            <PrimaryButton
                                className="col-6"
                                disabled={loading || !selectedPage || (selectedPage.key === Page.myDepartments && !selectedDepartment)}
                                iconProps={{ iconName: 'PageAdd' }}
                                onClick={this.setHomePage}
                            >Set Home Page</PrimaryButton>
                        </div>
                    }
                >
                    <Dropdown
                        // className="col-3"
                        disabled={loading}
                        placeholder="Select Page"
                        label="Page"
                        onChange={this.changePage}
                        options={this.pages}
                        selectedKey={selectedPage?.key}
                    />
                    {
                        selectedPage?.key === Page.myDepartments &&
                        <Dropdown
                            // className="col-3"
                            disabled={loading}
                            placeholder="Select Department"
                            label="Department"
                            onChange={this.changeDepartment}
                            onRenderTitle={options => this.renderDepartmentOption(options[0])}
                            onRenderOption={this.renderDepartmentOption}
                            options={departments}
                            selectedKey={selectedDepartment?.key}
                        />
                    }
                    {
                        loading &&
                        <div className='mt-4'>
                            <Spinner label="Setting Home Page..." ariaLive="assertive" labelPosition="right" />
                        </div>
                    }
                </Panel>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.session.user,
})

const mapDispatchToProps = (dispatch) => ({
    setUser: user => dispatch(setUser(user)),
    addNotification: not => dispatch(addNotification(not))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PreferredHomePage)