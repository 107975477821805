import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import { Dropdown, Icon, PrimaryButton } from '@fluentui/react';
import { SearchBox } from '@fluentui/react/lib/SearchBox';

class BasicTable extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            searchValue: '',
            searchOption: undefined
        }
    }

    componentDidMount() {
        if (this.props.searchOptions?.length > 0) {
            this.setState({ searchOption: this.props.searchOptions[0] });
        }
    }

    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    };

    onPageSizeChanged(value) {
        this.gridApi.paginationSetPageSize(value.key);
    };

    renderOption(icon, text) {
        return (
            <div>
                {icon && (
                    <Icon
                        style={{ marginRight: '8px' }}
                        iconName={icon}
                        aria-hidden="true"
                        title={icon}
                    />
                )}
                <span>{text}</span>
            </div>
        );
    }

    render() {
        const {
            data,
            columns,
            onCellValueChanged,
            onRowDoubleClicked,
            onSelectionChanged,
            onRowClicked,
            getRowNodeId,
            context,
            rowClassRules,
            onSearch,
            minCharToSearch,
            searchPlaceholder,
            searchOptions
        } = this.props;

        const { searchValue, searchOption } = this.state;

        const canSearch = Number.isInteger(minCharToSearch)
            ? searchValue.length >= minCharToSearch
            : false;

        return (
            <>
                <div className="mb-2">
                    <div className="d-sm-block d-sm-flex justify-content-between align-items-center order--filter mt-3">
                        <div className="d-flex col-6 p-0 align-items-center" style={{ minWidth: '500px', gap: 10 }}>
                            <div style={{ minWidth: '280px' }}>
                                <SearchBox
                                    value={searchValue}
                                    onChange={e => this.setState({ searchValue: e.target.value })}
                                    placeholder={searchPlaceholder}
                                    onClear={() => this.setState({ searchValue: '' })}
                                    onSearch={nv => canSearch ? onSearch(this.gridApi, nv, searchOption) : undefined}
                                />
                            </div>
                            {
                                !!searchOptions?.length &&
                                <div style={{ width: '120px' }}>
                                    <Dropdown
                                        onChange={(e, opt) => this.setState({ searchOption: opt })}
                                        options={searchOptions}
                                        onRenderTitle={(options) => {
                                            const option = options[0];
                                            return this.renderOption(option.data?.icon, option.text);
                                        }}
                                        onRenderOption={(option) => this.renderOption(option.data?.icon, option.text)}
                                    />
                                </div>
                            }
                            <PrimaryButton
                                text='Search'
                                onClick={e => onSearch?.(this.gridApi, searchValue, searchOption)}
                                disabled={!canSearch}
                            />
                        </div>
                        <div style={{ float: 'right' }}>
                            <Dropdown
                                options={[
                                    { key: 25, text: '25', selected: true },
                                    { key: 50, text: '50' },
                                    { key: 100, text: '100' },
                                    { key: 200, text: '200' },
                                ]}
                                onChange={(e, val) => this.onPageSizeChanged(val)}
                            />
                        </div>
                    </div>
                </div>
                <div style={{ overflow: 'hidden', flexGrow: '1' }}>
                    <div className="ag-theme-alpine" style={{ width: '100%', height: '100%' }}>
                        <AgGridReact
                            defaultColDef={{
                                flex: 1,
                                resizable: true,
                                suppressMovable: true,
                                sortable: false
                            }}

                            onGridReady={this.onGridReady}
                            onCellValueChanged={e => onCellValueChanged && onCellValueChanged(e)}
                            onRowClicked={e => onRowClicked?.(e)}
                            onRowDoubleClicked={e => onRowDoubleClicked?.(e)}
                            // onGridSizeChanged={e => e.api.sizeColumnsToFit()}
                            suppressRowClickSelection={true}
                            animateRows={true}
                            showLoadingOverlay={true}
                            pagination={true}
                            paginationPageSize={25}
                            applyColumnDefOrder={true}
                            onSelectionChanged={e => onSelectionChanged && onSelectionChanged(e.api.getSelectedRows())}
                            rowSelection="multiple"
                            rowData={data?.results || []}
                            context={context}
                            getRowNodeId={getRowNodeId ? getRowNodeId : rowData => rowData.id}
                            rowClassRules={rowClassRules ? rowClassRules : {}}
                            columnDefs={columns}
                        />
                    </div>
                </div>
            </>
        )
    }
}

export default BasicTable;