import React from 'react';
import { connect } from 'react-redux';

class TitleSection extends React.Component {
    render() {
        const { breadcrumbs } = this.props;

        return (
            <div className="d-flex align-items-center">
                <h5 className="m-0"><b>All Orders</b></h5>
                {
                    !!breadcrumbs?.length &&
                    breadcrumbs.map(x => <small key={x} className="mb-0 ml-2" style={{ color: '#aaa' }}> • {x}</small>)                  
                }
            </div>
        )
    }
}

const mapStateToProps = state => ({
    selectedFirm: state.allorders.selectedFirm,
    selectedRegion: state.allorders.selectedRegion,
    selectedMarket: state.allorders.selectedMarket,
    selectedOffice: state.allorders.selectedOffice,
    selectedDepartment: state.allorders.selectedDepartment,
    breadcrumbs: state.allorders.breadcrumbs
})

export default connect(mapStateToProps)(TitleSection);