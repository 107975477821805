import * as actionTypes from '../actions/actionTypes';

const defaultState = {
    loading: false,
    selectedRows: [],
    rowDetail: null,
    data: [],
    pagination: { size: 25, skip: null },
    filters: [],
    filterOptions: [],
    columns: [],
    searchText: '',
    showNotes: false,

    firms: [],
    selectedFirm: null,
    regions: [],
    selectedRegion: null,
    markets: [],
    selectedMarket: null,
    offices: [],
    selectedOffice: null,
    departments: [],
    selectedDepartment: null,
    hierarchy: { by: null, name: null },
    breadcrumbs: []
}

const newState = (state, newData) => {
    return Object.assign({}, state, newData)
}

const allorders = (state = defaultState, action) => {
    switch (action.type) {
        case actionTypes.SET_ALLORDERS_LOADING:
            return newState(state, {
                loading: action.payload
            })
        case actionTypes.SET_ALLORDERS_SELECTEDROWS:
            return newState(state, {
                selectedRows: action.payload
            })
        case actionTypes.SET_ALLORDERS_ROWDETAIL:
            return newState(state, {
                rowDetail: action.payload
            })
        case actionTypes.SET_ALLORDERS_DATA:
            return newState(state, {
                data: action.payload
            })
        case actionTypes.SET_ALLORDERS_PAGINATION:
            return newState(state, {
                pagination: action.payload
            })
        case actionTypes.SET_ALLORDERS_FILTERS:
            return newState(state, {
                filters: action.payload
            })
        case actionTypes.SET_ALLORDERS_FILTEROPTIONS:
            return newState(state, {
                filterOptions: action.payload
            })
        case actionTypes.SET_ALLORDERS_COLUMNS:
            return newState(state, {
                columns: action.payload
            })
        case actionTypes.SET_ALLORDERS_SEARCHTEXT:
            return newState(state, {
                searchText: action.payload
            })
        case actionTypes.SET_ALLORDERS_SHOWNOTES:
            return newState(state, {
                showNotes: action.payload
            })
        case actionTypes.SET_ALLORDERS_FIRMS:
            return newState(state, {
                firms: action.payload
            })
        case actionTypes.SET_ALLORDERS_SELECTEDFIRM:
            return newState(state, {
                selectedFirm: action.payload
            })
        case actionTypes.SET_ALLORDERS_REGIONS:
            return newState(state, {
                regions: action.payload
            })
        case actionTypes.SET_ALLORDERS_SELECTEDREGION:
            return newState(state, {
                selectedRegion: action.payload
            })
        case actionTypes.SET_ALLORDERS_MARKETS:
            return newState(state, {
                markets: action.payload
            })
        case actionTypes.SET_ALLORDERS_SELECTEDMARKET:
            return newState(state, {
                selectedMarket: action.payload
            })
        case actionTypes.SET_ALLORDERS_OFFICES:
            return newState(state, {
                offices: action.payload
            })
        case actionTypes.SET_ALLORDERS_SELECTEDOFFICE:
            return newState(state, {
                selectedOffice: action.payload
            })
        case actionTypes.SET_ALLORDERS_DEPARTMENTS:
            return newState(state, {
                departments: action.payload
            })
        case actionTypes.SET_ALLORDERS_SELECTEDDEPARTMENT:
            return newState(state, {
                selectedDepartment: action.payload
            })
        case actionTypes.SET_ALLORDERS_HIERARCHY:
            return newState(state, {
                hierarchy: action.payload
            })
        case actionTypes.SET_ALLORDERS_BREADCRUMBS:
            return newState(state, {
                breadcrumbs: action.payload
            })
        default:
            return state
    }
}
export default allorders;