import React from 'react';
import '../App.css';
import './SideBar.css';
import { toggleSideMenu } from '../../store/actions';
import { connect, batch } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { withRouter } from 'react-router-dom'
import { menuList, consolidatedSubmenuItems } from './sideBarData'
import { ContextualMenu, DirectionalHint, FontIcon, TooltipHost, Dialog, DialogFooter, DefaultButton, DialogType, Icon } from '@fluentui/react';
import {
    setActiveDepartment,
    setActiveMicroteam,
    setConsolidatedData,
    setConsolidatedPagination,
    setConsolidatedFilterOptions,
    setConsolidatedSearchText,
    setConsolidatedFilters,
    setConsolidatedIsDelegate,
    setMyDepartmentsData,
    setMyDepartmentsPagination,
    setMyDepartmentsFilterOptions,
    setMyDepartmentsSearchText,
    setMyDepartmentsFilters
} from '../../store/actions';
import { Page } from '../../constants/constants';
import MenuIcon from '../icons/MenuIcon';
import config from '../../config';


class SideBar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            openSideMenu: this.props.sidemenu,
            showMyDepartmentsSubmenu: false,
            showConsolidatedSubmenu: false,
            departments: [],
            myDepartmentsSubmenuSelected: null,
            consolidatedDepartments: [],
            consolidatedSubmenuSelected: null,
            menuItems: [],
            showHelp: false
        }
    }

    componentDidMount() {
        const { user } = this.props;

        const hasDepartments = user.officeLeaderDepartmentList && user.officeLeaderDepartmentList.length > 0;
        const menuItems = hasDepartments
            ? menuList
            : menuList.filter(x => !["/consolidatedOrders", "/myDepartments"].includes(x.path));

        let departments = user.officeLeaderDepartmentList.map(dp => ({
            isDelegate: dp.isDelegate,
            iconProps: dp.isDelegate ? { iconName: 'BusinessCenterLogo', style: { color: 'salmon' } } : undefined,
            key: dp.kf_financialhierarchyid,
            text: dp.departmentName
        }));

        const consolidatedDepartments = [];
        if (user.officeLeaderDepartmentList.some(x => x.isDelegate)) {
            consolidatedDepartments.push(consolidatedSubmenuItems[0]);
        }
        if (user.officeLeaderDepartmentList.some(x => !x.isDelegate)) {
            consolidatedDepartments.push(consolidatedSubmenuItems[1]);
        }


        const preferred = user.settings.preferredHomePage;

        let submenuStateName;
        let submenuStateValue;
        switch (preferred.page) {
            case Page.consolidatedOrders:
                submenuStateName = "consolidatedSubmenuSelected";
                submenuStateValue = consolidatedDepartments.length > 0 ? consolidatedDepartments[0].key : null;
                break;
            case Page.myDepartments:
                let departmentId = null;
                if (departments.length > 0 && preferred.data) {
                    const dept = departments.find(x => x.key === preferred.data);
                    departmentId = dept ? dept.key : departments[0].key;
                }
                submenuStateName = "myDepartmentsSubmenuSelected";
                submenuStateValue = departmentId;
                break;
            default:
                if (hasDepartments) {
                    submenuStateName = "consolidatedSubmenuSelected";
                    submenuStateValue = consolidatedDepartments.length > 0 ? consolidatedDepartments[0].key : null;
                }
                break;
        }

        this.setState({
            menuItems,
            departments,
            consolidatedDepartments,
            [`${submenuStateName}`]: submenuStateValue
            // myDepartmentsSubmenuSelected: departments[0].text
            // consolidatedSubmenuSelected: consolidatedDepartments.length > 0 ? consolidatedDepartments[0].key : null
        });
    }

    selectDepartment(name) {
        const { myDepartmentsSubmenuSelected } = this.state;
        const { user, myDepartmentsPagination, consolidatedPagination } = this.props;

        const departments = user.officeLeaderDepartmentList.filter(dp => dp.departmentName === name);
        const departmentId = departments.length > 0 ? departments[0]?.kf_financialhierarchyid : '';

        if (myDepartmentsSubmenuSelected === departmentId) {
            return;
        }

        batch(() => {
            this.props.setActiveDepartment(departmentId);
            this.props.setActiveMicroteam();
            this.props.setMyDepartmentsData([]);
            this.props.setMyDepartmentsPagination(myDepartmentsPagination.size, 0);
            this.props.setConsolidatedPagination(consolidatedPagination.size, 0);
            this.props.setMyDepartmentsSearchText('');
            this.props.setMyDepartmentsFilters([]);
            this.props.setMyDepartmentsFilterOptions([]);
        });

        this.navigateTo('/myDepartments');
        this.setState({ showMyDepartmentsSubmenu: false, myDepartmentsSubmenuSelected: departmentId, consolidatedSubmenuSelected: null });
    }

    selectConsolidatedOrder(name) {
        const { consolidatedSubmenuSelected, consolidatedDepartments } = this.state;
        const { myDepartmentsPagination, consolidatedPagination } = this.props;

        const item = consolidatedDepartments.find(x => x.text === name);

        if (consolidatedSubmenuSelected === item.key) {
            return;
        }

        batch(() => {
            this.props.setConsolidatedIsDelegate(item.isDelegate);
            this.props.setConsolidatedData([]);
            this.props.setConsolidatedPagination(consolidatedPagination.size, 0);
            this.props.setMyDepartmentsPagination(myDepartmentsPagination.size, 0);
            this.props.setConsolidatedSearchText('');
            this.props.setConsolidatedFilters([]);
            this.props.setConsolidatedFilterOptions([]);
        });

        this.navigateTo('/consolidatedOrders');
        this.setState({ showConsolidatedSubmenu: false, myDepartmentsSubmenuSelected: null, consolidatedSubmenuSelected: item.key });
    }

    toggleSideMenu = () => {
        const { openSideMenu } = this.state;
        this.props.sidemenu(!openSideMenu);
        this.setState({ openSideMenu: !openSideMenu });
    }


    navigateTo(pathname) {
        const { history } = this.props;
        history.push({ pathname });
    }

    validatePermission(permissions = []) {
        const { user } = this.props;
        return permissions.indexOf(user.roleName) >= 0;
    }

    selectMenuOption(i) {
        const { user } = this.props;
        const { consolidatedDepartments } = this.state;

        if (i.myDepartmentsSubmenu) {
            this.selectDepartment(user.officeLeaderDepartmentList[0].departmentName);
        }
        else if (this.validatePermission(i.consolidatedSubmenu)) {
            this.selectConsolidatedOrder(consolidatedDepartments[0].text);
        }
        else {
            this.setState({ myDepartmentsSubmenuSelected: null, consolidatedSubmenuSelected: null });
            this.navigateTo(i.path)
        }
    }

    renderContextualMenu(index, items, statePropertyName, onItemClick) {
        return (
            <div id={`menu-item-${index}`} onClick={() => this.setState({ [statePropertyName]: true })} className="position-absolute sidebar-menu">
                <ContextualMenu
                    isBeakVisible
                    target={`#menu-item-${index}`}
                    items={items}
                    hidden={!this.state[statePropertyName]}
                    onItemClick={onItemClick}
                    onDismiss={() => this.setState({ [statePropertyName]: false })}
                    directionalHint={DirectionalHint.rightTopEdge}
                    directionalHintFixed={true}
                    className="border shadow"
                />
            </div>
        )
    }

    renderSubmenuItem(title, isSelected, isDelegate, onClick) {
        return (
            <li key={title}
                className={"list-item bg-light d-flex position-relative align-items-center submenu"}>
                <div className={`list-group-item bg-light list-group-item-action position-relative py-2 ${isSelected ? 'submenu-active-list' : ''}`}
                    onClick={onClick}>
                    {
                        isDelegate &&
                        <span className="mr-2">
                            <TooltipHost content="Delegate">
                                <FontIcon iconName="BusinessCenterLogo" style={{ color: 'salmon' }} />
                            </TooltipHost>
                        </span>
                    }
                    <span>{title}</span>
                </div>
            </li>
        )
    }


    render() {
        const { location, user } = this.props;
        const {
            openSideMenu,
            showMyDepartmentsSubmenu,
            showConsolidatedSubmenu,
            departments,
            consolidatedDepartments,
            myDepartmentsSubmenuSelected,
            consolidatedSubmenuSelected,
            menuItems,
            showHelp
        } = this.state;

        return (
            <div id="sidebar-wrapper"
                className="`bg-light border-right p-0 bg-light"
                style={{ height: '100vh', zIndex: 20 }}
            >
                {
                    !openSideMenu && !showMyDepartmentsSubmenu && !showConsolidatedSubmenu &&
                    <ReactTooltip place="right" />
                }
                <div className="list-group list-group-flush d-flex justify-content-between h-100">
                    <div className="sidebar-list">
                        <li onClick={() => this.toggleSideMenu()}
                            style={{ minHeight: '48px' }}
                            className="list-group-item border-bottom list-group-item-action bg-light d-flex align-items-center border-top-0"
                        >
                            <MenuIcon />
                            {
                                openSideMenu &&
                                <span className="ml-2">Menu</span>
                            }
                        </li>
                        {
                            user && menuItems.map((i, index) => {

                                if (!this.validatePermission(i.permissions)) {
                                    return null
                                }

                                return (
                                    <ul key={i.title}
                                        className={`list-item bg-light d-block m-0 p-0 align-items-center`}
                                    >
                                        <li data-tip={i.title}
                                            className={`list-item bg-light d-block position-relative align-items-center ${location.pathname === i.path && 'active-list'}`}
                                        >
                                            <div className="list-group-item bg-light d-flex align-items-center list-group-item-action position-relative py-3"
                                                onClick={() => this.selectMenuOption(i)}
                                            >
                                                {i.icon()}
                                                {
                                                    openSideMenu &&
                                                    <span className="ml-2">{i.title}</span>
                                                }
                                            </div>
                                            {
                                                i.myDepartmentsSubmenu && !openSideMenu &&
                                                this.renderContextualMenu(
                                                    index,
                                                    departments,
                                                    "showMyDepartmentsSubmenu",
                                                    (d) => this.selectDepartment(d.target.innerText)
                                                )
                                            }
                                            {
                                                this.validatePermission(i.consolidatedSubmenu) && !openSideMenu &&
                                                this.renderContextualMenu(
                                                    index,
                                                    consolidatedDepartments,
                                                    "showConsolidatedSubmenu",
                                                    (d) => this.selectConsolidatedOrder(d.target.innerText)
                                                )
                                            }
                                        </li>
                                        {
                                            i.myDepartmentsSubmenu && openSideMenu &&
                                            <ul>
                                                {
                                                    departments.map((dp) => this.renderSubmenuItem(
                                                        dp.text,
                                                        !consolidatedSubmenuSelected && myDepartmentsSubmenuSelected === dp.key,
                                                        dp.isDelegate,
                                                        (d) => this.selectDepartment(dp.text)
                                                    ))
                                                }
                                            </ul>
                                        }
                                        {
                                            this.validatePermission(i.consolidatedSubmenu) && openSideMenu &&
                                            <ul>
                                                {
                                                    consolidatedDepartments.map((x) => this.renderSubmenuItem(
                                                        x.text,
                                                        !myDepartmentsSubmenuSelected && consolidatedSubmenuSelected === x.key,
                                                        x.isDelegate,
                                                        (d) => this.selectConsolidatedOrder(x.text)
                                                    ))
                                                }
                                            </ul>
                                        }
                                    </ul>
                                )
                            })
                        }
                    </div>
                    <div className="sidebar-list" style={{ overflow: 'hidden', minHeight: '48px' }}>
                        <ul className="list-item bg-light d-block m-0 p-0 align-items-center">
                            <li data-tip="Help"
                                className="list-group-item list-group-item-action bg-light d-flex align-items-center"
                                onClick={() => this.setState({ showHelp: true })}
                            >
                                <Icon iconName="Help" />
                                {
                                    openSideMenu &&
                                    <span className="ml-2">Help</span>
                                }
                                <Dialog
                                    hidden={!showHelp}
                                    onDismiss={() => this.setState({ showHelp: false })}
                                    dialogContentProps={{
                                        type: DialogType.normal,
                                        title: 'Help',
                                        subText: config.REACT_APP_VERSION || "---",
                                    }}
                                    modalProps={{
                                        isBlocking: true,
                                        // styles: { main: { maxWidth: 450 } }
                                    }}
                                >
                                    <DialogFooter>
                                        <DefaultButton onClick={() => this.setState({ showHelp: false })} text="Close" />
                                    </DialogFooter>
                                </Dialog>
                            </li>
                        </ul>
                    </div>
                </div>
            </div >
        );

    }
}

const mapStateToProps = (state, props) => ({
    user: state.session.user,
    sidemenu: state.session.sidemenu,
    myDepartmentsPagination: state.mydepartments.pagination,
    consolidatedPagination: state.consolidated.pagination,
    isDelegate: state.consolidated.isDelegate
})

const mapDispatchToProps = (dispatch) => ({
    sidemenu: (i) => dispatch(toggleSideMenu(i)),
    setActiveDepartment: data => dispatch(setActiveDepartment(data)),
    setActiveMicroteam: data => dispatch(setActiveMicroteam(data)),
    setConsolidatedData: data => dispatch(setConsolidatedData(data)),
    setConsolidatedPagination: (size, skip) => dispatch(setConsolidatedPagination(size, skip)),
    setConsolidatedFilters: filters => dispatch(setConsolidatedFilters(filters)),
    setConsolidatedFilterOptions: data => dispatch(setConsolidatedFilterOptions(data)),
    setConsolidatedSearchText: text => dispatch(setConsolidatedSearchText(text)),
    setConsolidatedIsDelegate: text => dispatch(setConsolidatedIsDelegate(text)),
    setMyDepartmentsData: data => dispatch(setMyDepartmentsData(data)),
    setMyDepartmentsPagination: (size, skip) => dispatch(setMyDepartmentsPagination(size, skip)),
    setMyDepartmentsFilters: filters => dispatch(setMyDepartmentsFilters(filters)),
    setMyDepartmentsFilterOptions: data => dispatch(setMyDepartmentsFilterOptions(data)),
    setMyDepartmentsSearchText: text => dispatch(setMyDepartmentsSearchText(text))
})

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(SideBar))