import React, { createRef } from 'react';
import { connect, batch } from 'react-redux';
import apiService from '../../../../services/api-service';
import allOrdersService from '../../../../services/allorders-service';
import { getCancelTokenSource } from '../../../../api';
import {
    setAllOrdersLoading,
    setAllOrdersSelectedRows,
    setAllOrdersRowDetail,
    setAllOrdersData,
    setAllOrdersFilters,
    setAllOrdersPagination,
    setAllOrdersColumns,
    setAllOrdersShowNotes,
    setUser
} from '../../../../store/actions';
import { columnsDef } from './Columns';
import ColumnHeader from './ColumnHeader/ColumnHeader';
import { Hierarchy } from '../../../../constants/constants';
import { Page } from '../../../../constants/constants';
import JobOrdersTable from '../../../shared/GeneralTable/JobOrdersTable';
import GridJobOrderDetailModal from '../../../shared/GridJobOrderDetailModal/GridJobOrderDetailModal';
import NotesModal from '../../../shared/NotesModal/NotesModal';

class TableSection extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            fetchingJobOrders: true,
            showDetail: false
        }
        this.source = createRef();
    }

    componentWillUnmount() {
        this.source.current?.cancel?.();
    }

    refreshTableData(size, skip) {
        const { user, tableSearchText, tableFilters, hierarchy } = this.props;

        const groupId = allOrdersService.getGroupId(hierarchy);
        const filters = allOrdersService.mapFiltersToAPI(user.settings.allOrdersPage.filter.filters.filter(x => x.group === groupId));

        if (tableFilters && tableFilters.length > 0) {
            filters.push(...tableFilters);
        }
        const orderBy = allOrdersService.createGroupBy(user.settings.allOrdersPage.sort);

        this.props.setAllOrdersLoading(true);

        this.source.current?.cancel?.();
        this.source.current = getCancelTokenSource();

        if ([Hierarchy.department, Hierarchy.firm].includes(hierarchy.by)) {
            if (hierarchy.by === Hierarchy.department) {
                filters.push(allOrdersService.createMandatoryFilter(hierarchy.name));
            }
            apiService
                .getJobOrders(filters, size, skip, tableSearchText, orderBy, this.source.current.token)
                .then(data => {
                    batch(() => {
                        this.props.setAllOrdersData(data);
                        this.props.setAllOrdersPagination(size, skip);
                        this.props.setAllOrdersLoading(false);
                        this.props.setAllOrdersSelectedRows([]);
                    })
                });
        } else {
            apiService
                .getJobOrdersByHierarchy(hierarchy.by, hierarchy.name, filters, size, skip, tableSearchText, orderBy, this.source.current.token)
                .then(data => {
                    batch(() => {
                        this.props.setAllOrdersData(data);
                        this.props.setAllOrdersPagination(size, skip);
                        this.props.setAllOrdersLoading(false);
                        this.props.setAllOrdersSelectedRows([]);
                    })
                });
        }
    }

    render() {
        const { showDetail } = this.state;
        const {
            showData,
            tableData,
            tablePagination,
            tableColumns,
            showNotes,
            rowDetail
        } = this.props;

        if (!showData) {
            return null;
        }

        return (
            <>
                <JobOrdersTable
                    context={this}
                    fetching={false}
                    page={Page.allOrders}
                    data={tableData}
                    columnHeaderComponent={ColumnHeader}
                    columnsDef={columnsDef}
                    columns={tableColumns}
                    pagination={tablePagination}
                    refresh={(size, skip) => this.refreshTableData(size, skip)}
                    setColumns={columns => this.props.setAllOrdersColumns(columns)}
                    setRowDetail={rowDetail => this.props.setAllOrdersRowDetail(rowDetail)}
                    setSelectedRows={selectedRows => this.props.setAllOrdersSelectedRows(selectedRows)}
                    onRowDoubleClicked={() => {
                        this.setState({ showDetail: true });
                        if (showNotes) {
                            this.props.setAllOrdersShowNotes(false);
                        }
                    }}
                />

                <GridJobOrderDetailModal
                    isOpen={showDetail}
                    onDismiss={() => {
                        this.setState({ showDetail: false });
                        this.props.setAllOrdersRowDetail(null);
                    }}
                    rowDetail={rowDetail}
                    readOnly={true}
                />

                <NotesModal
                    readOnly={true}
                    rowDetail={rowDetail}
                    isOpen={showNotes && rowDetail}
                    onDismiss={() => {
                        batch(() => {
                            this.props.setAllOrdersShowNotes(false);
                            this.props.setAllOrdersRowDetail(null);
                        })
                    }}
                />
            </>
        )
    }
}


const mapStateToProps = (state) => ({
    selectedRows: state.allorders.selectedRows,
    rowDetail: state.allorders.rowDetail,
    tableData: state.allorders.data,
    tablePagination: state.allorders.pagination,
    tableSort: state.allorders.sort,
    tableFilters: state.allorders.filters,
    tableColumns: state.allorders.columns,
    tableSearchText: state.allorders.searchText,
    showNotes: state.allorders.showNotes,
    hierarchy: state.allorders.hierarchy,
    user: state.session.user
})
const mapDispatchToProps = (dispatch) => ({
    setAllOrdersLoading: isLoading => dispatch(setAllOrdersLoading(isLoading)),
    setAllOrdersSelectedRows: selectedRows => dispatch(setAllOrdersSelectedRows(selectedRows)),
    setAllOrdersRowDetail: rowDetail => dispatch(setAllOrdersRowDetail(rowDetail)),
    setAllOrdersFilters: filters => dispatch(setAllOrdersFilters(filters)),
    setAllOrdersData: data => dispatch(setAllOrdersData(data)),
    setAllOrdersPagination: (size, skip) => dispatch(setAllOrdersPagination(size, skip)),
    setAllOrdersColumns: columns => dispatch(setAllOrdersColumns(columns)),
    setAllOrdersShowNotes: data => dispatch(setAllOrdersShowNotes(data)),
    setUser: user => dispatch(setUser(user))
})

export default connect(mapStateToProps, mapDispatchToProps)(TableSection)