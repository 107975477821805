import * as actionTypes from './actionTypes'

export const setSecurityLoading = payload => ({
    type: actionTypes.SET_SECURITY_LOADING,
    payload
})

export const setSecurityRowDetail = payload => ({
    type: actionTypes.SET_SECURITY_ROWDETAIL,
    payload
})

export const setSecurityData = payload => ({
    type: actionTypes.SET_SECURITY_DATA,
    payload
})

export const setSecurityPagination = (size, skip) => ({
    type: actionTypes.SET_SECURITY_PAGINATION,
    payload: { size, skip }
})

export const setSecurityFilters = payload => ({
    type: actionTypes.SET_SECURITY_FILTERS,
    payload
})

export const setSecurityFilterOptions = payload => ({
    type: actionTypes.SET_SECURITY_FILTEROPTIONS,
    payload
})

export const setSecurityColumns = payload => ({
    type: actionTypes.SET_SECURITY_COLUMNS,
    payload
})

export const setSecuritySort = (columnName, sortOrder) => ({
    type: actionTypes.SET_SECURITY_SORT,
    payload: { columnName, sortOrder }
})

export const setSecuritySearchText = payload => ({
    type: actionTypes.SET_SECURITY_SEARCHTEXT,
    payload
})

export const setSecurityShowForm = payload => ({
    type: actionTypes.SET_SECURITY_SHOWFORM,
    payload
})