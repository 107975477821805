import React from 'react';
import { DefaultButton, PrimaryButton, Panel, ActionButton } from '@fluentui/react';
import { Field } from '../../../constants/constants';

const ADVANCED_SERVICES_FIELDS = [
    Field.kf_advancedservicesrequired,
    Field.opportunityname,
    Field.stepname,
    Field.kf_practicearea,
    Field.kf_servicecapabilityprimary,
    Field.kf_engagementmodel
]

class ColumnsSettings extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedColumnOptions: []
        }
        this.refreshSelection = this.refreshSelection.bind(this);
        this.close = this.close.bind(this);
    }

    componentDidMount() {
        this.refreshSelection();
    }

    refreshSelection() {
        const { columnsToShow } = this.props;
        this.setState({ selectedColumnOptions: columnsToShow.filter(x => x.optional?.hideable === true) });
    }

    selectItem(item) {
        let { selectedColumnOptions } = this.state;
        if (!this.itsOnList(item)) {
            selectedColumnOptions.push(item)
        }
        else {
            selectedColumnOptions = selectedColumnOptions.filter(e => e.headerName !== item.headerName);
        }
        this.setState({ selectedColumnOptions });
    }

    save() {
        const { userColumns, columnsDef } = this.props;
        const { selectedColumnOptions } = this.state;

        const columnsFilter = columnsDef
            .filter(c => !c.optional?.hideable || selectedColumnOptions.includes(c))
            .sort((a, b) => a.order > b.order);

        const columnsToSet = [];
        for (const c of columnsFilter) {
            const columnName = c.headerName !== '' ? c.headerName : c.field;
            const settingsColumn = userColumns.filter(x => x.columnName === columnName)[0];
            const width = settingsColumn?.width ? Math.round(settingsColumn.width) : null;
            columnsToSet.push({
                columnName: columnName,
                width: width
            });
        }

        this.props.onSave(columnsFilter, columnsToSet);
    }

    itsOnList(item) {
        const { selectedColumnOptions } = this.state;
        return selectedColumnOptions.filter(e => e.headerName === item.headerName || e.headerTooltip === item.headerTooltip).length > 0;
    }

    selectAll() {
        let { selectedColumnOptions } = this.state;
        let { columnsDef } = this.props;

        if (columnsDef.length === selectedColumnOptions.length) {
            selectedColumnOptions = [];
        } else {
            selectedColumnOptions = columnsDef;
        }
        this.setState({ selectedColumnOptions });
    }

    selectAdvancedServices() {
        let { selectedColumnOptions } = this.state;
        let { columnsDef } = this.props;

        const advancedServicesColumns = selectedColumnOptions.filter(x => this.isAdvancedServices(x.field));

        selectedColumnOptions = selectedColumnOptions.filter(x => !this.isAdvancedServices(x.field));

        if (advancedServicesColumns.length !== ADVANCED_SERVICES_FIELDS.length) {
            columnsDef.forEach(x => {
                if (this.isAdvancedServices(x.field)) {
                    selectedColumnOptions.push(x);
                }
            });
        }
        this.setState({ selectedColumnOptions });
    }



    isAdvancedServices(field) {
        return ADVANCED_SERVICES_FIELDS.includes(field);
    }

    close() {
        this.refreshSelection();
        this.props.onClose()
    }

    render() {
        const { columnsDef, open } = this.props;

        return (
            <Panel
                headerText="Columns Settings"
                isOpen={open}
                onDismiss={this.close}
                isFooterAtBottom
                isBlocking
                onRenderHeader={(props, defaultRender) => {

                    return <div className='d-flex flex-column w-100'>
                        {defaultRender(props)}
                        <div className='d-flex pl-4' style={{ gap: 5 }}>
                            <ActionButton
                                style={{ padding: 0 }}
                                text="Select All"
                                onRenderText={props => <span className='small'>{props.text}</span>}
                                onClick={() => this.selectAll()}
                                iconProps={{ iconName: 'ReceiptCheck' }}
                            />

                            <ActionButton
                                style={{ padding: 0 }}
                                text="Select KCS"
                                onRenderText={props => <span className='small'>{props.text}</span>}
                                onClick={() => this.selectAdvancedServices()}
                                iconProps={{ iconName: 'BuildDefinition' }}
                            />
                            {/* </div> */}
                        </div>
                    </div>
                }

                }
                onRenderFooterContent={() =>
                    <div style={{ display: 'flex', gap: 5 }}>
                        <DefaultButton
                            className="col-6"
                            iconProps={{ iconName: 'Cancel' }}
                            onClick={this.close}
                        >Cancel</DefaultButton>
                        <PrimaryButton
                            className="col-6"
                            iconProps={{ iconName: 'CheckMark' }}
                            onClick={() => this.save()}
                        >Save</PrimaryButton>
                    </div>
                }
            >
                <div className='mt-1'>
                    {
                        columnsDef.map(column => column.optional?.hideable
                            ? (
                                <div
                                    key={column.headerName}
                                    className="microteam-list d-flex align-items-center col-12 pl-1 py-1"
                                >
                                    <input
                                        type="checkbox"
                                        checked={this.itsOnList(column)}
                                        onChange={() => this.selectItem(column)}
                                    />

                                    <div className="ml-2 d-flex flex-column">
                                        <span style={{ color: '#555' }}>
                                            {column.headerTooltip || column.headerName || column.field}
                                            {
                                                column.headerName && column.headerTooltip && column.headerTooltip !== column.headerName &&
                                                <span style={{ fontSize: '10px' }}> - <span style={{ color: 'red' }}>{column.headerName}</span> </span>
                                            }
                                        </span>

                                        {
                                            this.isAdvancedServices(column.field) &&
                                            <span className="text-muted small info">
                                                KCS
                                            </span>
                                        }
                                    </div>
                                </div>
                            )
                            : null
                        )
                    }
                </div>
            </Panel >
        )
    }
}

export default ColumnsSettings;