import React from 'react';
import { connect } from 'react-redux';
import { setMyDepartmentsShowRecruiters } from '../../../../../store/actions';
import { validatePermission } from '../../../../../utils/auth';
import GridRecruitersCell from '../../../../shared/GridRecruitersCell/GridRecruitersCell';

class RecruitersCell extends React.Component {

    render() {
        const { recruiters, user, activeDepartment, setMyDepartmentsShowRecruiters, showRecruiters } = this.props;

        const showEdit = activeDepartment?.isDelegate || validatePermission('EDIT_RECRUITERS', user.roleName);
        return <GridRecruitersCell
            recruiters={recruiters}
            showEdit={showEdit}
            onClickEdit={() => setMyDepartmentsShowRecruiters(!showRecruiters)}
        />
    }
};

const mapStateToProps = state => ({
    user: state.session.user,
    activeDepartment: state.session.activeDepartment,
    showRecruiters: state.mydepartments.showRecruiters
})


const mapDispatchToProps = (dispatch, props) => ({
    setMyDepartmentsShowRecruiters: (i) => dispatch(setMyDepartmentsShowRecruiters(i))
})

export default connect(mapStateToProps, mapDispatchToProps)(RecruitersCell);