import React from 'react';
import GridRecruitersCell from '../../../../shared/GridRecruitersCell/GridRecruitersCell';

class RecruitersCell extends React.Component {

    render() {
        const { recruiters } = this.props;
        return <GridRecruitersCell
            recruiters={recruiters}
            showEdit={false}
        />
    }
};

export default RecruitersCell;