import React, { createRef } from 'react';
import { connect, batch } from 'react-redux';
import myDepartmentsService from '../../../../services/mydepartments-service';
import {
    setMyDepartmentsLoading,
    setMyDepartmentsSelectedRows,
    setMyDepartmentsRowDetail,
    setMyDepartmentsData,
    setMyDepartmentsPagination,
    setMyDepartmentsColumns,
    setMyDepartmentsShowNotes,
    setMyDepartmentsShowRecruiters,
    setMyDepartmentsFilterOptions,
    addNotification,
    setUser
} from '../../../../store/actions';
import { columnsDef } from './Columns';
import ColumnHeader from './ColumnHeader/ColumnHeader';
import apiService from '../../../../services/api-service';
import { getCancelTokenSource } from '../../../../api';
import { TextFilterAction, Page, Field } from '../../../../constants/constants';
import RecruitersDetailModal from '../../../shared/RecruitersDetailModal/RecruitersDetailModal';
import NotesModal from '../../../shared/NotesModal/NotesModal';
import GridJobOrderDetailModal from '../../../shared/GridJobOrderDetailModal/GridJobOrderDetailModal';
import JobOrdersTable from '../../../shared/GeneralTable/JobOrdersTable';
import { validatePermission } from '../../../../utils/auth';

class TableSection extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentDepartmentId: '',
            fetchingJobOrders: true,
            showDetail: false
        }

        this.source = createRef();
    }

    componentWillUnmount() {
        this.source.current?.cancel?.();
    }

    componentDidUpdate() {
        const { currentDepartmentId } = this.state;
        const { activeDepartment, tablePagination } = this.props;
        if (currentDepartmentId !== activeDepartment.kf_financialhierarchyid) {
            this.source.current?.cancel?.();
            this.source.current = getCancelTokenSource();
            this.setState({ currentDepartmentId: activeDepartment.kf_financialhierarchyid, fetchingJobOrders: true });
            this.loadFilterOptions();
            this.refreshTableData(tablePagination.size, 0);
        }
    }

    refreshTableData(size, skip) {
        const { activeMicroteam, activeDepartment, user, tableSearchText, tableFilters } = this.props;

        const filterGroup = myDepartmentsService.getGroupId(activeMicroteam?.microteamid, activeDepartment?.kf_financialhierarchyid);
        const userFilters = user.settings.myDepartmentsPage.filter.filters.filter(x => x.group === filterGroup);

        const filters = [
            myDepartmentsService.createMandatoryFilter(activeMicroteam?.microteamid, activeDepartment?.kf_financialhierarchyid),
            ...myDepartmentsService.mapFiltersToAPI(userFilters)
        ];

        if (tableFilters && tableFilters.length > 0) {
            filters.push(...tableFilters);
        }

        const orderBy = myDepartmentsService.createGroupBy(user.settings.myDepartmentsPage.sort);
        this.props.setMyDepartmentsLoading(true);


        apiService.getJobOrders(filters, size, skip, tableSearchText, orderBy, this.source.current.token)
            .then(data => {
                batch(() => {
                    this.props.setMyDepartmentsData(data);
                    this.props.setMyDepartmentsPagination(size, skip);
                    this.props.setMyDepartmentsLoading(false);
                    this.props.setMyDepartmentsSelectedRows([]);
                })
            }).finally(() => this.setState({ fetchingJobOrders: false }));
    }

    loadFilterOptions() {
        const { activeDepartment, activeMicroteam } = this.props;

        const microteamId = activeMicroteam?.microteamid;

        const filters = [{
            Option: microteamId ? Field.allocation_board_microteam_id : Field.owner_kf_financialhierarchyid,
            Action: TextFilterAction.Equals.key,
            Value: microteamId ? microteamId : activeDepartment.kf_financialhierarchyid
        }];

        apiService.getJobOrderFilters(filters, false, this.source.current.token)
            .then(data => {
                const filterOptions = myDepartmentsService.createFilterOptions(data);
                this.props.setMyDepartmentsFilterOptions(filterOptions);
            })
    }

    render() {
        const { fetchingJobOrders, showDetail } = this.state;
        const {
            tableData,
            tablePagination,
            tableColumns,
            rowDetail,
            showNotes,
            showRecruiters,
            activeDepartment,
            user
        } = this.props;

        return (
            <>
                <JobOrdersTable
                    context={this}
                    fetching={fetchingJobOrders}
                    page={Page.myDepartments}
                    data={tableData}
                    columnHeaderComponent={ColumnHeader}
                    columnsDef={columnsDef}
                    columns={tableColumns}
                    pagination={tablePagination}
                    refresh={(size, skip) => this.refreshTableData(size, skip)}
                    setColumns={columns => this.props.setMyDepartmentsColumns(columns)}
                    setRowDetail={rowDetail => this.props.setMyDepartmentsRowDetail(rowDetail)}
                    setSelectedRows={selectedRows => this.props.setMyDepartmentsSelectedRows(selectedRows)}
                    onRowDoubleClicked={() => {
                        this.setState({ showDetail: true });
                        if (showNotes) {
                            this.props.setMyDepartmentsShowNotes(false);
                        }
                    }}
                />

                <GridJobOrderDetailModal
                    isOpen={showDetail}
                    onDismiss={() => {
                        this.setState({ showDetail: false });
                        this.props.setMyDepartmentsRowDetail(null);
                    }}
                    rowDetail={rowDetail}
                    department={activeDepartment}
                    readOnly={activeDepartment?.isDelegate ? false : !validatePermission('CHANGE_STATUS', user.roleName)}
                />

                <RecruitersDetailModal
                    rowDetail={rowDetail}
                    isOpen={showRecruiters && rowDetail}
                    onDismiss={() => {
                        batch(() => {
                            this.props.setMyDepartmentsShowRecruiters(false);
                            this.props.setMyDepartmentsRowDetail(null);
                        })
                    }}
                />

                <NotesModal
                    rowDetail={rowDetail}
                    isOpen={showNotes && rowDetail}
                    onDismiss={() => {
                        batch(() => {
                            this.props.setMyDepartmentsShowNotes(false);
                            this.props.setMyDepartmentsRowDetail(null);
                        })
                    }}
                />
            </>
        )
    }
}


const mapStateToProps = (state) => ({
    selectedRows: state.mydepartments.selectedRows,
    rowDetail: state.mydepartments.rowDetail,
    tableData: state.mydepartments.data,
    tablePagination: state.mydepartments.pagination,
    tableColumns: state.mydepartments.columns,
    tableSearchText: state.mydepartments.searchText,
    tableFilters: state.mydepartments.filters,
    user: state.session.user,
    activeDepartment: state.session.activeDepartment,
    activeMicroteam: state.session.activeMicroteam,
    showNotes: state.mydepartments.showNotes,
    showRecruiters: state.mydepartments.showRecruiters
})
const mapDispatchToProps = (dispatch) => ({
    setMyDepartmentsLoading: isLoading => dispatch(setMyDepartmentsLoading(isLoading)),
    setMyDepartmentsSelectedRows: selectedRows => dispatch(setMyDepartmentsSelectedRows(selectedRows)),
    setMyDepartmentsRowDetail: rowDetail => dispatch(setMyDepartmentsRowDetail(rowDetail)),
    setMyDepartmentsData: data => dispatch(setMyDepartmentsData(data)),
    setMyDepartmentsPagination: (size, skip) => dispatch(setMyDepartmentsPagination(size, skip)),
    setMyDepartmentsColumns: columns => dispatch(setMyDepartmentsColumns(columns)),
    setMyDepartmentsShowNotes: data => dispatch(setMyDepartmentsShowNotes(data)),
    setMyDepartmentsShowRecruiters: data => dispatch(setMyDepartmentsShowRecruiters(data)),
    setMyDepartmentsFilterOptions: data => dispatch(setMyDepartmentsFilterOptions(data)),
    addNotification: not => dispatch(addNotification(not)),
    setUser: user => dispatch(setUser(user))
})

export default connect(mapStateToProps, mapDispatchToProps)(TableSection)