export const LocalTokenKey = '__localtoken';

export const Role = {
    associate: "Associate",
    officeLeader: "Office Leader",
    seniorLeader: "Senior Leader",
    securityAdmin: "Security Admin",
}

export const optionsStatus = [
    { key: 'All', text: 'All Status' },
    { key: 'Open', text: 'Open' },
    { key: 'NotCovered', text: 'Not Covered' },
    { key: 'Covered', text: 'Covered' },
    { key: 'SendOut', text: 'Send Out' },
    { key: 'OfferAcepted', text: 'Offer Acepted' },
    { key: 'DirectFill', text: 'Direct Fill' },
    { key: 'Start', text: 'Start' },
    { key: 'Opportunity', text: 'Opportunity' },
    { key: 'Canceled', text: 'Canceled' },
    { key: 'Closed', text: 'Closed' }
]

export const rolesList = Object.values(Role);

export const Hierarchy = {
    firm: "firm",
    region: "region",
    market: "market",
    office: "office",
    department: "department"
}

export const TextFilterAction = {
    Equals: { key: 'Equals', text: 'Equals' },
    NotEquals: { key: 'NotEquals', text: 'Not Equals' },
    Contains: { key: 'Contains', text: 'Contains' },
    StartWith: { key: 'StartWith', text: 'Start With' },
    EndWith: { key: 'EndWith', text: 'End With' }
}

export const textFilterActionList = Object.values(TextFilterAction);

export const NumericFilterAction = {
    Equals: { key: 'NumericEquals', text: 'Equals' },
    NotEquals: { key: 'NumericNotEquals', text: 'Not Equals' },
    GreaterThan: { key: 'GreaterThan', text: 'Greater Than' },
    GreaterThanOrEquals: { key: 'GreaterOrEquals', text: 'Greater Than or Equals' },
    LowerThan: { key: 'LowerThan', text: 'Lower Than' },
    LowerThanOrEquals: { key: 'LowerOrEquals', text: 'Lower Than or Equals' },
    Between: { key: 'Between', text: 'Between' }
}

export const numericFilterActionList = Object.values(NumericFilterAction);

export const Page = {
    consolidatedOrders: "consolidatedOrders",
    allOrders: "allOrders",
    myDepartments: "myDepartments"
}

export const CandidateStatusColors = {
    "Assigned to Job Order": "#ff9933",
    "Internal Submittal": "#cc33cc",
    "External Submittal": "#00ccff",
    "Send Out": "#339999",
    "Offer": "#330099",
    "Withdrawn": "#ff0033",
    "Onboarding": "#fff200",
    "Start Confirmation": "#0ed145"
}

export const Field = {
    department_display_name: "department_display_name",
    owner_kf_financialhierarchyid: "owner_kf_financialhierarchyid",
    allocation_board_microteam_id: "allocation_board_microteam_id",
    job_order_is_exclusive: "job_order_is_exclusive",
    job_order_is_exclusive_filterable: "job_order_is_exclusive_filterable",
    job_order_is_hotlist_filterable: "job_order_is_hotlist_filterable",
    pafo_quantity: "pafo_quantity",
    trm_rank: "trm_rank",
    recruiters: "recruiters",
    candidates: "candidates",
    trm_issjoborder: "trm_issjoborder",
    trm_vsdjoborder: "trm_vsdjoborder",
    trm_posttoweb: "trm_posttoweb",
    trm_post_to_ilabor: "trm_post_to_ilabor",
    kf_advancedservicesrequired: "kf_advancedservicesrequired",
    createdon: "createdon",
    account_name: "account_name",
    allocation_board_status: "allocation_board_status",
    trm_status: "trm_status",
    owner_fullname: "owner_fullname",
    pafo_jobtype: "pafo_jobtype",
    trm_priority: "trm_priority",
    trm_worksitecity: "trm_worksitecity",
    trm_worksitestate: "trm_worksitestate",
    allocation_board_priority: "allocation_board_priority",
    opportunityname: "opportunityname",
    stepname: "stepname",
    kf_practicearea: "kf_practicearea",
    kf_servicecapabilityprimary: "kf_servicecapabilityprimary",
    kf_engagementmodel: "kf_engagementmodel"
}

export const FilterCategory = {
    General: "General",
    Location: "Location",
    Status: "Status",
    Highlight: "Highlight",
    AdvancedServices: "KCS",
}