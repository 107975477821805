import { TextFilterAction, Field } from "../constants/constants";
import { advancedServicesFilterOption, exclusiveFilterOption, hotlistFilterOption, iLaborFilterOption, postToWebFilterOption } from "../constants/service-filters";

const allOrdersService = {

    createGroupBy(sort) {
        if (!sort || !sort.columnName || !sort.sortOrder) {
            return [];
        }
        return [`${sort.columnName} ${sort.sortOrder}`];
    },

    createMandatoryFilter: function (departmentId) {
        const mandatoryFilter = {
            Option: Field.owner_kf_financialhierarchyid,
            Action: TextFilterAction.Equals.key,
            Value: departmentId
        }
        return mandatoryFilter;
    },

    createFilterOptions: function (facetsData) {
        return [
            ...facetsData, 
            exclusiveFilterOption, 
            hotlistFilterOption,
            postToWebFilterOption,
            iLaborFilterOption,
            advancedServicesFilterOption
        ];
    },

    getFiltersForFilters: function (tableFilters) {
        return tableFilters && tableFilters.length > 0
            ? tableFilters.filter(x => x.Option === Field.department_display_name)
            : [];
    },

    mapFiltersToAPI: function (filters) {
        return filters && filters.length > 0
            ? filters.map(x => ({
                Option: x.columnName,
                Action: x.action,
                Value: x.value,
                Group: x.group
            }))
            : [];
    },

    getGroupId(hierarchy) {
        return hierarchy?.by ? `${hierarchy.by}_${hierarchy.name}` : undefined;
    }
}

export default allOrdersService;