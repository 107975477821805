import React, { createRef } from 'react';
import { connect, batch } from 'react-redux';
import {
    setMyDepartmentsLoading,
    setMyDepartmentsData,
    setMyDepartmentsPagination,
    setMyDepartmentsColumns,
    setMyDepartmentsFilters,
    setUser
} from '../../../../../store/actions';
import apiService from '../../../../../services/api-service';
import { getCancelTokenSource } from '../../../../../api';
import myDepartmentsService from '../../../../../services/mydepartments-service';
import { Page } from '../../../../../constants/constants';
import GridColumnHeader from '../../../../shared/GridColumnHeader/GridColumnHeader';

class ColumnHeader extends React.Component {

    constructor(props) {
        super(props);
        this.applyPageFilters = this.applyPageFilters.bind(this);
        this.refreshTableData = this.refreshTableData.bind(this);
        this.source = createRef();
    }

    componentWillUnmount() {
        this.source.current?.cancel?.();
    }

    applyPageFilters(filters) {
        this.props.setMyDepartmentsFilters(filters);
    }

    refreshTableData(orderBy, tableFilters, resetPagination) {
        const { activeMicroteam, activeDepartment, user, tableSearchText, tablePagination } = this.props;

        const filterGroup = myDepartmentsService.getGroupId(activeMicroteam?.microteamid, activeDepartment?.kf_financialhierarchyid);
        const userFilters = user.settings.myDepartmentsPage.filter.filters.filter(x => x.group === filterGroup);
        const filters = [
            myDepartmentsService.createMandatoryFilter(activeMicroteam?.microteamid, activeDepartment?.kf_financialhierarchyid),
            ...myDepartmentsService.mapFiltersToAPI(userFilters)
        ];

        if (tableFilters && tableFilters.length > 0) {
            filters.push(...tableFilters);
        }

        const skip = resetPagination
            ? 0
            : tablePagination.skip;

        batch(() => {
            this.props.setMyDepartmentsLoading(true);
            this.props.setMyDepartmentsFilters(tableFilters);
            if (resetPagination) {
                this.props.setMyDepartmentsPagination(tablePagination.size, skip);
            }
        })

        this.source.current?.cancel?.();
        this.source.current = getCancelTokenSource();

        apiService
            .getJobOrders(filters, tablePagination.size, skip, tableSearchText, orderBy, this.source.current.token)
            .then(data => {
                batch(() => {
                    this.props.setMyDepartmentsData(data);
                    this.props.setMyDepartmentsLoading(false);
                })
            });
    }

    render() {
        const { column, displayName, sort, tableFilters, filterOptions } = this.props;

        return (
            <GridColumnHeader
                column={column}
                filterOptions={filterOptions}
                displayName={displayName}
                userSort={sort}
                tableFilters={tableFilters}
                settingsPage={Page.myDepartments}
                onRefreshTableData={this.refreshTableData}
                onApplyPageFilters={this.applyPageFilters}
            />
        )
    }
}

const mapStatetoProps = state => ({
    user: state.session.user,
    sort: state.session.user.settings.myDepartmentsPage.sort,
    activeDepartment: state.session.activeDepartment,
    activeMicroteam: state.session.activeMicroteam,
    filterOptions: state.mydepartments.filterOptions,
    tableSearchText: state.mydepartments.searchText,
    tablePagination: state.mydepartments.pagination,
    tableFilters: state.mydepartments.filters
})

const mapDispatchToProps = (dispatch) => ({
    setUser: user => dispatch(setUser(user)),
    setMyDepartmentsLoading: isLoading => dispatch(setMyDepartmentsLoading(isLoading)),
    setMyDepartmentsData: data => dispatch(setMyDepartmentsData(data)),
    setMyDepartmentsPagination: (size, skip) => dispatch(setMyDepartmentsPagination(size, skip)),
    setMyDepartmentsColumns: columns => dispatch(setMyDepartmentsColumns(columns)),
    setMyDepartmentsFilters: filters => dispatch(setMyDepartmentsFilters(filters))
})

export default connect(mapStatetoProps, mapDispatchToProps)(ColumnHeader);