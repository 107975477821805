import React from 'react';
import { connect } from 'react-redux';
import { FontIcon } from '@fluentui/react';
import { Role } from '../../../../constants/constants';

class TitleSection extends React.Component {
    render() {
        const { user, isDelegate } = this.props;
        const isAssociate = user.roleName === Role.associate;
        return (
            <div className="d-flex align-items-center">
                <h5 className="m-0"><b>Consolidated Orders</b>{isAssociate && ` • ${isDelegate ? "Delegate" : "Associate"} Departments`}</h5>
                {
                    isAssociate && isDelegate &&
                    <span className="ml-2">
                        <FontIcon iconName="BusinessCenterLogo" style={{ color: 'salmon' }} />
                    </span>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.session.user,
    isDelegate: state.consolidated.isDelegate
})

export default connect(mapStateToProps)(TitleSection);