import { IconButton } from '@fluentui/react';
import React from 'react';
import { Role } from '../../../../../constants/constants';
import PeopleList from '../../../../shared/PeopleList/PeopleList';
import { connect } from 'react-redux';
import { PrimaryButton, Spinner, Dropdown, DefaultButton } from '@fluentui/react';
import { AutoCompleteNarrow } from '../../../../shared/AutoCompleteNarrow/AutoCompleteNarrow';
import apiService from '../../../../../services/api-service';
import { addNotification } from '../../../../../store/actions';

class EditForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            displayName: '',
            selectedRole: null,
            selectedDepartments: []
        }
    }

    componentDidMount() {
        const { rowDetail } = this.props;
        const selectedUser = rowDetail.data;

        this.setState({
            displayName: selectedUser.fullname,
            selectedRole: selectedUser.rolename,
            selectedDepartments: selectedUser.departments.map((x, i) => ({
                key: x.kf_financialhierarchyid,
                text: x.departmentName
            }))
        });
    }

    saveChanges() {
        const { rowDetail } = this.props;
        const { selectedRole, selectedDepartments } = this.state;

        const selectedUser = rowDetail.data;

        this.setState({ loading: true });

        const departments = selectedDepartments
            && selectedDepartments.length > 0
            && (selectedRole === Role.officeLeader || selectedRole === Role.associate)
            ? selectedDepartments
            : [];

        let message = 'The profile was not saved';
        let success = false;

        apiService
            .assignRole(selectedUser.id, selectedRole, departments.map(x => x.key))
            .then(data => {
                if (data === true) {
                    rowDetail.node.data.rolename = selectedRole;
                    rowDetail.node.data.departments = departments.map(x => ({
                        kf_financialhierarchyid: x.key,
                        departmentName: x.text
                    }));
                    rowDetail.node.setData(rowDetail.node.data);
                    this.props.onClose();
                    message = "Profile saved";
                    success = true;
                }
            })
            .finally(() => {
                this.props.addNotification({ type: success ? 'success' : 'error', text: message });
                this.setState({ loading: false });
            });
    }

    getOptionsByKey(key) {
        const { filterOptions } = this.props;
        const result = filterOptions.filter(x => x.key === key);
        if (!result || result.length === 0) {
            return [];
        }

        return result[0].values.map((x, i) => ({ key: x.id, text: x.name }));
    }

    render() {
        const { loading, selectedRole, selectedDepartments } = this.state;
        const { rowDetail } = this.props;
        const selectedUser = rowDetail?.data;

        if (!selectedUser) {
            return null;
        }

        const roles = this.getOptionsByKey("roleName");
        const departments = this.getOptionsByKey("departmentName");

        return (
            <div className="position-fixed bg-white shadow p-3" style={style.container}>
                <div className="d-flex justify-content-between align-items-center">
                    {
                        selectedUser ?
                            <PeopleList
                                data={[{
                                    id: selectedUser.id,
                                    description: selectedUser.fullname,
                                    name: selectedUser.fullname.length > 1 ? selectedUser.fullname.slice(0, 2).toUpperCase() : selectedUser.fullname,
                                    thumbnail: ''
                                }]}
                            />
                            :
                            <h5 className="m-0">Create Profile</h5>
                    }
                    <IconButton
                        iconProps={{ iconName: 'Cancel' }}
                        onClick={() => this.props.onClose()}
                    />
                </div>

                <div className="col-12 d-flex mt-5 justify-content-end p-0">
                    {
                        loading ?
                            <Spinner className="mr-3" label="Saving profile..." ariaLive="assertive" labelPosition="right" />
                            :
                            <DefaultButton
                                className="mr-2"
                                onClick={() => this.props.onClose()}
                            >Cancel</DefaultButton>
                    }
                    <PrimaryButton
                        onClick={() => this.saveChanges()}
                        disabled={loading}
                    >Save Changes</PrimaryButton>
                </div>
                <div className="col-12 mt-4 p-0" style={{ overflowY: 'auto' }}>
                    {/* <TextField
                        label="Display Name"
                        placeholder="Enter a name"
                        value={displayName}
                        disabled={true}
                        className="col-12 p-0"
                        onChange={d => this.setState({ displayName: d.target.value })}
                    /> */}
                    <div className="col-11 d-flex p-1 align-items-end">
                        <Dropdown
                            placeholder="No role assigned"
                            label="Role"
                            options={roles}
                            selectedKey={selectedRole || null}
                            onChange={(_, val) => this.setState({ selectedRole: val.text })}
                            className="col-10 p-0 pr-2"
                        />
                        <DefaultButton
                            className="col-2"
                            onClick={() => this.setState({ selectedRole: '' })}
                        >Unassign Role</DefaultButton>
                    </div>
                    {
                        selectedRole && (selectedRole === Role.officeLeader || selectedRole === Role.associate) &&
                        <div className="col-12 p-1 mt-1" style={{ marginBottom: '200px' }}>
                            <AutoCompleteNarrow
                                placeholder="Select"
                                options={departments}
                                optionsSelected={selectedDepartments}
                                label="Department"
                                onSelectItem={(i) => this.setState({ selectedDepartments: i })}
                            />
                        </div>
                    }
                </div>
            </div >
        )
    }
}

const style = {
    container: {
        bottom: 0,
        right: 0,
        height: '100%',
        width: '70vh',
        minWidth: '25%',
        border: '1px solid #ddd',
        overflowY: 'auto'
    }
}

const mapStateToProps = state => ({
    user: state.session.user,
    filterOptions: state.security.filterOptions,
});

const mapDispatchToProps = (dispatch) => ({
    addNotification: not => dispatch(addNotification(not))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditForm);