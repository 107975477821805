import * as React from 'react';
import './FilterList.css';
import { List } from '@fluentui/react';
import CheckboxWithState from '../CheckboxWithState/CheckboxWithState';

class FilterList extends React.Component {
    ROWS_PER_PAGE = 3;
    MAX_ROW_HEIGHT = 0;

    constructor(props) {
        super(props);
        this.columnCount = React.createRef();
        this.rowHeight = React.createRef();

        this.getItemCountForPage = this.getItemCountForPage.bind(this);
        this.getPageHeight = this.getPageHeight.bind(this);
    }

    onRenderCell = (item, index) => {
        const { selectable } = this.props;

        return (
            <div className='d-flex mt-1 filter-list'>
                {
                    selectable &&
                    <div className='mr-2'>
                        <CheckboxWithState
                            checked={item.selected === true}
                            useInternalState={true}
                            onChange={checked => {
                                item.selected = checked === true;
                                this.props.onSelectItem(item);
                            }}
                        />
                    </div>
                }
                <div>
                    {item.key}
                </div>
            </div>
        );
    };

    getItemCountForPage(itemIndex, surfaceRect) {
        if (itemIndex === 0) {
            this.columnCount.current = Math.ceil(surfaceRect.width / this.MAX_ROW_HEIGHT);
            this.rowHeight.current = Math.floor(surfaceRect.width / this.columnCount.current);
        }
        return this.columnCount.current * this.ROWS_PER_PAGE;
    }

    getPageHeight() {
        return this.rowHeight.current * this.ROWS_PER_PAGE;
    }

    render() {
        const { data, maxHeight } = this.props;
        return <List
            items={data}
            // onShouldVirtualize={() => false}
            getItemCountForPage={this.getItemCountForPage}
            getPageHeight={this.getPageHeight}
            renderedWindowsAhead={4}
            onRenderCell={this.onRenderCell}
            className="scrollbar"
            style={{ maxHeight: maxHeight || 200, overflowY: 'auto' }}
        />
    }
}

export default FilterList