import React from 'react';
import GridImportantNoteCell from '../../../../shared/GridImportantNoteCell/GridImportantNoteCell';

class ImportantNoteCell extends React.Component {

    render() {
        const { rowDetail } = this.props;
        
        return <GridImportantNoteCell
            rowDetail={rowDetail}
            importantNote={rowDetail.value}
            readOnly={true}
        />
    }
};

export default ImportantNoteCell;