import React from 'react';
import Information from './Information/Information';
import PreferredHomePage from './PreferredHomePage/PreferredHomePage';
import OfficeDelegates from './OfficeDelegates/OfficeDelegates';
import { FontIcon } from '@fluentui/react';
import { Role } from "../../../constants/constants";
import { connect } from 'react-redux';
class UserPreferences extends React.Component {

    constructor(props) {
        super(props);

        this.sections = [
            {
                iconName: "Contact",
                title: "Information",
                component: <Information />,
                permissions: []
            },
            {
                iconName: "Home",
                title: "Home Page",
                component: <PreferredHomePage />,
                permissions: []
            },
            {
                iconName: "TeamFavorite",
                title: "Office Delegates",
                component: <OfficeDelegates />,
                permissions: [Role.officeLeader]
            }
        ];
    }

    render() {
        const { user } = this.props;

        return (
            <div className="p-3 bg-white" id="appContent" style={{ width: '100%', height: '100%' }}>
                <h5 className="m-0"><b>User Preferences</b></h5>
                <div className='mt-4 flex'>
                    {
                        this.sections
                            .filter(x => x.permissions.length === 0 || x.permissions.includes(user.roleName))
                            .map((section, index) =>
                                <div key={index} className="mb-5">
                                    <div className='d-flex'>
                                        <div className='mr-2'>
                                            <FontIcon iconName={section.iconName} style={{ color: 'salmon' }} />
                                        </div>
                                        <h5 style={{ fontVariant: 'all-small-caps' }}>{section.title}</h5>
                                    </div>
                                    {section.component}
                                </div>
                            )
                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    user: state.session.user
})

export default connect(mapStateToProps)(UserPreferences);