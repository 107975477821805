import * as actionTypes from './actionTypes'

export const setToken = payload => ({
    type: actionTypes.TOKEN,
    payload
})

export const setUser = payload => ({
    type: actionTypes.USER,
    payload
})

export const toggleSideMenu = payload => ({
    type: actionTypes.SIDEMENU,
    payload
})

export const setActiveDepartment = payload => ({
    type: actionTypes.SET_ACTIVE_DEPARTMENT,
    payload
})

export const setActiveMicroteam = payload => ({
    type: actionTypes.SET_ACTIVE_MICROTEAM,
    payload
})