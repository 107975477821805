import React from 'react';
import { connect } from 'react-redux';
import { setConsolidatedColumns } from '../../../../../store/actions';
import { Page } from '../../../../../constants/constants';
import GridActionHeader from '../../../../shared/GridActionHeader/GridActionHeader';

class ActionsHeader extends React.Component {

    render() {
        const { rowDetail, columnsDef } = this.props;
        
        return (
            <GridActionHeader
                page={Page.consolidatedOrders}
                setColumns={columns => this.props.setConsolidatedColumns(columns)}
                columnsToShow={this.props.columnsToShow}
                columnsDef={columnsDef}
                rowDetail={rowDetail}
            />
        );
    }
};

const mapStateToProps = state => ({
    columnsToShow: state.consolidated.columns
})

const mapDispatchToProps = {
    setConsolidatedColumns
}

export default connect(mapStateToProps, mapDispatchToProps)(ActionsHeader);
