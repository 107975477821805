import * as React from 'react';
import { Panel, PanelType } from '@fluentui/react';
import RecruitersDetail from '../RecruitersDetail/RecruitersDetail';

class RecruitersDetailModal extends React.Component {

    render() {
        const { rowDetail, isOpen, onDismiss, compact, readOnly } = this.props;

        return (
            <Panel
                headerText={rowDetail?.data?.trm_jobtitle}
                type={PanelType.large}
                isOpen={isOpen}
                onDismiss={onDismiss}
            >
                {
                    rowDetail?.data &&
                    <RecruitersDetail rowDetail={rowDetail} compact={compact} readOnly={readOnly} />
                }
            </Panel>
        )
    }
}

export default RecruitersDetailModal