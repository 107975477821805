import '../../App.css';
import React from 'react';
import { connect } from 'react-redux';
import { setConsolidatedFilterOptions } from '../../../store/actions';
import TitleSection from './TitleSection/TitleSection';
import OptionsSection from './OptionsSection/OptionsSection';
import TableSection from './TableSection/TableSection';
class ConsolidatedOrders extends React.Component {

    render() {

        return (
            <div className="p-3 bg-white" id="appContent" style={{ width: '100%', height: '100%' }}>
                <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                    <div className="mb-2 d-sm-block d-md-flex justify-content-between align-items-center">
                        <TitleSection />
                    </div>
                    <div>
                        <OptionsSection />
                    </div>
                    <TableSection />
                </div>
            </div>
        )

    }
}

const mapStateToProps = (state) => ({
    user: state.session.user,
    tableFilters: state.consolidated.filters,
    isDelegate: state.consolidated.isDelegate
})
const mapDispatchToProps = (dispatch) => ({
    setConsolidatedFilterOptions: options => dispatch(setConsolidatedFilterOptions(options))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ConsolidatedOrders)