import React, { createRef } from 'react';
import { connect, batch } from 'react-redux';
import {
    setActiveDepartment,
    setActiveMicroteam,
    setMyDepartmentsData,
    setMyDepartmentsPagination,
    setMyDepartmentsSearchText,
    setMyDepartmentsLoading,
    setMyDepartmentsFilterOptions,
    setMyDepartmentsFilters,
    setUser
} from '../../../../store/actions';
import { DefaultButton, PrimaryButton, IconButton } from '@fluentui/react';
import { SearchBox } from '@fluentui/react/lib/SearchBox';
import Status from '../../../shared/Status/Status';
import apiService from '../../../../services/api-service';
import { getCancelTokenSource } from '../../../../api';
import { Dialog, DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';
import myDepartmentsService from '../../../../services/mydepartments-service';
import PermissionControllerRender from '../../../../utils/auth';
import { TextFilterAction, Page, Field } from '../../../../constants/constants';
import ExportCsvModal from '../../../shared/ExportCsvModal/ExportCsvModal';
import GridFilters from '../../../shared/GridFilters/GridFilters';
import GridDisplayFilters from '../../../shared/GridDisplayFilters/GridDisplayFilters';
import { columnsDef } from '../TableSection/Columns'

class OptionsSection extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showFilters: false,
            showDeleteMicroteam: false,
            showExportCsv: false,
            selectedFilterOptions: []
        }
        this.source = createRef();
    }

    componentWillUnmount() {
        this.source.current?.cancel?.();
    }

    deleteMicroteam(microteam) {
        this.setState({ showDeleteMicroteam: false });

        const { activeDepartment, user, pagination } = this.props;

        const microteams = activeDepartment.microteams.filter(mt => mt.microteamid !== microteam.microteamid);
        user.officeLeaderDepartmentList.filter(x => x.kf_financialhierarchyid === activeDepartment.kf_financialhierarchyid)[0].microteams = microteams;
        batch(() => {
            this.props.setUser(user);
            this.props.setActiveDepartment(activeDepartment.kf_financialhierarchyid);
            this.props.setActiveMicroteam();
        });

        this.refreshTableData(pagination.size, 0, '', [{
            Option: Field.owner_kf_financialhierarchyid,
            Action: TextFilterAction.Equals.key,
            Value: activeDepartment.kf_financialhierarchyid
        }]);

        apiService
            .deleteMicroteam(microteam.microteamid)
            .then(data => {
                if (data === true) {
                    return;
                }
                console.error("Delete Microteam - Something went wrong " + data);
                // IF FAIL... ROLLBACK?
            });
    }

    search(textValue) {
        const { pagination, setMyDepartmentsSearchText } = this.props;
        setMyDepartmentsSearchText(textValue);
        this.refreshTableData(pagination.size, 0, textValue, this.getDefaultFilters());
    }

    getDefaultFilters() {
        const { activeMicroteam, activeDepartment, user } = this.props;

        const groupId = this.getGroupId();
        const filtersToMap = user.settings.myDepartmentsPage.filter.filters.filter(x => x.group === groupId);

        return [
            myDepartmentsService.createMandatoryFilter(activeMicroteam?.microteamid, activeDepartment?.kf_financialhierarchyid),
            ...myDepartmentsService.mapFiltersToAPI(filtersToMap)
        ];
    }

    getFilters(filters) {
        const { tableFilters } = this.props;

        if (tableFilters && tableFilters.length > 0) {
            filters.push(...tableFilters);
        }

        return filters;
    }

    getOrderBy() {
        const { user } = this.props;
        return myDepartmentsService.createGroupBy(user.settings.myDepartmentsPage.sort);
    }

    refreshTableData(size, skip, textValue = '', baseFilters = []) {
        const filters = this.getFilters(baseFilters);
        const orderBy = this.getOrderBy();
        this.props.setMyDepartmentsLoading(true);

        this.source.current?.cancel?.();
        this.source.current = getCancelTokenSource();

        apiService
            .getJobOrders(filters, size, skip, textValue, orderBy, this.source.current.token)
            .then(data => {
                batch(() => {
                    this.props.setMyDepartmentsData(data);
                    this.props.setMyDepartmentsPagination(size, skip);
                    this.props.setMyDepartmentsLoading(false);
                })
            });
    }

    getGroupId() {
        const { activeDepartment, activeMicroteam } = this.props;
        return activeMicroteam?.microteamid || activeDepartment.kf_financialhierarchyid;
    }

    setTrmStatusFilter(status) {
        const { user, setUser, pagination, searchText } = this.props;

        const groupId = this.getGroupId();

        let userFilters = user.settings.myDepartmentsPage.filter.filters.filter(x => x.group !== groupId);
        let groupFilters = user.settings.myDepartmentsPage.filter.filters.filter(x => x.columnName !== "trm_status" && x.group === groupId);
        if (status !== "All Status") {
            groupFilters.push({
                columnName: "trm_status",
                action: TextFilterAction.Equals.key,
                value: status,
                group: groupId
            });
        }

        user.settings.myDepartmentsPage.filter.filters = [...userFilters, ...groupFilters];
        setUser(user);

        apiService.setUserFilters(user.oid, Page.myDepartments, user.settings.myDepartmentsPage.filter.filters);

        this.refreshTableData(pagination.size, 0, searchText, this.getDefaultFilters());
    }

    getTrmStatusFilter() {
        const { user } = this.props;

        const userFilters = user.settings.myDepartmentsPage.filter.filters;
        const groupId = this.getGroupId();

        if (userFilters && userFilters.length > 0) {
            const trm_statuses = userFilters.filter(x => x.columnName === "trm_status" && x.group === groupId);
            if (trm_statuses.length > 0) {
                return trm_statuses[0].value;
            }
        }
        return "All Status";
    }

    refresh(resetSkip) {
        const { pagination, searchText } = this.props;

        const skip = resetSkip ? 0 : pagination.skip;
        this.refreshTableData(pagination.size, skip, searchText, this.getDefaultFilters())
        this.refreshFilterOptions();
    }

    refreshFilterOptions() {
        const { user, activeMicroteam, activeDepartment } = this.props;
        const filterGroup = myDepartmentsService.getGroupId(activeMicroteam?.microteamid, activeDepartment?.kf_financialhierarchyid)
        const userFilters = user.settings.myDepartmentsPage.filter.filters.filter(x => x.group === filterGroup);
        this.setState({ selectedFilterOptions: userFilters });
    }

    applyFilter() {
        const { selectedFilterOptions } = this.state;
        const { user, activeMicroteam, activeDepartment, pagination, searchText, tableFilters } = this.props;

        const filters = [
            myDepartmentsService.createMandatoryFilter(activeMicroteam?.microteamid, activeDepartment?.kf_financialhierarchyid),
            ...myDepartmentsService.mapFiltersToAPI(selectedFilterOptions)
        ];

        if (tableFilters && tableFilters.length > 0) {
            filters.push(...tableFilters);
        }

        const filterGroup = myDepartmentsService.getGroupId(activeMicroteam?.microteamid, activeDepartment?.kf_financialhierarchyid);
        const saveFilters = user.settings.myDepartmentsPage.filter.filters.filter(x => x.group !== filterGroup);
        saveFilters.push(...selectedFilterOptions);

        apiService.setUserFilters(user.oid, Page.myDepartments, saveFilters)
            .then(data => {
                // IF FAIL... ROLLBACK ?
            });

        user.settings.myDepartmentsPage.filter.filters = saveFilters;
        const orderBy = myDepartmentsService.createGroupBy(user.settings.myDepartmentsPage.sort);

        this.props.setMyDepartmentsLoading(true);

        apiService.getJobOrders(filters, pagination.size, 0, searchText, orderBy)
            .then(data => {
                batch(() => {
                    this.props.setMyDepartmentsData(data);
                    this.props.setMyDepartmentsPagination(pagination.size, 0);
                    this.props.setMyDepartmentsLoading(false);
                    this.props.setUser(user);
                });
            })
            .catch(error => this.props.setUser(user));
    }

    render() {
        const { activeMicroteam, activeDepartment, filterOptions, searchText, tableData, showNotes } = this.props;
        const { showFilters, showDeleteMicroteam, showExportCsv } = this.state;

        const microteamName = activeMicroteam?.microteamname || "";
        const status = this.getTrmStatusFilter();
        const groupId = myDepartmentsService.getGroupId(activeMicroteam?.microteamid, activeDepartment?.kf_financialhierarchyid);

        return (
            <>
                <div className="mb-2">
                    <div className="row col-12">
                        {/* <span>{microteamName && `${microteamName} Orders`}</span> */}
                        {/* {
                            loading &&
                            <div className="p-0 d-flex justify-content-center ml-3">
                                <Spinner label="Loading data..." ariaLive="assertive" labelPosition="right" />
                            </div>
                        } */}
                    </div>
                    <div className="d-sm-block d-md-flex justify-content-between align-items-center order--filter mt-3">
                        <div className="d-flex col-6 p-0 align-items-center">
                            <SearchBox
                                placeholder="Search Orders"
                                className="col-5 mr-2"
                                onClear={() => this.search('')}
                                onSearch={nv => this.search(nv)}
                                onChange={(_, nv) => this.props.setMyDepartmentsSearchText(nv)}
                                value={searchText}
                            />
                            <Status
                                readOnly={false}
                                onChange={(status) => this.setTrmStatusFilter(status)}
                                status={status}
                                itemsType="TRM"
                            />
                            <IconButton
                                title="Filters"
                                className="ml-1"
                                disabled={showNotes}
                                onClick={() => this.setState({ showFilters: !showFilters })}
                                iconProps={{ iconName: 'Filter' }}
                            />
                            <IconButton
                                title="Refresh Data"
                                onClick={() => this.refresh()}
                                iconProps={{ iconName: 'Refresh' }}
                            />
                            <IconButton
                                title="Export to CSV"
                                disabled={!tableData?.results?.length || showNotes}
                                onClick={() => this.setState({ showExportCsv: true })}
                                iconProps={{ iconName: 'DownloadDocument' }}
                            />
                            {
                                activeMicroteam && microteamName &&
                                <PermissionControllerRender for="DELETE_MICROTEAM" skip={activeDepartment?.isDelegate}>
                                    <IconButton
                                        title="Delete Microteam"
                                        onClick={() => this.setState({ showDeleteMicroteam: true })}
                                        iconProps={{ iconName: 'Trash' }}
                                    />
                                </PermissionControllerRender>
                            }
                        </div>
                        {/* <div>
                        {
                            !this.props.onlyView &&
                            this.state.selectedRows && (this.state.selectedRows.length > 0) &&
                            <div className="d-flex">
                                <ActionButton iconProps={{ iconName: 'AddFriend' }}>
                                    Add Recruiter
                                </ActionButton>
                                <ActionButton className="ml-3" iconProps={{ iconName: 'FabricMovetoFolder' }} >
                                    Move To
                                </ActionButton>
                                <ActionButton className="ml-3" iconProps={{ iconName: 'Delete' }}
                                    onClick={() => this.setState({ showRemoveDialog: true })}>
                                    Remove
                                </ActionButton>
                            </div>
                        }
                    </div> */}
                    </div>
                </div>

                <Dialog
                    hidden={!showDeleteMicroteam}
                    onDismiss={() => this.setState({ showDeleteMicroteam: false })}
                    maxWidth={500}
                    dialogContentProps={{
                        type: DialogType.normal,
                        title: `Delete '${microteamName}' Microteam`,
                        subText: 'Deleting a Microteam returns all Job Orders currently assigned to the Microteam to the \'All\' orders list, and removes the named Microteam from the Department. Continue?',
                    }}
                >
                    <DialogFooter>
                        <PrimaryButton onClick={() => { this.deleteMicroteam(activeMicroteam) }} text="Yes" />
                        <DefaultButton onClick={() => { this.setState({ showDeleteMicroteam: false }) }} text="No" />
                    </DialogFooter>
                </Dialog>

                <GridDisplayFilters
                    userFilters={this.props.userFilters}
                    tableFilters={this.props.tableFilters}
                    filterOptions={this.props.filterOptions}
                    columns={columnsDef}
                    page={Page.myDepartments}
                    groupId={groupId}
                    onChange={filters => this.props.setMyDepartmentsFilters(filters)}
                    onRefresh={() => this.refresh(true)}
                />

                <GridFilters
                    loading={this.props.loading}
                    isOpen={showFilters}
                    filterOptions={filterOptions}
                    selectedFilterOptions={this.state.selectedFilterOptions}
                    onOpen={() => this.refreshFilterOptions()}
                    onClose={() => this.setState({ showFilters: false })}
                    onUpdate={filterOptions => this.setState({ selectedFilterOptions: filterOptions })}
                    onApply={() => this.applyFilter()}
                    groupId={groupId}
                />

                <ExportCsvModal
                    page={Page.myDepartments}
                    isOpen={showExportCsv}
                    onDismiss={() => this.setState({ showExportCsv: false })}
                    filters={this.getFilters(this.getDefaultFilters())}
                    text={searchText}
                    orderBy={this.getOrderBy()}
                    columnsDef={columnsDef}
                />
            </>
        )
    }
}

const mapStateToProps = state => ({
    loading: state.mydepartments.loading,
    pagination: state.mydepartments.pagination,
    searchText: state.mydepartments.searchText,
    tableFilters: state.mydepartments.filters,
    tableData: state.mydepartments.data,
    filterOptions: state.mydepartments.filterOptions,
    showNotes: state.mydepartments.showNotes,
    user: state.session.user,
    activeDepartment: state.session.activeDepartment,
    activeMicroteam: state.session.activeMicroteam,
    userFilters: state.session.user.settings.myDepartmentsPage.filter.filters
});

const mapDispatchToProps = {
    setActiveDepartment,
    setActiveMicroteam,
    setMyDepartmentsData,
    setMyDepartmentsPagination,
    setMyDepartmentsLoading,
    setMyDepartmentsSearchText,
    setMyDepartmentsFilterOptions,
    setMyDepartmentsFilters,
    setUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(OptionsSection)