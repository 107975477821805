import React from 'react';
import { connect } from 'react-redux';
import { setUser } from '../../../store/actions';
import apiService from '../../../services/api-service';
import { ActionButton } from '@fluentui/react';

class GridDisplayFilters extends React.Component {

    getFilters() {
        const { userFilters, tableFilters, filterOptions, columns, groupId } = this.props;

        const filters = [];

        userFilters.forEach(f => {
            if (f.group !== groupId)
                return;

            const filterOptionsFiltered = filterOptions.filter(x => x.key === f.columnName);
            const displayName = filterOptionsFiltered.length > 0 ? filterOptionsFiltered[0].displayName : "";

            filters.push({
                name: displayName,
                option: f.columnName,
                action: f.action,
                value: f.value,
                save: true
            });
        })

        tableFilters.forEach(f => {
            let displayName = f.Option;
            const columnsFiltered = columns.filter(x => x.field === f.Option);
            if (columnsFiltered.length > 0) {
                displayName = columnsFiltered[0].headerName;
            } else {
                const filterOptionsFiltered = filterOptions.filter(x => x.key === f.columnName);
                if (filterOptionsFiltered.length > 0) {
                    displayName = filterOptionsFiltered[0].displayName;
                }
            }

            filters.push({
                name: displayName,
                option: f.Option,
                action: f.Action,
                value: f.Value,
                save: false
            });
        });

        return filters;
    }

    getNonGroupIdUserFilters() {
        const { user, page, groupId } = this.props;

        return user.settings[page + "Page"].filter.filters.filter(x => x.group !== groupId);
    }

    removeFilter(filter, index) {
        const { groupId } = this.props;

        const filters = this.getFilters();
        const newFilters = filters.filter((x, i) => index !== i);

        if (filter.save) {
            const mapFilters = newFilters.filter(x => x.save).map(x => ({
                columnName: x.option,
                action: x.action,
                value: x.value,
                group: groupId
            }));

            const rest = this.getNonGroupIdUserFilters();
            const saveFilters = [...rest, ...mapFilters];

            this.saveFilters(saveFilters);
        } else {
            const tableFilters = newFilters.filter(x => !x.save).map(x => ({
                Option: x.option,
                Action: x.action,
                Value: x.value
            }));

            this.props.onChange(tableFilters);
        }
        this.refresh();
    }

    refresh() {
        window.setTimeout(() => this.props.onRefresh(), 100);
    }

    clearFilters() {
        const rest = this.getNonGroupIdUserFilters();
        this.saveFilters(rest);
        this.props.onChange([]);
        this.refresh();
    }

    saveFilters(filters) {
        const { user, page } = this.props;

        user.settings[page + "Page"].filter.filters = filters;

        this.props.setUser(user);

        apiService.setUserFilters(user.oid, page, filters)
            .then(data => {
                // IF FAIL... ROLLBACK ?
            });
    }

    render() {
        const filters = this.getFilters();

        return (
            <div className="col-12 p-0" style={{ msOverflowX: 'auto', overflow: 'auto', maxHeight: '140px' }}>
                {
                    !!filters.length && (
                        <>
                            {
                                filters.length > 1 &&
                                <ActionButton
                                    text="Clear Filters"
                                    onClick={() => this.clearFilters()}
                                    style={{ height: 'auto', paddingLeft: 0, marginLeft: 0 }}
                                    iconProps={{ iconName: 'ClearFilter', styles: { root: { paddingLeft: 0, marginLeft: 0 } } }}
                                />
                            }
                            {
                                filters.map((f, index) => {
                                    return (
                                        <div key={index} className={`badge badge-pill badge-${f.save ? 'light' : 'secondary'} p-1 rounded border mr-1`}>
                                            <span>
                                                {f.name} : {f.value} <span style={{ fontSize: '8px' }}>({f.action})</span>
                                            </span>
                                            <button
                                                className="ml-1 border-0 bg-light rounded"
                                                onClick={() => this.removeFilter(f, index)}
                                            >X</button>
                                        </div>
                                    )
                                })
                            }
                        </>
                    )
                }
            </div>
        )
    }
}

const mapStatetoProps = state => ({
    user: state.session.user
})

const mapDispatchToProps = (dispatch) => ({
    setUser: user => dispatch(setUser(user))
})

export default connect(mapStatetoProps, mapDispatchToProps)(GridDisplayFilters);