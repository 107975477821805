import * as actionTypes from './actionTypes'

let id = 0;

export const addNotification = payload => ({
    type: actionTypes.ADD_NOTIFICATION,
    payload: {
        ...payload,
        id: id++
    }
})

export const removeNotification = id => ({
    type: actionTypes.REMOVE_NOTIFICATION,
    payload: id
})