import * as React from 'react';
import './PeopleList.css';
import { ActivityItem, List } from '@fluentui/react';
import { CandidateStatusColors } from '../../../constants/constants';

class PeopleList extends React.Component {

    onRenderCell = item => {
        const { selectable, removable, onSelectItem, randomInitialsColor } = this.props;
        return (
            <div className={`people-list d-flex align-items-center col-12 ${!selectable ? 'pl-0' : 'pl-1'}`}>
                {
                    selectable &&
                    <input type="checkbox" onChange={(e) => onSelectItem(item, e.target.checked)} />
                }
                <ActivityItem
                    className="d-flex align-items-center col-11 m-0 px-1 pb-1 jo-list"
                    activityPersonas={[
                        {
                            initialsColor: randomInitialsColor === true ? undefined : item.initialsColor || CandidateStatusColors[item.additional] || "black",
                            imageInitials: item.name || item.imageInitials,
                            text: item.description || item.text,
                            secondaryText: item.additional,
                            showSecondaryText: true
                        },
                    ]}
                    activityDescription={item.description || item.text}
                    comments={item.additional}
                />
                {
                    removable &&
                    <i className="fa fa-close" style={{ marginLeft: 'auto'}} onClick={() => this.props.itemRemoved(item)} />
                }
            </div>
        );
    };

    render() {
        const { data, maxHeight } = this.props;
        return <List
            items={data}
            onRenderCell={this.onRenderCell}
            className="scrollbar"
            style={{ maxHeight: maxHeight || 200, overflowY: 'auto' }}
        />
    }
}

export default PeopleList