import * as React from 'react';
import { MessageBar } from '@fluentui/react';

class NoData extends React.Component {

    render() {
        return <MessageBar>No Data</MessageBar>
    }
}

export default NoData