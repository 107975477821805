import * as React from 'react';
import { Panel, PanelType } from '@fluentui/react';
import Notes from '../Notes/Notes';

class NotesModal extends React.Component {

    render() {
        const { rowDetail, isOpen, onDismiss, readOnly } = this.props;


        return (
            <Panel
                isBlocking={false}
                headerText={rowDetail?.data?.trm_jobtitle}
                type={PanelType.smallFixedFar}
                isOpen={isOpen}
                onDismiss={onDismiss}
                style={{ height: '100%' }}
                // styles={{ content: { height: '100%' }, root: { height: '100%' } }}
            >
                {
                    rowDetail?.data &&
                    <Notes
                        jobOrder={rowDetail.data}
                        compact={true}
                        readOnly={readOnly}
                    />
                }
            </Panel>
        )
    }
}

export default NotesModal