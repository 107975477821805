import * as actionTypes from '../actions/actionTypes';

const defaultState = {
    token: null,
    user: null,
    sidemenu: true,
    activeDepartment: null,
    activeMicroteam: null
}

const newState = (state, newData) => {
    return Object.assign({}, state, newData)
}

const session = (state = defaultState, action) => {
    switch (action.type) {
        case actionTypes.TOKEN:
            return newState(state, {
                token: action.payload
            })
        case actionTypes.USER:
            return newState(state, {
                user: action.payload
            })
        case actionTypes.SIDEMENU:
            return newState(state, {
                sidemenu: action.payload
            })
        case actionTypes.SET_ACTIVE_DEPARTMENT:
            const departmentId = action.payload;
            const departments = state.user.officeLeaderDepartmentList;
            const departmentFilter = departments.filter(dep => dep.kf_financialhierarchyid === departmentId);
            const activeDepartment = departmentFilter.length > 0 ? departmentFilter[0] : departments[0];
            return newState(state, {
                activeDepartment: activeDepartment
            })
        case actionTypes.SET_ACTIVE_MICROTEAM:
            const microteamId = action.payload;
            const microteams = state.activeDepartment.microteams;
            let activeMicroteam = null;
            if ((microteams.length > 0) && microteamId) {
                const microteamFilter = microteams.filter(dep => dep.microteamid === microteamId);
                if (microteamFilter.length > 0) {
                    activeMicroteam = microteamFilter[0];
                }
            }
            return newState(state, {
                activeMicroteam: activeMicroteam
            })
        default:
            return state
    }
}
export default session;