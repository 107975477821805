import * as actionTypes from './actionTypes'

export const setConsolidatedLoading = payload => ({
    type: actionTypes.SET_CONSOLIDATED_LOADING,
    payload
})

export const setConsolidatedSelectedRows = payload => ({
    type: actionTypes.SET_CONSOLIDATED_SELECTEDROWS,
    payload
})

export const setConsolidatedRowDetail = payload => ({
    type: actionTypes.SET_CONSOLIDATED_ROWDETAIL,
    payload
})

export const setConsolidatedData = payload => ({
    type: actionTypes.SET_CONSOLIDATED_DATA,
    payload
})

export const setConsolidatedPagination = (size, skip) => ({
    type: actionTypes.SET_CONSOLIDATED_PAGINATION,
    payload: { size, skip }
})

export const setConsolidatedFilters = payload => ({
    type: actionTypes.SET_CONSOLIDATED_FILTERS,
    payload
})

export const setConsolidatedFilterOptions = payload => ({
    type: actionTypes.SET_CONSOLIDATED_FILTEROPTIONS,
    payload
})

export const setConsolidatedSort = (columnName, sortOrder) => ({
    type: actionTypes.SET_CONSOLIDATED_SORT,
    payload: { columnName, sortOrder }
})

export const setConsolidatedColumns = payload => ({
    type: actionTypes.SET_CONSOLIDATED_COLUMNS,
    payload
})

export const setConsolidatedSearchText = payload => ({
    type: actionTypes.SET_CONSOLIDATED_SEARCHTEXT,
    payload
})

export const setConsolidatedShowNotes = payload => ({
    type: actionTypes.SET_CONSOLIDATED_SHOWNOTES,
    payload
})

export const setConsolidatedShowRecruiters = payload => ({
    type: actionTypes.SET_CONSOLIDATED_SHOWRECRUITERS,
    payload
})

export const setConsolidatedIsDelegate = payload => ({
    type: actionTypes.SET_CONSOLIDATED_ISDELEGATE,
    payload
})