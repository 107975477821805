import React from 'react';
import './AutoCompleteFullScreen.css';
import { DefaultButton, SearchBox } from '@fluentui/react';

export class AutoCompleteFullScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            value: '',
            filterOption: [],
            optionsSelected: [],
            showOptions: false
        }


        this.wrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        const { options } = this.props;
        document.addEventListener('mousedown', this.handleClickOutside);
        this.setState({ filterOption: options });
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            this.setState({ showOptions: false });
        }
    }

    filter(text) {
        const { options } = this.props;
        text = text.toLowerCase();
        const filterOption = options.filter(o => {
            const match = (o.text).toLowerCase().indexOf(text) !== -1;
            return match;
        });
        this.setState({ filterOption });
    }

    onSelectItem(item) {
        let { optionsSelected } = this.state;
        if (!this.isOnList(optionsSelected, item.key)) {
            optionsSelected.push(item)
        } else {
            optionsSelected = optionsSelected.filter(e => e.key !== item.key);
        }
        this.setState({ optionsSelected, value: '' });
        this.filter('');
        this.props.onSelectItem(optionsSelected);
    }

    isOnList(options, key) {
        const isSelected = options.filter(e => e.key === key).length > 0;
        return isSelected;
    }

    cleanOrders() {
        this.setState({ optionsSelected: [] });
        this.filter('');
        this.props.onSelectItem([]);
    }

    showOptions(show) {
        setTimeout(() => {
            this.setState({ showOptions: show });
        }, show ? 0 : 400);
    }


    render() {
        const { className, label, placeholder } = this.props;
        const { filterOption, optionsSelected, showOptions, value } = this.state;
        return (
            <div className={className}>
                <div className="row col-12 mt-0">
                    {
                        optionsSelected && (optionsSelected.length > 0) ?
                        <>
                            {

                                optionsSelected.map(i => (
                                    <span key={i.key} className="badge badge-pill badge-light p-2 mr-1 mb-1 rounded border">{i.text}
                                        <button className="ml-2 border-0 bg-light rounded"
                                            onClick={() => this.onSelectItem(i)}
                                        >x</button></span>
                                ))
                            }
                            <DefaultButton onClick={() => this.cleanOrders()}>
                                Clear All
                            </DefaultButton>
                        </>
                        :
                        <div className="p-3"></div>
                    }
                </div>
                <small style={styles.inputLabel}>{label}</small>
                <div ref={this.wrapperRef} className="col-12 p-0">
                    {
                        <SearchBox
                            value={value}
                            style={styles.searchBox}
                            placeholder={placeholder}
                            onChange={(i, val) => { this.setState({ value: val }, this.filter(val)) }}
                            // componentRef={(r) => {if (r && (showOptions !== r.hasFocus())) { this.showOptions(r.hasFocus()) }}}
                            onFocus={() => this.showOptions(true)} // Only listen to open
                        />
                    }
                    {
                        showOptions &&
                        <>
                            <div
                                className="position-fixed"
                                style={{ top: 0, left: 0, height: '100%', width: '100%' }}
                                onClick={() => this.showOptions(false)}
                            />

                            <div className="position-relative"
                                style={{ left: 0, width: '100%', zIndex: 3 }}
                            >
                                <div className="col-12 shadow bg-white">
                                    <div className="p-2 float-right">
                                        <DefaultButton onClick={() => this.showOptions(false)}>Close</DefaultButton>
                                    </div>
                                    <div className="position-relative optionBox p-0 scrollbar">
                                        {
                                            filterOption.map((o, i) => (
                                                <li key={o.key} className="py-2 px-4 d-flex align-items-center">
                                                    <input type="checkbox" checked={this.isOnList(optionsSelected, o.key)}
                                                        className="mr-2" id={'chbkx-' + i} onChange={(e) => this.onSelectItem(o)} />
                                                    <label className="m-0" htmlFor={'chbkx-' + i}>{o.text}</label>
                                                </li>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </div>
            </div>
        )
    }
}

const styles = {
    inputLabel: {
        fontWeight: 500
    },
    searchBox: {
        position: 'relative'
    }
}