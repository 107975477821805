import '../../App.css';
import React from 'react';
import { connect } from 'react-redux';
import { Spinner } from '@fluentui/react';
import MicroteamsSection from './MicroteamsSection/MicroteamsSection';
import TitleSection from './TitleSection/TitleSection';
import OptionsSection from './OptionsSection/OptionsSection';
import TableSection from './TableSection/TableSection';

class MyDepartments extends React.Component {

    render() {
        const { activeDepartment } = this.props;

        if (!activeDepartment) {
            return (
                <div className="mt-3">
                    <Spinner label="Checking department, please wait..." ariaLive="assertive" labelPosition="right" />
                </div>
            )
        }

        return (
            <div className="p-3 bg-white" id="appContent" style={{ width: '100%', height: '100%' }}>
                <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                    <div className="mb-2 d-sm-block d-md-flex justify-content-between align-items-center">
                        <TitleSection />
                        <MicroteamsSection />
                    </div>
                    <div>
                        <OptionsSection />
                    </div>
                    <TableSection />
                </div>
            </div>
        )

    }
}

const mapStateToProps = (state) => ({
    activeDepartment: state.session.activeDepartment
})

export default connect(
    mapStateToProps
)(MyDepartments)