import * as React from 'react';
import { ProgressIndicator } from '@fluentui/react';

class Loading extends React.Component {

    render() {
        const { label, description, percentComplete } = this.props;

        return (
            <div className='d-flex flex-column align-items-center pt-5'>
                <h5>Allocation Board</h5>
                <div style={{ width: '350px' }}>
                    <ProgressIndicator
                        label={label}
                        description={description}
                        percentComplete={percentComplete}
                    />
                </div>
            </div>
        )
    }
}

export default Loading;