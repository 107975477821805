import config from "./config";
import axios from 'axios';
import store from './store/store';
import { addNotification, setSystemLoading } from "./store/actions";
import { authentication } from "@microsoft/teams-js";
import { LocalTokenKey } from './constants/constants';

export const getCancelTokenSource = axios.CancelToken.source;

const api = axios.create({
    baseURL: config.REACT_APP_BASE_URL
});

const defaultTimeoutMs = 300000; // 5 minutes.
const timeoutMs = parseInt(config.REACT_APP_TIMEOUT_MS);
const useCustomTimeoutMs = timeoutMs && timeoutMs > 5000;
api.defaults.timeout = useCustomTimeoutMs ? timeoutMs : defaultTimeoutMs;
console.log(`Timeout: ${api.defaults.timeout}ms`, `| ${useCustomTimeoutMs ? "Custom" : "Default (5 minutes)"} value`);

api.interceptors.request.use(
    async (request) => await requestHandler(request),
    (error) => errorHandler(error)
);

api.interceptors.response.use(
    (response) => responseHandler(response),
    (error) => errorHandler(error)
);

const requestHandler = request => {
    if (!request.url.includes('/authprocess')) {
        store.dispatch(setSystemLoading(true));
    }

    // ============== LOCAL DEVELOPMENT ============== 
    const localtoken = window.localStorage.getItem(LocalTokenKey);
    if (localtoken) {
        request.headers.Authorization = 'Bearer ' + localtoken;
        request.headers['Content-Type'] = 'application/json';
        return new Promise(
            (resolve) => resolve(request),
            (reject) => reject(request)
        );
    }
    // ============ end: LOCAL DEVELOPMENT ============

    return new Promise(
        async (resolve) => {
            try {
                const token = await authentication.getAuthToken({ silent: true });
                request.headers.Authorization = 'Bearer ' + token;
                request.headers['Content-Type'] = 'application/json';
                resolve(request);
            } catch (error) {
                console.error("failureCallback", error);
                resolve(request);
            }
        },
        (reject) => {
            reject(request);
        });
};

const responseHandler = response => {
    store.dispatch(setSystemLoading(false));

    if (response.status !== 200 && response.status !== 204) {
        errorController(response);
        return null;
    }
    return response.data;
};

const errorHandler = error => {
    if (!error.__CANCEL__) {
        store.dispatch(setSystemLoading(false));
        errorController(error)
    }
    return Promise.reject(error);
};

function errorController(e) {
    store.dispatch(addNotification({ type: 'error', text: `${e.message || e.status}` }));
}

export default api;

