import React from 'react';
import { connect, batch } from 'react-redux';
import { Spinner, Modal } from '@fluentui/react';
import apiService from '../../../../services/api-service';
import BasicTable from '../../../shared/GeneralTable/BasicTable'
import {
    setSecurityLoading,
    setSecurityRowDetail,
    setSecurityData,
    setSecurityPagination,
    setSecurityColumns,
    setSecurityShowForm,
    setSecurityFilterOptions,
    setSecuritySearchText
} from '../../../../store/actions';
import { columnsDef } from './Columns';
import SecurityRowDetail from './SecurityRowDetail/SecurityRowDetail';
import EditForm from './EditForm/EditForm';

class TableSection extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            rowClicked: null
        }
    }

    componentDidMount() {
        const { setSecurityColumns, setSecurityFilterOptions } = this.props;
        apiService
            .getSecurityFilters()
            .then(data => setSecurityFilterOptions(data));
        setSecurityColumns(columnsDef);
    }

    refreshTableData(searchText, searchOption) {
        const { tableFilters, tableSort } = this.props;

        const groupBy = [`${tableSort.columnName} ${tableSort.sortOrder}`];

        batch(() => {
            this.props.setSecurityLoading(true);
            this.props.setSecurityData({ count: 0, facets: {}, results: [] });
        });
        apiService
            .getSecurityUsers(tableFilters, 0, searchOption.key, searchText, groupBy)
            .then(data => {
                batch(() => {
                    this.props.setSecurityData(data);
                    this.props.setSecurityLoading(false);
                })
            });
    }

    render() {
        const { rowClicked } = this.state;
        const {
            tableData,
            tableColumns,
            loading,
            setSecurityRowDetail,
            rowDetail,
            setSecurityShowForm,
            showForm
        } = this.props;

        return (
            <>
                <BasicTable
                    context={this}
                    getRowNodeId={rowData => rowData.id}
                    columns={tableColumns}
                    data={tableData}
                    onRowClicked={row => this.setState({ rowClicked: row })}
                    onRowDoubleClicked={data => setSecurityRowDetail(data)}
                    searchPlaceholder='User (at least 3 characters)...'
                    searchOptions={[
                        { text: 'Name', key: 0, data: { icon: 'ProfileSearch' }, selected: true },
                        { text: 'Email', key: 1, data: { icon: 'Mail' } }
                    ]}
                    minCharToSearch={3}
                    onSearch={(gridApi, textValue, opt) => {
                        this.refreshTableData(textValue, opt);
                    }}
                />
                <Modal
                    isOpen={loading}
                    isBlocking={false}
                    containerClassName={{
                        display: 'flex',
                        flexFlow: 'row',
                        alignItems: 'center'
                    }}
                >
                    <Spinner label="Loading, please wait..." style={{ padding: '5px' }} ariaLive="assertive" labelPosition="right" />
                </Modal>
                <Modal
                    isOpen={rowDetail}
                    onDismiss={() => setSecurityRowDetail(null)}
                    isBlocking={false}
                    containerClassName={{
                        display: 'flex',
                        flexFlow: 'column nowrap',
                        alignItems: 'stretch',
                        width: '80vw',
                        borderRadius: 0,
                        padding: '20px'
                    }}
                >
                    <SecurityRowDetail
                        close={(e) => setSecurityRowDetail(null)}
                    />
                </Modal>
                {
                    showForm && rowClicked &&
                    <EditForm
                        rowDetail={rowClicked}
                        onClose={() => { this.setState({ rowClicked: null }); setSecurityShowForm(false) }}
                    />
                }
            </>
        )
    }
}


const mapStateToProps = (state) => ({
    loading: state.security.loading,
    rowDetail: state.security.rowDetail,
    tableData: state.security.data,
    tablePagination: state.security.pagination,
    tableColumns: state.security.columns,
    tableFilters: state.security.filters,
    tableSearchText: state.security.searchText,
    tableSort: state.security.sort,
    user: state.session.user,
    showForm: state.security.showForm
})
const mapDispatchToProps = (dispatch) => ({
    setSecurityLoading: isLoading => dispatch(setSecurityLoading(isLoading)),
    setSecurityRowDetail: rowDetail => dispatch(setSecurityRowDetail(rowDetail)),
    setSecurityData: data => dispatch(setSecurityData(data)),
    setSecurityPagination: (size, skip) => dispatch(setSecurityPagination(size, skip)),
    setSecurityColumns: columns => dispatch(setSecurityColumns(columns)),
    setSecurityShowForm: show => dispatch(setSecurityShowForm(show)),
    setSecurityFilterOptions: options => dispatch(setSecurityFilterOptions(options))
})

export default connect(mapStateToProps, mapDispatchToProps)(TableSection)