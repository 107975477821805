import GridCandidatesCell from "../components/shared/GridCandidatesCell/GridCandidatesCell";
import { formatISODate } from "./date";

const MIN_WIDTH = 80;

export const build = {
    trm_status() {
        return {
            optional: { exportable: true },
            headerName: "",
            headerTooltip: "TRM Status",
            tooltipField: "trm_status",
            field: "trm_status",
            suppressSizeToFit: true,
            resizable: false,
            width: 5,
            minWidth: 5,
            maxWidth: 5,
            cellClass: params => {
                return `status-bg-${params.data.trm_status?.replace(' ', '-')}`;
            },
            cellStyle: { padding: '0px', width: '5px' },
            cellRenderer: params => null
        }
    },

    // _checkboxSelection() {
    //     return {
    //         width: 50,
    //         minWidth: 50,
    //         maxWidth: 50,
    //         suppressSizeToFit: true,
    //         suppressAutoSize: true,
    //         resizable: false,
    //         headerCheckboxSelection: true,
    //         headerCheckboxSelectionFilteredOnly: true,
    //         headerCheckboxSelectionCurrentPageOnly: true,
    //         checkboxSelection: true
    //     }
    // },

    allocation_board_priority(editable) {
        return {
            optional: { sortable: true, filterable: true, filterableType: 'numeric' },
            headerName: "P",
            headerTooltip: "Priority",
            field: "allocation_board_priority",
            cellClass: "text-center hide-sort-icon",
            resizable: false,
            minWidth: 120,
            maxWidth: 120,
            width: 120,
            suppressSizeToFit: true,
            cellRenderer: data => (data.value && data.value > 0 ? data.value : ''),
            editable: editable,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            headerCheckboxSelectionCurrentPageOnly: true,
            checkboxSelection: true
            // headerComponentParams: {
            //     template:
            //         '<div class="ag-cell-label-container" role="presentation">' +
            //         '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
            //         '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>' +
            //         '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>' +
            //         '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>' +
            //         '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>' +
            //         '    P' +
            //         '  </div>' +
            //         '</div>'
            // }
        }
    },

    department_display_name() {
        return {
            optional: { exportable: true, hideable: true, sortable: true },
            minWidth: MIN_WIDTH,
            headerName: "Department",
            headerTooltip: "Department",
            field: "department_display_name"
            // field: "owner_kf_financialhierarchyid"
        }
    },

    account_name() {
        return {
            optional: { exportable: true, hideable: true, filterable: true, sortable: true },
            minWidth: MIN_WIDTH,
            headerName: "Client",
            headerTooltip: "Client",
            field: "account_name",
            filter: true,
            valueGetter: params => params?.data?.account_name
        }
    },

    trm_jobtitle() {
        return {
            optional: { exportable: true, hideable: true, filterable: true, sortable: true },
            minWidth: MIN_WIDTH,
            headerName: "Job Title",
            headerTooltip: "Job Title",
            unSortIcon: true,
            field: "trm_jobtitle",
            filter: true,
            resizable: true,
            // headerCheckboxSelection: true,
            // headerCheckboxSelectionFilteredOnly: true,
            // checkboxSelection: true
        }
    },

    allocation_important_note(cellRendererFramework) {
        return {
            optional: { exportable: true, sortable: true },
            headerName: "",
            headerTooltip: "Important Note",
            field: "allocation_important_note",
            suppressSizeToFit: true,
            resizable: false,
            width: 1,
            cellRendererFramework: cellRendererFramework
        }
    },

    mt(cellRendererFramework) {
        return {
            optional: { exportable: true, hideable: true },
            headerName: "",
            headerTooltip: "Microteam",
            field: "mt",
            suppressSizeToFit: true,
            width: 60,
            minWidth: 60,
            maxWidth: 60,
            resizable: false,
            cellRendererFramework: cellRendererFramework
        }
    },

    kf_advancedservicesrequired() {
        return {
            optional: { exportable: true, hideable: true, filterable: true, sortable: true },
            cellClass: "text-center",
            width: 130,
            minWidth: MIN_WIDTH,
            headerName: "KCS",
            headerTooltip: "KCS",
            field: "kf_advancedservicesrequired",
            cellRendererFramework: params => params.value ? "Yes" : "No"
        }
    },

    opportunityname() {
        return {
            optional: { exportable: true, hideable: true, filterable: true, sortable: true },
            minWidth: MIN_WIDTH,
            headerName: "Opportunity",
            headerTooltip: "Opportunity",
            field: "opportunityname"
        }
    },

    stepname() {
        return {
            optional: { exportable: true, hideable: true, filterable: true, sortable: true },
            minWidth: MIN_WIDTH,
            headerName: "Phase",
            headerTooltip: "Phase",
            field: "stepname"
        }
    },

    kf_practicearea() {
        return {
            optional: { exportable: true, hideable: true, filterable: true, sortable: true },
            minWidth: MIN_WIDTH,
            headerName: "Practice Area",
            headerTooltip: "Primary Practice Area",
            field: "kf_practicearea"
        }
    },  

    kf_servicecapabilityprimary() {
        return {
            optional: { exportable: true, hideable: true, filterable: true, sortable: true },
            minWidth: MIN_WIDTH,
            headerName: "Service Capability",
            headerTooltip: "Service Capability",
            field: "kf_servicecapabilityprimary"
        }
    },

    kf_engagementmodel() {
        return {
            optional: { exportable: true, hideable: true, filterable: true, sortable: true },
            minWidth: MIN_WIDTH,
            headerName: "Delivery Model",
            headerTooltip: "Delivery Model",
            field: "kf_engagementmodel"
        }
    },

    pafo_foid() {
        return {
            optional: { exportable: true, hideable: true, filterable: true, sortable: true },
            minWidth: MIN_WIDTH,
            headerName: "JO Number",
            headerTooltip: "JO Number",
            field: "pafo_foid"
        }
    },

    trm_clientrequisitionnumber() {
        return {
            optional: { exportable: true, hideable: true, filterable: true, sortable: true },
            minWidth: MIN_WIDTH,
            headerName: "Client ID",
            headerTooltip: "Client ID",
            field: "trm_clientrequisitionnumber"
        }
    },

    createdon() {
        return {
            optional: { exportable: true, hideable: true, sortable: true },
            minWidth: MIN_WIDTH,
            headerName: "Create Date",
            headerTooltip: "Create Date",
            field: "createdon",
            filter: 'agDateColumnFilter',
            filterParams: {
                comparator: function (filterLocalDateAtMidnight, cellValue) {
                    var dateAsString = new Date(cellValue).setHours(0, 0, 0, 0);
                    if (dateAsString < filterLocalDateAtMidnight) { return -1; }
                    else if (dateAsString > filterLocalDateAtMidnight) { return 1; }
                    else { return 0; }
                }
            },
            valueGetter: params => formatISODate(params.data.createdon)
        }
    },

    pafo_estimatedstartdate() {
        return {
            optional: { exportable: true, hideable: true, sortable: true },
            minWidth: MIN_WIDTH,
            headerName: "Start Date",
            headerTooltip: "Start Date",
            field: "pafo_estimatedstartdate",
            filter: 'agDateColumnFilter',
            filterParams: {
                comparator: function (filterLocalDateAtMidnight, cellValue) {
                    var dateAsString = new Date(cellValue).setHours(0, 0, 0, 0);
                    if (dateAsString < filterLocalDateAtMidnight) { return -1; }
                    else if (dateAsString > filterLocalDateAtMidnight) { return 1; }
                    else { return 0; }
                }
            },
            cellRenderer: data => {
                if (data.value && (data.value !== '0001-01-01T00:00:00Z')) {
                    return (new Date(data.value)).toLocaleDateString()
                }
                return '';
            }
        }
    },

    pafo_quantity() {
        return {
            optional: { exportable: true, hideable: true, filterable: true, filterableType: 'numeric', sortable: true },
            minWidth: MIN_WIDTH,
            cellClass: "text-center",
            headerName: "Qty",
            headerTooltip: "Quantity",
            field: "pafo_quantity"
        }
    },

    trm_priority() {
        return {
            optional: { exportable: true, hideable: true },
            minWidth: MIN_WIDTH,
            cellClass: "text-center",
            headerName: "Priority",
            headerTooltip: "Priority",
            field: "trm_priority",
            filter: true
        }
    },

    trm_rank() {
        return {
            optional: {exportable: true, hideable: true, filterable: true, filterableType: 'numeric', sortable: true },
            minWidth: MIN_WIDTH,
            cellClass: "text-center",
            headerName: "Rank",
            headerTooltip: "Rank",
            field: "trm_rank",
            filter: true
        }
    },

    external_submittals() {
        return {
            optional: { exportable: true, hideable: true, sortable: true },
            minWidth: MIN_WIDTH,
            cellClass: "text-center",
            headerName: "Ext. Subs",
            headerTooltip: "Ext. Subs",
            field: "external_submittals"
        }
    },

    sendouts() {
        return {
            optional: { exportable: true, hideable: true, sortable: true },
            minWidth: MIN_WIDTH,
            cellClass: "text-center",
            headerName: "Send Outs",
            headerTooltip: "Send Outs",
            field: "sendouts"
        }
    },

    allocation_board_status(cellRendererFramework) {
        return {
            optional: { exportable: true, hideable: true, sortable: true },
            minWidth: MIN_WIDTH,
            headerName: "AB Status",
            headerTooltip: "Allocation Board Status",
            field: "allocation_board_status",
            cellClass: "h-100",
            filter: true,
            cellRendererFramework: cellRendererFramework
        }
    },

    trm_worksitecity() {
        return {
            optional: { exportable: true, hideable: true, filterable: true, sortable: true },
            minWidth: MIN_WIDTH,
            headerName: "City",
            headerTooltip: "City",
            field: "trm_worksitecity",
            filter: true
        }
    },

    trm_worksitestate() {
        return {
            optional: { exportable: true, hideable: true, sortable: true },
            minWidth: MIN_WIDTH,
            cellClass: "text-center",
            headerName: "State",
            headerTooltip: "State",
            field: "trm_worksitestate",
            filter: true
        }
    },

    trm_remotework() {
        return {
            optional: { exportable: true, hideable: true, sortable: true },
            minWidth: MIN_WIDTH,
            headerName: "Remote",
            headerTooltip: "Remote",
            field: "trm_remotework"
        }
    },

    trm_issjoborder() {
        return {
            optional: { exportable: true, hideable: true, filterable: true, sortable: true },
            width: 100,
            minWidth: MIN_WIDTH,
            cellClass: "text-center",
            headerName: "ISS",
            headerTooltip: "ISS",
            field: "trm_issjoborder"
        }
    },

    trm_vsdjoborder() {
        return {
            optional: { exportable: true, hideable: true, filterable: true, sortable: true },
            width: 110,
            minWidth: MIN_WIDTH,
            cellClass: "text-center",
            headerName: "VSD",
            headerTooltip: "VSD",
            field: "trm_vsdjoborder"
        }
    },

    trm_posttoweb() {
        return {
            optional: { exportable: true, hideable: true, filterable: true, sortable: true },
            width: 150,
            minWidth: MIN_WIDTH,
            cellClass: "text-center",
            headerName: "Post to Web",
            headerTooltip: "Post to Web",
            field: "trm_posttoweb"
        }
    },

    trm_post_to_ilabor() {
        return {
            optional: { exportable: true, hideable: true, filterable: true, sortable: true },
            width: 120,
            minWidth: MIN_WIDTH,
            cellClass: "text-center",
            headerName: "Vendor Network",
            headerTooltip: "Send to Vendor Network",
            field: "trm_post_to_ilabor"
        }
    },

    pafo_jobtype() {
        return {
            optional: { exportable: true, hideable: true, sortable: true },
            minWidth: MIN_WIDTH,
            headerName: "Job Type",
            headerTooltip: "Job Type",
            field: "pafo_jobtype"
        }
    },

    pafo_billrangehigh() {
        return {
            optional: { exportable: true, hideable: true, sortable: true },
            minWidth: MIN_WIDTH,
            headerClass: "justify-content-end",
            cellClass: "text-right",
            headerName: "Max Bill Rate",
            headerTooltip: "Max Bill Rate",
            field: "pafo_billrangehigh"
        }
    },

    trm_salaryrangeto() {
        return {
            optional: { exportable: true, hideable: true, sortable: true },
            minWidth: MIN_WIDTH,
            headerClass: "justify-content-end",
            cellClass: "text-right",
            headerName: "Max Salary",
            headerTooltip: "Max Salary",
            field: "trm_salaryrangeto"
        }
    },

    owner_fullname() {
        return {
            optional: { exportable: true, hideable: true, filterable: true, sortable: true },
            minWidth: MIN_WIDTH,
            headerName: "A.M.",
            headerTooltip: "Account Manager",
            field: "owner_fullname",
            filter: true,
            valueGetter: params => params?.data?.owner_fullname
        }
    },

    hiringmanager() {
        return {
            optional: { exportable: true, hideable: true, filterable: true, sortable: true },
            minWidth: MIN_WIDTH,
            headerName: "Hiring Manager",
            headerTooltip: "Hiring Manager",
            field: "hiringmanager"
        }
    },

    recruiters(cellRendererFramework) {
        return {
            optional: { exportable: true, hideable: true, filterable: true },
            minWidth: MIN_WIDTH,
            headerName: "Recruiters",
            headerTooltip: "Recruiters",
            field: "recruiters",
            cellRendererFramework: cellRendererFramework
        }
    },

    candidates() {
        return {
            optional: { exportable: true, hideable: true, filterable: true },
            minWidth: MIN_WIDTH,
            headerName: "Candidates",
            headerTooltip: "Candidates",
            field: "candidates",
            cellRendererFramework: params => {
                const value = params?.value;
                return <GridCandidatesCell candidates={value} />;
            }
        }
    },

    _notes(cellRendererFramework) {
        return {
            headerName: "",
            headerTooltip: "Notes",
            // field: "Notes",
            width: 45,
            minWidth: 45,
            maxWidth: 45,
            filter: false,
            resizable: false,
            pinned: 'right',
            cellStyle: { padding: 3 },
            cellRendererFramework: cellRendererFramework
        }
    },

    _actions(headerComponentFramework, cellRendererFramework) {
        return {
            headerName: "Actions",
            headerTooltip: "Actions",
            width: 40,
            minWidth: 40,
            maxWidth: 40,
            filter: false,
            resizable: false,
            pinned: 'right',
            cellStyle: { paddingLeft: 0 },
            headerComponentFramework: headerComponentFramework,
            cellRendererFramework: cellRendererFramework,
        }
    }
}