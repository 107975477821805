import React from 'react';
import { connect } from 'react-redux';

class TitleSection extends React.Component {
    render() {
        const { user } = this.props;
        return (
            <div className="d-flex align-items-center">
                <h5 className="m-0"><b>Security Administration</b></h5>
                <small className="mb-0 ml-2" style={{ color: '#aaa' }}> • {user.roleName}</small>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.session.user
})

export default connect(mapStateToProps)(TitleSection);