import * as React from 'react';

class NA extends React.Component {

    render() {
        const { message } = this.props;

        return <div className='small text-muted'>
            {message || "N/A"}
        </div>
    }
}

export default NA