import React from 'react';
import { connect } from 'react-redux';
import { ActionButton, TooltipHost } from "@fluentui/react";
import { setSecurityShowForm } from '../../../../../store/actions';

class EditCell extends React.Component {

    render() {
        const { setSecurityShowForm, showForm } = this.props;
        
        return (
            <TooltipHost content="Edit">
                <ActionButton
                    iconProps={{ iconName: 'Edit' }}
                    onClick={() => setSecurityShowForm(!showForm) }
                />
            </TooltipHost>
        )
    }
};

const mapStateToProps = state => ({
    showForm: state.security.showForm
})

const mapDispatchToProps = (dispatch, props) => ({
    setSecurityShowForm: (i) => dispatch(setSecurityShowForm(i))
})

export default connect(mapStateToProps, mapDispatchToProps)(EditCell);