import React from 'react';
import { Spinner } from '@fluentui/react';
import { connect } from 'react-redux';

// POSSIBLE PERMITS
// ------  ------ ------  ------ ------  ------
// DELETE - CREATE - UPDATE - VIEW
// For all permissions, put the permission in []
// For not permission, put the permission in ['']

export var permissionByPage = [
    {
        route: '/security',
        roles: [
            {
                name: 'Associate',
                permissions: null
            },
            {
                name: 'Office Leader',
                permissions: null
            }
        ]
    },
    {
        route: '/myDepartments',
        roles: [
            {
                name: 'Associate',
                permissions: ['']
            }
        ]
    },
    {
        route: '/consolidatedOrders',
        roles: [
            {
                name: 'Associate',
                permissions: ['']
            }
        ]
    }
];

/**
 * This function validates permissions from the rol.
 * The function use permissionByPage JSON.
 * This function can be use with the permissionControllerRenderer or not.
 * @param {*} permission Permission Name
 * @param {*} userRolname Rol Name
 * @returns BOOLEAN
 */
export function validatePermission(permission, userRolname) {
    const location = window.location.pathname;
    const pagePermissions = permissionByPage.filter(p => p.route === location);
    if (pagePermissions.length === 0) { return true; } // If the route its not defined on the json

    // Get permissions array from Role
    const permissionsByRole = pagePermissions[0].roles.filter(p => p.name === userRolname);

    if (permissionsByRole.length === 0) { return true; } // All permissions
    const havePermission = permissionsByRole[0].permissions.indexOf(permission) > -1; // Verificate permissions

    return havePermission; // boolean
}


class PermissionControllerRender extends React.Component {
    render() {
        const { children, user, skip } = this.props;

        const verificated = skip
            ? true
            : validatePermission(this.props.for, user.roleName);

        if (verificated === null) {
            return <Spinner label="Checking permissions" ariaLive="assertive" labelPosition="right" />
        }

        if (verificated === false) {
            return null;
        }

        return children; // All correct
    }
}

const mapStateToProps = (state) => ({
    user: state.session.user,
})

export default connect(mapStateToProps)(PermissionControllerRender)