import { TextFilterAction, Role, Field } from "../constants/constants";
import { advancedServicesFilterOption, createDepartmentFilterOption, exclusiveFilterOption, hotlistFilterOption, iLaborFilterOption, postToWebFilterOption } from "../constants/service-filters";


const consolidatedService = {

    createGroupBy: function (sort) {
        if (!sort || !sort.columnName || !sort.sortOrder) {
            return [];
        }
        return [`${sort.columnName} ${sort.sortOrder}`];
    },

    useBaseFilters: function (filters) {
        const useBaseFilters = filters && filters.length > 0
            ? !filters.some(x => x.Option === Field.department_display_name || x.columnName === Field.department_display_name)
            : true;
        return useBaseFilters;
    },

    getFiltersForFilters: function (baseFilters, tableFilters) {
        if (baseFilters && baseFilters.length > 0) {
            return baseFilters;
        }

        return tableFilters && tableFilters.length > 0
            ? tableFilters.filter(x => x.Option === Field.department_display_name)
            : [];
    },

    mapFiltersToAPI: function (filters) {
        return filters && filters.length > 0
            ? filters.map(x => ({
                Option: x.columnName,
                Action: x.action,
                Value: x.value,
                Group: x.group
            }))
            : [];
    },

    createBaseFilters: function (user, tableFilters, isDelegate) {
        let baseFilters = [];

        if (this.useBaseFilters(tableFilters)) {
            const isAssociateRole = user.roleName === Role.associate;

            const departments = !isAssociateRole
                ? user.officeLeaderDepartmentList
                : user.officeLeaderDepartmentList.filter(x => x.isDelegate === isDelegate);

            baseFilters = departments.map(x => ({
                Option: Field.owner_kf_financialhierarchyid,
                Action: TextFilterAction.Equals.key,
                Value: x.kf_financialhierarchyid
            }))
        }

        return baseFilters;
    },

    createFilterOptions: function (user, facetsData, isDelegate) {
        const filterOptions = !!facetsData?.length
            ? facetsData.filter(x => x.key !== Field.department_display_name)
            : [];

        const departments = user.roleName !== Role.associate
            ? user.officeLeaderDepartmentList
            : user.officeLeaderDepartmentList.filter(x => x.isDelegate === isDelegate);

        const departmentValues = departments.map(x => x.departmentName);

        return [
            ...filterOptions,
            ...createDepartmentFilterOption(departmentValues),
            exclusiveFilterOption,
            hotlistFilterOption,
            postToWebFilterOption,
            iLaborFilterOption,
            advancedServicesFilterOption
        ];
    },

    getGroupId(user, isDelegate) {
        if (user.roleName !== Role.associate) {
            return null;
        }

        return isDelegate ? 'delegate' : 'associate';
    }
}

export default consolidatedService;