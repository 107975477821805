import * as React from 'react';
import { DefaultButton, Dropdown, PrimaryButton, Panel, PanelType, SearchBox, Icon, ShimmerElementsGroup, Shimmer, ShimmerElementType } from '@fluentui/react';
import { FilterCategory, TextFilterAction } from '../../../constants/constants';
import FilterList from '../FilterList/FilterList';
import { addNotification } from '../../../store/actions';
import { connect } from 'react-redux'
import Section from '../Section/Section';

const MAX_OPTIONS = 500;
const USE_ADVANCED_SEARCH_AFTER = 10;

class GridFilters extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            options: [],
            optionsData: [],
            optionsKey: undefined,
            closeAfterApply: false
        }
    }

    onSelectItem(option, columnName, disableMultiSelect) {
        const { selectedFilterOptions, groupId } = this.props;

        let selectedColumnOptions = disableMultiSelect
            ? []
            : selectedFilterOptions.filter(x => x.columnName === columnName) || [];

        if (option.selected) {
            selectedColumnOptions.push({
                columnName: columnName,
                action: TextFilterAction.Equals.key,
                value: option.key,
                group: groupId
            });
        } else if (!disableMultiSelect) {
            selectedColumnOptions = selectedColumnOptions.filter(e => e.value !== option.key && e.group === groupId);
        }

        const updatedSelectedFilterOptions = [
            ...selectedColumnOptions,
            ...selectedFilterOptions.filter(x => x.columnName !== columnName)
        ];

        this.props.onUpdate(updatedSelectedFilterOptions);
    }

    onApplyFilter(closeAfterApply) {
        // const { closeAfterApply } = this.state;
        const { selectedFilterOptions, groupId } = this.props;

        if (selectedFilterOptions.length > MAX_OPTIONS) {
            const groupBy = selectedFilterOptions.filter(x => x.group === groupId).reduce((group, item) => {
                group[item.columnName] = group[item.columnName] ?? 0;
                group[item.columnName]++;
                return group;
            }, {});

            if (Object.values(groupBy).some(x => x > MAX_OPTIONS)) {
                this.props.addNotification({ type: 'error', text: 'The maximun number of options selected per filter is ' + MAX_OPTIONS });
                return;
            }
        }

        this.props.onApply();

        if (closeAfterApply) {
            this.props.onClose();
        }
    }

    refreshOptionsData(searchValue) {
        const { options, optionsKey } = this.state;
        const { selectedFilterOptions } = this.props;


        this.setState({ optionsData: [] });

        let data = options;
        if (searchValue && (searchValue !== '')) {
            const searchValueLowerCase = searchValue.toLowerCase();
            data = data.filter(x => x.key.toLowerCase().indexOf(searchValueLowerCase) !== -1);
        }
        data.forEach((x, i) => {
            x.selected = selectedFilterOptions.some(rd => rd.columnName === optionsKey && rd.value === x.key);
        });

        setTimeout(() => {
            this.setState({ optionsData: data });
        }, 10);
    }

    renderLoading() {
        const { filterOptions } = this.props;

        return (
            <>
                {
                    filterOptions.map(opt => {
                        return (
                            <Shimmer
                                key={opt.key}
                                customElementsGroup={<ShimmerElementsGroup
                                    shimmerElements={[{ type: ShimmerElementType.line, height: opt.values.length > 10 ? 70 : 30 }]}
                                />}
                                style={{ marginTop: '10px' }}
                            />
                        )
                    })
                }
            </>
        )
    }

    renderFilterOptions() {
        const { selectedFilterOptions, filterOptions } = this.props;

        const filterOptionsGrouped = filterOptions?.reduce((prev, current) => {
            let group = prev;
            const category = current.category = current.category || FilterCategory.General;
            group[category] = prev[category] || [];
            group[category].push(current);
            return group;
        }, {}) || {};

        return (
            <div className='mt-3'>
                {
                    Object.keys(filterOptionsGrouped).map(key => {
                        const options = filterOptionsGrouped[key]
                            .sort((a, b) => a.displayName.toLowerCase().localeCompare(b.displayName.toLowerCase()))
                            .map(opt => {
                                const useAdvancedSearch = opt.values.length > USE_ADVANCED_SEARCH_AFTER;

                                return (
                                    <div key={opt.key} className='row mb-2'>
                                        <div className='col-3'>
                                            <label>{opt.displayName}</label>
                                        </div>
                                        <div className='col-9'>
                                            <Dropdown
                                                onClick={event => {
                                                    if (useAdvancedSearch) {
                                                        event.preventDefault();
                                                        const options = opt.values.map(x => ({ key: x, selected: false }));
                                                        this.setState({
                                                            optionsKey: opt.key,
                                                            options: options
                                                        }, () => this.refreshOptionsData());
                                                    }
                                                }}
                                                onRenderTitle={props => props.map(x => <div className='badge badge-primary mr-1'>{x.text}</div>)}
                                                onRenderCaretDown={(props, defaultRender) => useAdvancedSearch ? <Icon iconName="Search" /> : defaultRender(props)}
                                                placeholder={opt.values.length === 0 ? "---" : ""}
                                                disabled={opt.values.length === 0}
                                                options={opt.values ? opt.values.map(val => ({ key: val, text: val })) : []}
                                                multiSelect
                                                onChange={(i, val) => this.onSelectItem(val, opt.key, opt.disableMultiSelect)}
                                                selectedKeys={selectedFilterOptions.filter(x => x.columnName === opt.key).map(x => x.value)}
                                            />
                                        </div>
                                    </div>
                                )
                            })

                        return (
                            <Section key={key} icon="PageListFilter" title={key}>
                                {options}
                            </Section>
                        )
                    })
                }
            </div>
        )
    }

    render() {
        const { optionsData, optionsKey, closeAfterApply } = this.state;
        const { loading, onClose, onOpen, isOpen, filterOptions } = this.props;

        const optionsDisplayName = optionsKey
            ? filterOptions.find(x => x.key === optionsKey)?.displayName || ""
            : "";

        return (
            <>
                <Panel
                    headerText="Filters"
                    type={PanelType.medium}
                    // type={PanelType.custom}
                    // customWidth="40%"
                    isOpen={isOpen}
                    onOpen={() => onOpen()}
                    onDismiss={() => onClose()}
                    isFooterAtBottom
                    isBlocking={false}
                    onRenderFooterContent={() =>
                        <div className='d-flex flex-column'>
                            {/* <div className='d-flex justify-content-end mb-2'>
                                <label className='m-0'>Close after apply</label>
                                <input className='ml-2'
                                    type="checkbox"
                                    disabled={loading}
                                    checked={closeAfterApply}
                                    onChange={() => this.setState({ closeAfterApply: !closeAfterApply })}
                                />
                            </div> */}
                            <div className="d-flex justify-content-end align-items-center">
                                <div className="d-flex justify-content-end" style={{ gap: 5 }}>
                                    <DefaultButton
                                        onClick={() => onClose()}
                                        iconProps={{ iconName: 'Cancel' }}
                                    >Close</DefaultButton>
                                    <DefaultButton
                                        onClick={() => this.props.onUpdate([])}
                                        iconProps={{ iconName: 'ClearFilter' }}
                                        disabled={loading}
                                    >Clear All</DefaultButton>
                                    <DefaultButton
                                        onClick={() => this.onApplyFilter()}
                                        disabled={loading}
                                        iconProps={{ iconName: 'Search' }}
                                    >Apply</DefaultButton>
                                    <PrimaryButton
                                        onClick={() => this.onApplyFilter(true)}
                                        disabled={loading}
                                        iconProps={{ iconName: 'Search' }}
                                    >Apply & Close</PrimaryButton>
                                </div>
                            </div>
                        </div>
                    }
                >
                    {
                        loading
                            ? this.renderLoading()
                            : this.renderFilterOptions()
                    }
                </Panel>

                <Panel
                    type={PanelType.smallFixedFar}
                    // type={PanelType.custom}
                    // customWidth="38%"
                    isOpen={!!optionsKey}
                    onDismiss={() => this.setState({ optionsKey: undefined })}
                    onRenderHeader={() =>
                        <div className='w-100 pl-3'>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <h6 style={{ marginLeft: 5 }}>Select {optionsDisplayName}</h6>
                                <div className="m-1">
                                    <SearchBox
                                        placeholder="Search..."
                                        className="mb-3"
                                        onSearch={newValue => this.refreshOptionsData(newValue)}
                                    />
                                </div>
                            </div>
                        </div>
                    }
                    isFooterAtBottom
                    onRenderFooterContent={() =>
                        <div className='d-flex justify-content-end'>
                            {/* <div style={{ display: 'flex', gap: 5 }}> */}
                            <DefaultButton
                                className="mr-2"
                                onClick={() => {
                                    const newFilters = this.props.selectedFilterOptions.filter(x => x.columnName !== optionsKey)
                                    this.props.onUpdate(newFilters);
                                    window.setTimeout(() => this.refreshOptionsData(), 1);
                                }}
                                // iconProps={{ iconName: 'ClearFilter' }}
                                disabled={loading}
                            >Clear All</DefaultButton>
                            <PrimaryButton
                                // className="col-6"
                                iconProps={{ iconName: 'Accept' }}
                                onClick={() => this.setState({ optionsKey: undefined })}
                            >Done</PrimaryButton>
                        </div>
                    }
                >
                    <FilterList
                        selectable={true}
                        onSelectItem={(i) => this.onSelectItem(i, optionsKey)}
                        data={optionsData || []}
                        maxHeight="100%"
                    />
                </Panel>
            </>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({
    addNotification: not => dispatch(addNotification(not))
})

export default connect(null, mapDispatchToProps)(GridFilters)