import React from 'react';
import { connect } from 'react-redux';
import { validatePermission } from '../../../../../utils/auth';
import GridImportantNoteCell from '../../../../shared/GridImportantNoteCell/GridImportantNoteCell';

class ImportantNoteCell extends React.Component {

    render() {
        const { rowDetail, user, isDelegate } = this.props;
        const hasPermission = isDelegate || validatePermission('EDIT_JO', user.roleName);
        
        return <GridImportantNoteCell
            rowDetail={rowDetail}
            importantNote={rowDetail.value}
            readOnly={!hasPermission}
        />
    }
};

const mapStateToProps = state => ({
    user: state.session.user,
    isDelegate: state.consolidated.isDelegate
})

export default connect(mapStateToProps)(ImportantNoteCell);