import React, { createRef } from 'react';
import { connect, batch } from 'react-redux';
import {
    setConsolidatedLoading,
    setConsolidatedSelectedRows,
    setConsolidatedRowDetail,
    setConsolidatedData,
    setConsolidatedPagination,
    setConsolidatedColumns,
    setConsolidatedShowNotes,
    setConsolidatedShowRecruiters,
    setConsolidatedFilterOptions,
    addNotification,
    setUser
} from '../../../../store/actions';
import { columnsDef } from './Columns';
import ColumnHeader from './ColumnHeader/ColumnHeader';
import { validatePermission } from '../../../../utils/auth';
import apiService from '../../../../services/api-service';
import { getCancelTokenSource } from '../../../../api';
import RecruitersDetailModal from '../../../shared/RecruitersDetailModal/RecruitersDetailModal';
import consolidatedService from '../../../../services/consolidated-service';
import { Page, Role } from '../../../../constants/constants';
import GridJobOrderDetailModal from '../../../shared/GridJobOrderDetailModal/GridJobOrderDetailModal';
import JobOrdersTable from '../../../shared/GeneralTable/JobOrdersTable';
import NotesModal from '../../../shared/NotesModal/NotesModal';

class TableSection extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            firstLoad: true,
            currentIsDelegate: null,
            fetchingJobOrders: true,
            showDetail: false
        }

        this.source = createRef();
    }

    componentWillUnmount() {
        this.source.current?.cancel?.();
    }

    componentDidUpdate() {
        const { firstLoad, currentIsDelegate } = this.state;
        const { tablePagination, user, isDelegate } = this.props;

        if (user.roleName !== Role.associate && firstLoad) {
            this.source.current?.cancel?.();
            this.source.current = getCancelTokenSource();
            this.setState({ firstLoad: false, fetchingJobOrders: true });
            this.loadFilterOptions();
            this.refreshTableData(tablePagination.size, 0);
            return;
        }

        if (user.roleName === Role.associate && currentIsDelegate !== isDelegate) {
            this.source.current?.cancel?.();
            this.source.current = getCancelTokenSource();
            this.setState({ currentIsDelegate: isDelegate, fetchingJobOrders: true });
            this.loadFilterOptions();
            this.refreshTableData(tablePagination.size, 0);
        }
    }

    refreshTableData(size, skip) {
        const { user, tableSearchText, tableFilters, isDelegate } = this.props;

        const filterGroup = consolidatedService.getGroupId(user, isDelegate);
        const userFilters = user.settings.consolidatedOrdersPage.filter.filters.filter(x => x.group === filterGroup);

        const filters = [
            ...consolidatedService.createBaseFilters(user, [...userFilters, ...tableFilters], isDelegate),
            ...consolidatedService.mapFiltersToAPI(userFilters)
        ];

        if (tableFilters && tableFilters.length > 0) {
            filters.push(...tableFilters);
        }

        const orderBy = consolidatedService.createGroupBy(user.settings.consolidatedOrdersPage.sort);
        this.props.setConsolidatedLoading(true);

        apiService.getJobOrders(filters, size, skip, tableSearchText, orderBy, this.source.current.token)
            .then(data => {
                batch(() => {
                    this.props.setConsolidatedData(data);
                    this.props.setConsolidatedPagination(size, skip);
                    this.props.setConsolidatedLoading(false);
                    this.props.setConsolidatedSelectedRows([]);
                })
            }).finally(() => this.setState({ fetchingJobOrders: false }));
    }

    loadFilterOptions() {
        const { user, tableFilters, isDelegate } = this.props;

        const filterGroup = consolidatedService.getGroupId(user, isDelegate);
        const userFilters = user.settings.consolidatedOrdersPage.filter.filters.filter(x => x.group === filterGroup);

        const mixedFilters = [...consolidatedService.mapFiltersToAPI(userFilters), ...tableFilters];
        const baseFilters = consolidatedService.createBaseFilters(user, mixedFilters, isDelegate);
        const filtersForFilters = consolidatedService.getFiltersForFilters(baseFilters, mixedFilters);

        apiService.getJobOrderFilters(filtersForFilters, false, this.source.current.token)
            .then(data => {
                const filterOptions = consolidatedService.createFilterOptions(user, data, isDelegate);
                this.props.setConsolidatedFilterOptions(filterOptions);
            })
    }

    render() {
        const { fetchingJobOrders, showDetail } = this.state;
        const {
            tableData,
            tablePagination,
            tableColumns,
            rowDetail,
            showNotes,
            showRecruiters,
            isDelegate,
            user
        } = this.props;

        return (
            <>
                <JobOrdersTable
                    context={this}
                    fetching={fetchingJobOrders}
                    page={Page.consolidatedOrders}
                    data={tableData}
                    columnHeaderComponent={ColumnHeader}
                    columnsDef={columnsDef}
                    columns={tableColumns}
                    pagination={tablePagination}
                    refresh={(size, skip) => this.refreshTableData(size, skip)}
                    setColumns={columns => this.props.setConsolidatedColumns(columns)}
                    setRowDetail={rowDetail => this.props.setConsolidatedRowDetail(rowDetail)}
                    setSelectedRows={selectedRows => this.props.setConsolidatedSelectedRows(selectedRows)}
                    onRowDoubleClicked={() => {
                        this.setState({ showDetail: true });
                        if (showNotes) {
                            this.props.setConsolidatedShowNotes(false);
                        }
                    }}
                />

                <GridJobOrderDetailModal
                    isOpen={showDetail}
                    onDismiss={() => {
                        this.setState({ showDetail: false });
                        this.props.setConsolidatedRowDetail(null);
                    }}
                    rowDetail={rowDetail}
                    readOnly={isDelegate ? false : !validatePermission('CHANGE_STATUS', user.roleName)}
                />

                <RecruitersDetailModal
                    rowDetail={rowDetail}
                    isOpen={showRecruiters && rowDetail}
                    onDismiss={() => {
                        batch(() => {
                            this.props.setConsolidatedShowRecruiters(false);
                            this.props.setConsolidatedRowDetail(null);
                        })
                    }}
                />

                <NotesModal
                    rowDetail={rowDetail}
                    isOpen={showNotes && rowDetail}
                    onDismiss={() => {
                        batch(() => {
                            this.props.setConsolidatedShowNotes(false);
                            this.props.setConsolidatedRowDetail(null);
                        })
                    }}
                />
            </>
        )
    }
}


const mapStateToProps = (state) => ({
    selectedRows: state.consolidated.selectedRows,
    rowDetail: state.consolidated.rowDetail,
    tableData: state.consolidated.data,
    tablePagination: state.consolidated.pagination,
    tableColumns: state.consolidated.columns,
    tableSearchText: state.consolidated.searchText,
    tableFilters: state.consolidated.filters,
    tableSort: state.consolidated.sort,
    showNotes: state.consolidated.showNotes,
    showRecruiters: state.consolidated.showRecruiters,
    isDelegate: state.consolidated.isDelegate,
    user: state.session.user
})
const mapDispatchToProps = (dispatch) => ({
    setConsolidatedLoading: isLoading => dispatch(setConsolidatedLoading(isLoading)),
    setConsolidatedSelectedRows: selectedRows => dispatch(setConsolidatedSelectedRows(selectedRows)),
    setConsolidatedRowDetail: rowDetail => dispatch(setConsolidatedRowDetail(rowDetail)),
    setConsolidatedData: data => dispatch(setConsolidatedData(data)),
    setConsolidatedPagination: (size, skip) => dispatch(setConsolidatedPagination(size, skip)),
    setConsolidatedColumns: columns => dispatch(setConsolidatedColumns(columns)),
    setConsolidatedShowNotes: data => dispatch(setConsolidatedShowNotes(data)),
    setConsolidatedShowRecruiters: data => dispatch(setConsolidatedShowRecruiters(data)),
    setConsolidatedFilterOptions: data => dispatch(setConsolidatedFilterOptions(data)),
    addNotification: not => dispatch(addNotification(not)),
    setUser: user => dispatch(setUser(user))
})

export default connect(mapStateToProps, mapDispatchToProps)(TableSection)