import { Field, FilterCategory } from "./constants";

export const createDepartmentFilterOption = (departmentValues) => {
    return !!departmentValues?.length ? [{
        displayName: 'Department',
        key: Field.department_display_name,
        values: departmentValues
    }] : [];
};

export const convertToTrueFalse = (value) => value === "Yes" ? "true" : "false";

export const isYes = (value) => value === "Yes";
export const isNo = (value) => value === "No";

export const exclusiveFilterOption = {
    category: FilterCategory.Highlight,
    displayName: 'Exclusive',
    key: Field.job_order_is_exclusive,
    values: ["Yes", "No"],
    disableMultiSelect: true
}

export const hotlistFilterOption = {
    category: FilterCategory.Highlight,
    displayName: 'Hotlist',
    key: Field.job_order_is_hotlist_filterable,
    values: ["Yes", "No"],
    disableMultiSelect: true
}

export const postToWebFilterOption = {
    displayName: 'Post to Web',
    key: Field.trm_posttoweb,
    values: ["Yes", "No"],
    disableMultiSelect: true
}

export const iLaborFilterOption = {
    displayName: 'Send to Vendor Network',
    key: Field.trm_post_to_ilabor,
    values: ["Yes", "No"],
    disableMultiSelect: true
}

export const advancedServicesFilterOption = {
    category: FilterCategory.AdvancedServices,
    displayName: 'KCS',
    key: Field.kf_advancedservicesrequired,
    values: ["Yes", "No"],
    disableMultiSelect: true
}