import React from 'react';
import { MessageBar, MessageBarType, MessageBarButton, ProgressIndicator } from '@fluentui/react';
import { connect } from 'react-redux'
import { removeNotification } from '../../../store/actions';

const copyToClipboard = str => {
    if (navigator && navigator.clipboard && navigator.clipboard.writeText)
        return navigator.clipboard.writeText(str);
    return Promise.reject('The Clipboard API is not available.');
};

let queueToCloseNotifications = [];

class GlobalNotifications extends React.Component {

    renderLoading = () => {
        if (!this.props.systemLoading) {
            return null;
        }

        return (
            <div style={{ top: 0, left: 0, zIndex: 999999999, position: 'fixed', width: '100%' }}
            >
                <ProgressIndicator
                    styles={{ itemProgress: { padding: 0 } }}
                    barHeight={5}
                />
            </div>
        )
    }

    renderNotification = () => {
        return (
            <div
                className="position-absolute col-8 mx-auto"
                style={{ top: 10, left: 0, right: 0, zIndex: 999999999 }}
            >
                <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                    {
                        this.props.notification.map((x, i) => {
                            const isError = x.type === 'error';
                            const text = isError ? this.errorController(x.text) : x.text;

                            if (!isError && !queueToCloseNotifications.includes(x.id)) {
                                window.setTimeout(() => {
                                    this.props.removeNotification(x.id);
                                    queueToCloseNotifications = queueToCloseNotifications.filter(x => x !== x.id);
                                }, 5000);
                                queueToCloseNotifications.push(x.id);
                            }

                            return (
                                <MessageBar
                                    key={i}
                                    styles={{ root: { boxShadow: '0px 2px 4px gray' } }}
                                    messageBarType={MessageBarType[x.type]}
                                    isMultiline={true}
                                    dismissButtonAriaLabel="Close"
                                    onDismiss={() => this.props.removeNotification(x.id)}
                                    actions={isError &&
                                        <div>
                                            <MessageBarButton onClick={() => copyToClipboard(text)}>Copy to clipboard</MessageBarButton>
                                        </div>
                                    }
                                >
                                    {text}
                                </MessageBar>
                            )
                        })
                    }
                </div>
            </div>
        )
    }

    errorController = (errorObj) => {
        if (errorObj?.response) {
            const status = errorObj.response?.status || '?';
            const text = errorObj.response?.statusText || '?';
            return `HTTP error - ${status} - ${text}`;
        }
        return errorObj;
    }

    render() {
        return (
            <>
                {this.renderLoading()}
                {this.renderNotification()}
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    notification: state.notification,
    systemLoading: state.system.loading
})
const mapDispatchToProps = (dispatch) => ({
    removeNotification: id => dispatch(removeNotification(id)),
})


export default connect(mapStateToProps, mapDispatchToProps)(GlobalNotifications)