import React from 'react';
import { connect } from 'react-redux';
import { setAllOrdersColumns } from '../../../../../store/actions';
import { Page } from '../../../../../constants/constants';
import GridActionHeader from '../../../../shared/GridActionHeader/GridActionHeader';

class ActionsHeader extends React.Component {

    render() {
        const { rowDetail, columnsDef } = this.props;

        return (
            <GridActionHeader
                page={Page.allOrders}
                setColumns={columns => this.props.setAllOrdersColumns(columns)}
                columnsToShow={this.props.columnsToShow}
                columnsDef={columnsDef}
                rowDetail={rowDetail}
            />
        );
    }
};

const mapStateToProps = state => ({
    columnsToShow: state.allorders.columns
})

const mapDispatchToProps = {
    setAllOrdersColumns
}

export default connect(mapStateToProps, mapDispatchToProps)(ActionsHeader);