import React from 'react';
import { connect } from 'react-redux';

class Information extends React.Component {
    
    render() {
        const { user } = this.props;

        const items = [
            ["Name", user.name],
            ["Email", user.uniqueName],
            ["Role", user.roleName],
        ];

        return (
            <div>
                {
                    items.map(x =>
                        <div key={x[0]} className='d-flex mb-1'>
                            <div className='col-1'>
                                <span className='small'>{x[0]}</span>
                            </div>
                            <div className='col-11' style={{ color: '#888' }}>
                                {x[1]}
                            </div>
                        </div>)
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.session.user,
})

export default connect(
    mapStateToProps
)(Information)