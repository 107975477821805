import * as React from 'react';

class MenuIcon extends React.Component {

    render() {
        return (
            <svg
                style={{ color: 'var(--primary)' }}
                viewBox="0 0 24 24"
                fill="currentColor"
                height="1em"
                width="1em"
            >
                <path d="M3 8h18a1 1 0 000-2H3a1 1 0 000 2zm18 8H3a1 1 0 000 2h18a1 1 0 000-2zm0-5H3a1 1 0 000 2h18a1 1 0 000-2z" />
            </svg>
        )
    }
}

export default MenuIcon