import React from 'react';
import { ActivityItem, HoverCard, HoverCardType } from '@fluentui/react';
import PeopleList from '../PeopleList/PeopleList';
import { getActivityPersonas } from '../CandidatesDetail/CandidatesDetail';

class GridCandidatesCell extends React.Component {

    renderCompactCard(activityPersonas) {
        return <PeopleList data={activityPersonas} maxHeight="100%" />
    }

    render() {
        const { candidates } = this.props;

        if (!candidates || !Array.isArray(candidates) || candidates.length === 0) {
            return null;
        }

        let activityPersonas = getActivityPersonas(candidates);

        if (activityPersonas.length === 0) {
            return null;
        }

        const limit = 3;
        const more = candidates.length > limit
            ? <div style={{ color: 'gray', marginLeft: '4px', fontSize: '12px' }}>{`+${candidates.length - limit}`}</div>
            : null;

        const activityPersonasBubbles = activityPersonas.length > limit
            ? activityPersonas.slice(0, limit)
            : activityPersonas;

        return (
            <HoverCard
                instantOpenOnClick={false}
                type={HoverCardType.plain}
                plainCardProps={{ onRenderPlainCard: () => this.renderCompactCard(activityPersonas) }}
            >
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <ActivityItem className="h-100" activityPersonas={activityPersonasBubbles} isCompact={true} />
                    {more}
                </div>
            </HoverCard>
        )
    }
};


export default GridCandidatesCell;
