import * as actionTypes from './actionTypes'

export const setMyDepartmentsLoading = payload => ({
    type: actionTypes.SET_MYDEPARTMENTS_LOADING,
    payload
})

export const setMyDepartmentsSelectedRows = payload => ({
    type: actionTypes.SET_MYDEPARTMENTS_SELECTEDROWS,
    payload
})

export const setMyDepartmentsRowDetail = payload => ({
    type: actionTypes.SET_MYDEPARTMENTS_ROWDETAIL,
    payload
})

export const setMyDepartmentsData = payload => ({
    type: actionTypes.SET_MYDEPARTMENTS_DATA,
    payload
})

export const setMyDepartmentsPagination = (size, skip) => ({
    type: actionTypes.SET_MYDEPARTMENTS_PAGINATION,
    payload: { size, skip }
})

export const setMyDepartmentsFilters = payload => ({
    type: actionTypes.SET_MYDEPARTMENTS_FILTERS,
    payload
})

export const setMyDepartmentsSort = (columnName, sortOrder) => ({
    type: actionTypes.SET_MYDEPARTMENTS_SORT,
    payload: { columnName, sortOrder }
})

export const setMyDepartmentsFilterOptions = payload => ({
    type: actionTypes.SET_MYDEPARTMENTS_FILTEROPTIONS,
    payload
})

export const setMyDepartmentsColumns = payload => ({
    type: actionTypes.SET_MYDEPARTMENTS_COLUMNS,
    payload
})

export const setMyDepartmentsSearchText = payload => ({
    type: actionTypes.SET_MYDEPARTMENTS_SEARCHTEXT,
    payload
})

export const setMyDepartmentsShowNotes = payload => ({
    type: actionTypes.SET_MYDEPARTMENTS_SHOWNOTES,
    payload
})

export const setMyDepartmentsShowRecruiters = payload => ({
    type: actionTypes.SET_MYDEPARTMENTS_SHOWRECRUITERS,
    payload
})