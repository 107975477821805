import React, { createRef } from 'react';
import { connect, batch } from 'react-redux';
import {
    setConsolidatedData,
    setConsolidatedPagination,
    setConsolidatedSearchText,
    setConsolidatedLoading,
    setConsolidatedFilterOptions,
    setConsolidatedFilters,
    setUser
} from '../../../../store/actions';
import GridFilters from '../../../shared/GridFilters/GridFilters';
import { IconButton } from '@fluentui/react';
import { SearchBox } from '@fluentui/react/lib/SearchBox';
import Status from '../../../shared/Status/Status';
import ExportCsvModal from '../../../shared/ExportCsvModal/ExportCsvModal';
import apiService from '../../../../services/api-service';
import { getCancelTokenSource } from '../../../../api';
import consolidatedService from '../../../../services/consolidated-service';
import { TextFilterAction, Page } from '../../../../constants/constants';
import GridDisplayFilters from '../../../shared/GridDisplayFilters/GridDisplayFilters';
import { columnsDef } from '../TableSection/Columns'

class OptionsSection extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showFilters: false,
            showExportCsv: false,
            selectedFilterOptions: []
        }
        this.source = createRef();
    }

    componentWillUnmount() {
        this.source.current?.cancel?.();
    }

    search(textValue) {
        const { pagination, setConsolidatedSearchText } = this.props;
        setConsolidatedSearchText(textValue);
        this.refreshTableData(pagination.size, 0, textValue);
    }

    getDefaultFilters() {
        const { user, isDelegate } = this.props;

        const groupId = consolidatedService.getGroupId(user, isDelegate);
        const filtersToMap = user.settings.consolidatedOrdersPage.filter.filters.filter(x => x.group === groupId);

        return consolidatedService.mapFiltersToAPI(filtersToMap);
    }

    getFilters() {
        const { user, tableFilters, isDelegate } = this.props;

        const filters = this.getDefaultFilters();

        const baseFilters = consolidatedService.createBaseFilters(
            user,
            [...user.settings.consolidatedOrdersPage.filter.filters],
            isDelegate
        );
        filters.push(...baseFilters);


        if (tableFilters && tableFilters.length > 0) {
            filters.push(...tableFilters);
        }

        return filters;
    }

    getOrderBy() {
        const { user } = this.props;
        return consolidatedService.createGroupBy(user.settings.consolidatedOrdersPage.sort);
    }

    refreshTableData(size, skip, textValue = '') {
        const filters = this.getFilters();
        const orderBy = this.getOrderBy();
        this.props.setConsolidatedLoading(true);

        this.source.current?.cancel?.();
        this.source.current = getCancelTokenSource();

        apiService
            .getJobOrders(filters, size, skip, textValue, orderBy, this.source.current.token)
            .then(data => {
                batch(() => {
                    this.props.setConsolidatedData(data);
                    this.props.setConsolidatedPagination(size, skip);
                    this.props.setConsolidatedLoading(false);
                })
            });
    }

    setTrmStatusFilter(status) {
        const { user, setUser, pagination, searchText, isDelegate } = this.props;

        const groupId = consolidatedService.getGroupId(user, isDelegate);

        let userFilters = user.settings.consolidatedOrdersPage.filter.filters.filter(x => x.group !== groupId);
        let groupFilters = user.settings.consolidatedOrdersPage.filter.filters.filter(x => x.columnName !== "trm_status" && x.group === groupId);
        if (status !== "All Status") {
            groupFilters.push({
                columnName: "trm_status",
                action: TextFilterAction.Equals.key,
                value: status,
                group: groupId
            });
        }

        user.settings.consolidatedOrdersPage.filter.filters = [...userFilters, ...groupFilters];
        setUser(user);

        apiService.setUserFilters(user.oid, Page.consolidatedOrders, user.settings.consolidatedOrdersPage.filter.filters);

        this.refreshTableData(pagination.size, 0, searchText);
    }

    getTrmStatusFilter() {
        const { user, isDelegate } = this.props;

        const filters = user.settings.consolidatedOrdersPage.filter.filters;
        const groupId = consolidatedService.getGroupId(user, isDelegate);

        if (filters && filters.length > 0) {
            const trm_statuses = filters.filter(x => x.columnName === "trm_status" && x.group === groupId);
            if (trm_statuses.length > 0) {
                return trm_statuses[0].value;
            }
        }
        return "All Status";
    }

    refresh(resetSkip) {
        const { pagination, searchText } = this.props;

        const skip = resetSkip ? 0 : pagination.skip;
        this.refreshTableData(pagination.size, skip, searchText, this.getDefaultFilters())
        this.refreshFilterOptions();
    }

    refreshFilterOptions() {
        const { user, isDelegate } = this.props;
        const filterGroup = consolidatedService.getGroupId(user, isDelegate);
        const userFilters = user.settings.consolidatedOrdersPage.filter.filters
            ? user.settings.consolidatedOrdersPage.filter.filters.filter(x => x.group === filterGroup)
            : [];
        this.setState({ selectedFilterOptions: userFilters });
    }

    applyFilter() {
        const { selectedFilterOptions } = this.state;
        const { user, pagination, searchText, tableFilters, isDelegate } = this.props;

        const mixedFilters = [...consolidatedService.mapFiltersToAPI(selectedFilterOptions), ...tableFilters];
        const baseFilters = consolidatedService.createBaseFilters(user, mixedFilters, isDelegate);
        const filters = [
            ...baseFilters,
            ...consolidatedService.mapFiltersToAPI(selectedFilterOptions)
        ];

        if (tableFilters && tableFilters.length > 0) {
            filters.push(...tableFilters);
        }

        const filterGroup = consolidatedService.getGroupId(user, isDelegate);
        const saveFilters = user.settings.consolidatedOrdersPage.filter.filters.filter(x => x.group !== filterGroup);
        saveFilters.push(...selectedFilterOptions);

        apiService.setUserFilters(user.oid, Page.consolidatedOrders, saveFilters)
            .then(data => {
                // IF FAIL... ROLLBACK ?
            });

        user.settings.consolidatedOrdersPage.filter.filters = saveFilters;
        const orderBy = consolidatedService.createGroupBy(user.settings.consolidatedOrdersPage.sort);

        this.props.setConsolidatedLoading(true);

        apiService.getJobOrders(filters, pagination.size, 0, searchText, orderBy)
            .then(data => {
                batch(() => {
                    this.props.setConsolidatedData(data);
                    this.props.setConsolidatedPagination(pagination.size, 0);
                    this.props.setConsolidatedLoading(false);
                    this.props.setUser(user);
                });
            })
            .catch(error => this.props.setUser(user));

        const filtersForFilters = consolidatedService.getFiltersForFilters(baseFilters, mixedFilters);

        apiService.getJobOrderFilters(filtersForFilters)
            .then(data => {
                const filterOptions = consolidatedService.createFilterOptions(user, data, isDelegate);
                this.props.setConsolidatedFilterOptions(filterOptions);
            });
    }

    render() {
        const { user, isDelegate, filterOptions, searchText, tableData, showNotes } = this.props;
        const { showFilters, showExportCsv } = this.state;

        const status = this.getTrmStatusFilter();
        const groupId = consolidatedService.getGroupId(user, isDelegate);

        return (
            <>
                <div className="mb-2">
                    {/* <div className="row col-12">
                        {
                            loading &&
                            <div className="p-0 d-flex justify-content-center ml-3">
                                <Spinner label="Loading data..." ariaLive="assertive" labelPosition="right" />
                            </div>
                        }
                    </div> */}
                    <div className="d-sm-block d-md-flex justify-content-between align-items-center order--filter mt-3">
                        <div className="d-flex col-6 p-0 align-items-center">
                            <SearchBox
                                placeholder="Search Orders"
                                className="col-5 mr-2"
                                onClear={() => this.search('')}
                                onSearch={nv => this.search(nv)}
                                onChange={(_, nv) => this.props.setConsolidatedSearchText(nv)}
                                value={searchText}
                            />
                            <Status
                                readOnly={false}
                                onChange={(status) => this.setTrmStatusFilter(status)}
                                status={status}
                                itemsType="TRM"
                            />
                            <IconButton
                                title="Filters"
                                className="ml-1"
                                disabled={showNotes}
                                onClick={() => this.setState({ showFilters: !showFilters })}
                                iconProps={{ iconName: 'Filter' }}
                            />
                            <IconButton
                                title="Refresh Data"
                                onClick={() => this.refresh()}
                                iconProps={{ iconName: 'Refresh' }}
                            />
                            <IconButton
                                title="Export to CSV"
                                disabled={!tableData?.results?.length || showNotes}
                                onClick={() => this.setState({ showExportCsv: true })}
                                iconProps={{ iconName: 'DownloadDocument' }}
                            />
                        </div>
                        {/* <div style={{ marginLeft: 'auto' }}>
                            <ActionButton
                                text="Export"
                                onClick={() => window.alert("/joborder/exportjoborderstocsv")}
                                iconProps={{ iconName: 'DownloadDocument' }}
                            />
                        </div> */}
                    </div>
                </div>

                <GridDisplayFilters
                    userFilters={this.props.userFilters}
                    tableFilters={this.props.tableFilters}
                    filterOptions={this.props.filterOptions}
                    columns={columnsDef}
                    page={Page.consolidatedOrders}
                    groupId={groupId}
                    onChange={filters => this.props.setConsolidatedFilters(filters)}
                    onRefresh={() => this.refresh(true)}
                />

                <GridFilters
                    loading={this.props.loading}
                    isOpen={showFilters}
                    filterOptions={filterOptions}
                    selectedFilterOptions={this.state.selectedFilterOptions}
                    onOpen={() => this.refreshFilterOptions()}
                    onClose={() => this.setState({ showFilters: false })}
                    onUpdate={filterOptions => this.setState({ selectedFilterOptions: filterOptions })}
                    onApply={() => this.applyFilter()}
                    groupId={groupId}
                />

                <ExportCsvModal
                    page={Page.consolidatedOrders}
                    isOpen={showExportCsv}
                    onDismiss={() => this.setState({ showExportCsv: false })}
                    filters={this.getFilters()}
                    text={searchText}
                    orderBy={this.getOrderBy()}
                    columnsDef={columnsDef}
                />
            </>
        )
    }
}

const mapStateToProps = state => ({
    loading: state.consolidated.loading,
    pagination: state.consolidated.pagination,
    searchText: state.consolidated.searchText,
    filterOptions: state.consolidated.filterOptions,
    tableData: state.consolidated.data,
    tableFilters: state.consolidated.filters,
    tableSort: state.consolidated.sort,
    isDelegate: state.consolidated.isDelegate,
    showNotes: state.consolidated.showNotes,
    user: state.session.user,
    userFilters: state.session.user.settings.consolidatedOrdersPage.filter.filters
});

const mapDispatchToProps = {
    setConsolidatedData,
    setConsolidatedPagination,
    setConsolidatedLoading,
    setConsolidatedSearchText,
    setConsolidatedFilterOptions,
    setConsolidatedFilters,
    setUser,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OptionsSection)