import * as actionTypes from '../actions/actionTypes';

const defaultState = {
    loading: false
}

const newState = (state, newData) => {
    return Object.assign({}, state, newData)
}

const system = (state = defaultState, action) => {
    switch (action.type) {
        case actionTypes.SET_SYSTEM_LOADING:
            return newState(state, {
                loading: action.payload
            })
        default:
            return state
    }
}
export default system;