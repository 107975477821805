import './SecurityRowDetail.css';
import React from 'react';
import { connect } from 'react-redux';
import { IconButton } from '@fluentui/react';
import PeopleList from "../../../../shared/PeopleList/PeopleList";
class SecurityRowDetail extends React.Component {

    render() {
        const { rowDetail } = this.props;
        const selectedUser = rowDetail?.data;

        if (!selectedUser) {
            return null;
        }


        return (
            <div className="pb-2">
                <div className="d-flex align-items-center justify-content-between border-bottom pb-3 px-4">
                    <div className="d-flex align-items-end">
                        <h5 className="mb-0 mr-3">{selectedUser.fullname}</h5>
                        {
                            selectedUser.id &&
                            <span className="mx-3" style={{ color: '#ccc' }}>ID: #{selectedUser.id}</span>
                        }
                        {
                            selectedUser.rolename &&
                            <span>
                                <svg className="text-primary" viewBox="0 0 1024 1024" fill="currentColor" height="1em" width="1em" > <path d="M858.5 763.6a374 374 0 00-80.6-119.5 375.63 375.63 0 00-119.5-80.6c-.4-.2-.8-.3-1.2-.5C719.5 518 760 444.7 760 362c0-137-111-248-248-248S264 225 264 362c0 82.7 40.5 156 102.8 201.1-.4.2-.8.3-1.2.5-44.8 18.9-85 46-119.5 80.6a375.63 375.63 0 00-80.6 119.5A371.7 371.7 0 00136 901.8a8 8 0 008 8.2h60c4.4 0 7.9-3.5 8-7.8 2-77.2 33-149.5 87.8-204.3 56.7-56.7 132-87.9 212.2-87.9s155.5 31.2 212.2 87.9C779 752.7 810 825 812 902.2c.1 4.4 3.6 7.8 8 7.8h60a8 8 0 008-8.2c-1-47.8-10.9-94.3-29.5-138.2zM512 534c-45.9 0-89.1-17.9-121.6-50.4S340 407.9 340 362c0-45.9 17.9-89.1 50.4-121.6S466.1 190 512 190s89.1 17.9 121.6 50.4S684 316.1 684 362c0 45.9-17.9 89.1-50.4 121.6S557.9 534 512 534z" /> </svg>
                                {selectedUser.rolename}
                            </span>

                        }
                    </div>
                    <IconButton
                        iconProps={{ iconName: 'Cancel' }}
                        ariaLabel="Close popup modal"
                        onClick={() => this.props.close()}
                    />
                </div>
                <div className="row col-12 mt-4 p-0 px-4">
                    <div className="col-9 scrollbar" style={{ maxHeight: '70vh', overflowY: 'auto', overflowX: 'hidden' }}>
                        <section>
                            <div className="force-overflow"></div>
                            <h6 style={{ color: '#666' }} className="mb-3"><b>Details</b></h6>
                            <div className="d-flex">
                                <div className="col-6 p-0">
                                    <p style={{ color: '#666' }}>First Name: <b>{selectedUser.firstname}</b></p>
                                    <p style={{ color: '#666' }}>Last Name: <b>{selectedUser.lastname}</b></p>

                                </div>
                                <div className="col-6 p-0">
                                    <p style={{ color: '#666' }}>Email: <b>{selectedUser.email}</b></p>
                                    <p style={{ color: '#666' }}>Role: <b>{selectedUser.rolename}</b></p>
                                </div>
                            </div>
                        </section>
                    </div>
                    <div className="col-3 pr-0 scrollbar" style={{ maxHeight: '70vh', overflowY: 'auto' }}>
                        <div className="d-flex justify-content-between column" style={{ flexDirection: 'column' }}>
                            <p style={{ color: '#aaa' }} className="mb-1">Departments</p>
                            <div>
                                {
                                    selectedUser.departments && selectedUser.departments.length > 0 ?
                                        <PeopleList
                                            data={selectedUser.departments.map((x, i) => ({
                                                id: `${i}-${x.kf_financialhierarchyid}`,
                                                description: x.departmentName,
                                                name: x.departmentName && x.departmentName.length > 1 ? x.departmentName.slice(0, 2).toUpperCase() : x.departmentName,
                                                thumbnail: ''
                                            }))}
                                        />
                                :
                                <small>(none)</small>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
};

const mapStateToProps = (state) => ({
    rowDetail: state.security.rowDetail
})
export default connect(mapStateToProps)(SecurityRowDetail)