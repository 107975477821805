import * as React from 'react';
import { ActionButton, Panel, PanelType, Spinner, TooltipHost } from '@fluentui/react';
import Status from '../Status/Status';
import apiService from '../../../services/api-service';
import config from '../../../config';
import GridJobOrderDetail from '../GridJobOrderDetail/GridJobOrderDetail';

class GridJobOrderDetailModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            statusLoading: false
        }
    }

    setABStatus(newStatus) {
        this.setState({ statusLoading: true });
        const { rowDetail } = this.props

        apiService
            .updateJobOrderABStatus(rowDetail.data.pafo_joborderid, newStatus)
            .then(data => {
                if (data !== true) {
                    return;
                }

                rowDetail.node.data.allocation_board_status = newStatus;
                rowDetail.node.setData(rowDetail.node.data);

                const filtersForFilters = this.props.getFiltersForFilters();

                apiService.getJobOrderFilters(filtersForFilters)
                    .then(data => {
                        this.props.setFilterOptions(data);
                    });
            })
            .catch(error => console.error(error))
            .finally(() => this.setState({ statusLoading: false }));
    }

    render() {
        const { statusLoading } = this.state;
        const { isOpen, onDismiss, rowDetail, department, readOnly } = this.props;

        const jobOrder = rowDetail?.data;

        return (
            <Panel
                type={PanelType.custom}
                customWidth="100%"
                isBlocking={false}
                isOpen={isOpen}
                onDismiss={onDismiss}
                onRenderHeader={(props) => {
                    return (
                        <div className="d-flex align-items-baseline" style={{ marginRight: 'auto', paddingLeft: '16px', gap: 15 }}>
                            <TooltipHost content="Go to TRM Job Order">
                                <ActionButton
                                    style={{ padding: 0 }}
                                    iconProps={{ iconName: 'InfoSolid' }}
                                    onClick={() => window.open(`${config.REACT_APP_CRM_DYNAMICS_URL}&forceUCI=1&pagetype=entityrecord&etn=pafo_joborder&id=${jobOrder.pafo_joborderid}`, '_blank')}
                                >
                                    <h4 className="mb-0">{jobOrder?.trm_jobtitle}</h4>
                                </ActionButton>
                            </TooltipHost>
                            <div>
                                {
                                    !statusLoading ?
                                        <Status
                                            hideAllStatusOption
                                            status={jobOrder?.allocation_board_status}
                                            readOnly={readOnly}
                                            onChange={status => this.setABStatus(status)}
                                            itemsType="ALLOCATION_BOARD"
                                        />
                                        : <Spinner label="Updating Status..." ariaLive="assertive" labelPosition="right" />
                                }
                            </div>
                        </div>
                    )
                }}
            >
                <GridJobOrderDetail
                    rowDetail={rowDetail}
                    department={department}
                    readOnly={readOnly}
                />
            </Panel>
        )
    }
}

export default GridJobOrderDetailModal