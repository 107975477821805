import * as React from 'react';
import { PrimaryButton, DefaultButton, Dialog, DialogFooter, DialogType, Checkbox, ProgressIndicator } from '@fluentui/react';
import apiService from '../../../services/api-service';
import { connect } from 'react-redux'
import { addNotification } from '../../../store/actions';

class ExportCsvModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            exportAllColumns: false
        };
    }

    capitalize(value) {
        return value.charAt(0).toUpperCase() + value.slice(1);
    }

    getColumnsForExport() {
        const { columnsDef, user, page } = this.props;

        const userColumns = user.settings[page + "Page"].columns;
        const columnsForExport = [];

        for (const columnDef of columnsDef) {
            if (!columnDef.optional?.exportable)
                continue;

            if (columnDef.optional.hideable) {
                const values = [columnDef.field, columnDef.headerName, columnDef.headerTooltip];
                if (!userColumns.some(x => values.includes(x.columnName)))
                    continue;
            }

            columnsForExport.push(columnDef.field);
        }

        return columnsForExport;
    }

    downloadCSV() {
        const { exportAllColumns } = this.state;
        const { filters, text, orderBy, page, onDismiss, addNotification } = this.props;

        const columnsForExport = exportAllColumns ? null : this.getColumnsForExport();

        this.setState({ loading: true });

        apiService
            .exportJobOrdersToCsv(filters, text, orderBy, columnsForExport)
            .then(data => {
                const filename = this.getFilename(page);

                const fileURL = window.URL.createObjectURL(data);
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = filename;
                alink.click();

                addNotification({ type: 'success', text: 'Report generated: ' + filename });
                onDismiss();
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    getFilename(page) {
        const now = new Date();

        const currentDate = `${now.getFullYear()}${now.getMonth() + 1}${now.getDate()}`;
        const currentTime = `${now.getHours()}${now.getMinutes()}${now.getSeconds()}`;

        const areaName = page
            ? `${this.capitalize(page)}_`
            : '';

        return `AllocationBoard_${areaName}${currentDate}_${currentTime}.csv`;
    }

    render() {
        const { onDismiss, isOpen } = this.props;
        const { loading, exportAllColumns } = this.state;

        return (
            <Dialog
                hidden={!isOpen}
                onDismiss={onDismiss}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: 'Export Current View'
                }}
                modalProps={{
                    isBlocking: true,
                    // styles: { main: { maxWidth: 450 } }
                }}
            >
                <div>
                    {
                        loading
                            ? <ProgressIndicator label='Generating, please wait...' />
                            : <Checkbox
                                label="Export all columns"
                                checked={exportAllColumns}
                                disabled={loading}
                                onChange={(event, checked) => this.setState({ exportAllColumns: checked })}
                            />
                    }
                </div>
                <DialogFooter>
                    <DefaultButton
                        disabled={loading}
                        onClick={onDismiss}
                        text="Close"
                        iconProps={{ iconName: 'Cancel' }}
                    />
                    <PrimaryButton
                        disabled={loading}
                        onClick={() => this.downloadCSV()}
                        text="Generate CSV"
                        iconProps={{ iconName: 'Generate' }}
                    />
                </DialogFooter>
            </Dialog >
        )
    }
}

const mapStateToProps = state => ({
    user: state.session.user
});

const mapDispatchToProps = (dispatch) => ({
    addNotification: not => dispatch(addNotification(not))
})

export default connect(mapStateToProps, mapDispatchToProps)(ExportCsvModal)