import * as React from 'react';

class CheckboxWithState extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            checked: props.checked === true
        };
    }

    render() {
        const { onChange, disabled, useInternalState } = this.props;
        const checked = useInternalState === true ? this.state.checked : this.props.checked;

        return <input
            type="checkbox"
            checked={checked}
            disabled={disabled}
            onChange={() => {
                onChange(!checked);
                this.setState({ checked: !checked });
            }}
        />
    }
}

export default CheckboxWithState