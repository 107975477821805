import './Section.css';
import React from 'react';
import { Icon, IconButton } from '@fluentui/react';

class Section extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isHidden: false
        }
    }

    toggleHidden() {
        this.setState(state => ({
            isHidden: !state.isHidden
        }));
    }

    render() {
        const { isHidden } = this.state;

        return (
            <section className='mb-3'>
                <div className='w-100 mb-2' style={{ borderBottom: '#ccc solid 1px' }}>
                    <div className='d-flex justify-content-between'>
                        <div style={{ color: '#666', fontWeight: 500, fontSize: 16, display: 'flex', gap: 8 }}>
                            {
                                this.props.icon &&
                                <Icon iconName={this.props.icon} style={{ color: 'salmon' }} />
                            }
                            {this.props.title}
                        </div>
                        <IconButton
                            iconProps={{ iconName: isHidden ? 'ChevronDown' : 'ChevronUp' }}
                            onClick={() => this.toggleHidden()}
                        />
                    </div>
                </div>
                <div className={`section ${isHidden && 'hide'}`}>
                    {this.props.children}
                </div>
            </section>
        )
    }
}

export default Section;
