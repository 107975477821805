import * as actionTypes from '../actions/actionTypes';

const defaultState = {
    loading: false,
    data: [],
    pagination: { size: 25, skip: null },
    filters: [],
    filterOptions: [],
    columns: [],
    sort: { columnName: 'fullname', sortOrder: 'asc' },
    searchText: '',
    showForm: false
}

const newState = (state, newData) => {
    return Object.assign({}, state, newData)
}

const security = (state = defaultState, action) => {
    switch (action.type) {
        case actionTypes.SET_SECURITY_LOADING:
            return newState(state, {
                loading: action.payload
            })
        case actionTypes.SET_SECURITY_ROWDETAIL:
            return newState(state, {
                rowDetail: action.payload
            })
        case actionTypes.SET_SECURITY_DATA:
            return newState(state, {
                data: action.payload
            })
        case actionTypes.SET_SECURITY_PAGINATION:
            return newState(state, {
                pagination: action.payload
            })
        case actionTypes.SET_SECURITY_FILTERS:
            return newState(state, {
                filters: action.payload
            })
        case actionTypes.SET_SECURITY_FILTEROPTIONS:
            return newState(state, {
                filterOptions: action.payload
            })
        case actionTypes.SET_SECURITY_COLUMNS:
            return newState(state, {
                columns: action.payload
            })
        case actionTypes.SET_SECURITY_SORT:
            return newState(state, {
                sort: action.payload
            })
        case actionTypes.SET_SECURITY_SEARCHTEXT:
            return newState(state, {
                searchText: action.payload
            })
        case actionTypes.SET_SECURITY_SHOWFORM:
            return newState(state, {
                showForm: action.payload
            })
        default:
            return state
    }
}
export default security;