import React from 'react';
import { connect } from 'react-redux'
import { addNotification } from '../../../store/actions';
import {
    DefaultButton,
    PrimaryButton,
    Spinner,
    TextField,
    SpinnerSize,
    ActionButton,
    Icon
} from '@fluentui/react';
import apiService from '../../../services/api-service';
import { formatISODate } from '../../../utils/date';
import NoData from '../NoData/NoData';

class Notes extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showAddNote: props.compact,
            notesSort: true,
            noteBody: '',
            noteSubject: '',
            notes: null,
            noteCreateSuccess: null,
            lastClientSideId: 0,
            joId: null
        }
    }

    componentDidMount() {
        const { jobOrder } = this.props;
        this.setState({ joId: jobOrder.pafo_joborderid });
        this.getNotes();
    }

    componentDidUpdate() {
        const { jobOrder } = this.props;
        const { joId } = this.state;

        if (jobOrder.pafo_joborderid !== joId) {
            this.setState({
                joId: jobOrder.pafo_joborderid,
                noteBody: '',
                noteSubject: '',
                notes: null,
                noteCreateSuccess: null
            });
            this.getNotes();
        }
    }

    getNotes() {
        const { user, jobOrder } = this.props;

        this.setState({ notes: null })

        apiService.getAnnotation(user.systemUserId, jobOrder.pafo_joborderid)
            .then(data => {
                let notes = data.annotations || [];
                this.setState({ notes: notes })
            })
            .catch(error => {
                console.error(error)
                this.props.addNotification({ type: 'error', text: `Error getting notes. Job Order ID: ${jobOrder.pafo_joborderid}. System User ID: ${user.systemUserId}` });
                this.setState({ notes: [] })
            });
    }

    clearNote() {
        this.setState({ noteBody: '', noteSubject: '' });
    }

    saveNote() {
        const { user, jobOrder } = this.props;
        const { noteBody, noteSubject, lastClientSideId } = this.state;
        let { notes } = this.state;

        if (notes === null) notes = [];

        const clientSideId = lastClientSideId + 1;
        notes.unshift({
            annotationId: `CLIENTSIDE-${clientSideId}`,
            createdOn: new Date().toISOString(),
            subject: noteSubject,
            noteText: noteBody
        });

        this.setState({
            savingNote: true,
            noteCreateSuccess: null,
            notes,
            lastClientSideId: clientSideId
        });

        let messageType = 'error';
        let messageText = `Error saving note. Job Order ID: ${jobOrder.pafo_joborderid}. System User ID: ${user.systemUserId}`;

        apiService.saveAnnotation(user.systemUserId, jobOrder.pafo_joborderid, noteSubject, noteBody)
            .then(data => {
                messageType = 'success';
                const noteTitle = noteSubject ? `'${noteSubject}'` : "";
                messageText = `Note ${noteTitle} saved successfully`;
                this.setState({
                    noteBody: '',
                    noteSubject: '',
                    noteCreateSuccess: data?.isSuccessful ? true : false,
                    savingNote: false
                });
            })
            .catch(error => {
                console.error(error);
                this.setState({ noteCreateSuccess: false, savingNote: false });
            })
            .finally(() => {
                this.props.addNotification({ type: messageType, text: messageText });
            })
    }

    renderNote(note) {
        return (
            <div key={note.annotationId}>
                <div>
                    <div className='d-flex'>
                        <Icon iconName='Event' />
                        <p className="m-0 ml-2" style={{ color: '#777', fontSize: '12px' }}>
                            {formatISODate(note.createdOn)}
                        </p>
                    </div>
                    <p className="m-0" style={{ color: '#553344', fontWeight: '700' }}>
                        {note.subject}
                    </p>
                </div>
                <div className="m-0" style={{ overflowX: 'auto' }}>
                    <p>{note.noteText}</p>
                </div>
            </div>
        )
    }

    render() {
        const { compact, readOnly } = this.props;
        const { noteBody, noteSubject, notes, savingNote, showAddNote } = this.state;
        const allowOperate = noteBody.trim() || noteSubject.trim();

        return (
            <div className='d-flex flex-column h-100' style={{ overflow: 'hidden' }}>

                <div className="d-flex justify-content-between align-items-center">
                    <h6>Notes</h6>
                    {
                        !readOnly &&
                        <div>
                            <ActionButton
                                text={compact ? '' : 'Add Note'}
                                onClick={() => this.setState(state => ({ showAddNote: !state.showAddNote }))}
                                iconProps={{ iconName: 'AddTo' }}
                            />
                            <ActionButton
                                text={compact ? '' : 'Refresh'}
                                iconProps={{ iconName: 'Refresh' }}
                                onClick={() => this.getNotes()}
                                disabled={!notes}
                            />
                        </div>
                    }
                </div>

                {
                    showAddNote && (
                        <>
                            <div className="mb-2">
                                <TextField
                                    rows={1}
                                    value={noteSubject}
                                    placeholder="Subject"
                                    disabled={savingNote}
                                    onChange={(noteSubject) => this.setState({ noteSubject: noteSubject.target.value })}
                                    maxLength={128}
                                />

                                <TextField
                                    className="mt-1"
                                    rows={1}
                                    value={noteBody}
                                    placeholder="Note"
                                    disabled={savingNote}
                                    onChange={(noteBody) => this.setState({ noteBody: noteBody.target.value })}
                                    multiline
                                    autoAdjustHeight
                                    resizable={false}
                                    maxLength={512}
                                />
                            </div>

                            <div className="d-flex justify-content-end mb-3" style={{ gap: 5 }}>
                                {
                                    savingNote
                                        ? <Spinner
                                            size={SpinnerSize.small}
                                            label="Saving note..."
                                            ariaLive="assertive"
                                            labelPosition="right"
                                        />
                                        : <>
                                            <DefaultButton
                                                text="Cancel"
                                                disabled={savingNote}
                                                iconProps={{ iconName: 'Cancel' }}
                                                onClick={() => this.setState({ showAddNote: false })}
                                            />
                                            <DefaultButton
                                                text="Clear"
                                                disabled={savingNote || !allowOperate}
                                                onClick={() => this.clearNote()} 
                                            />
                                            <PrimaryButton
                                                text="Save"
                                                disabled={savingNote || !allowOperate}
                                                iconProps={{ iconName: 'Save' }}
                                                onClick={() => this.saveNote()}
                                            />
                                        </>
                                }
                            </div>
                        </>
                    )
                }

                {
                    !notes && <Spinner label="Loading notes..." ariaLive="assertive" labelPosition="right" />
                }

                {
                    notes && notes.length > 0 &&
                    <div style={{ width: '100%', height: '100%', overflow: 'auto' }}>
                        <div className="d-flex flex-column" style={{ gap: 3 }}>
                            {
                                notes.map(note => this.renderNote(note))
                            }
                        </div>
                    </div>
                }

                {
                    notes && notes.length === 0 && <NoData />
                }
            </div>
        )
    }
};

const mapStateToProps = (state) => ({
    user: state.session.user
});


const mapDispatchToProps = (dispatch) => ({
    addNotification: not => dispatch(addNotification(not))
})

export default connect(mapStateToProps, mapDispatchToProps)(Notes)