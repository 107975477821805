import { Role } from "../../constants/constants";
import AllOrdersIcon from "../icons/AllOrdersIcon";
import ConsolidatedOrdersIcon from "../icons/ConsolidatedOrdersIcon";
import MyDepartmentsIcon from "../icons/MyDepartmentsIcon";
import SecurityIcon from "../icons/SecurityIcon";
import UserPreferencesIcon from "../icons/UserPreferencesIcon";

export const menuList = [
    {
        path: "/consolidatedOrders",
        title: "Consolidated Orders",
        permissions: [Role.officeLeader, Role.associate],
        consolidatedSubmenu: [Role.associate],
        icon: () => <ConsolidatedOrdersIcon />
    },
    {
        path: "/myDepartments",
        title: "My Departments",
        permissions: [Role.officeLeader, Role.associate],
        myDepartmentsSubmenu: [],
        icon: () => <MyDepartmentsIcon />
    },
    {
        path: "/allOrders",
        title: "All Orders",
        permissions: [Role.officeLeader, Role.seniorLeader, Role.associate],
        icon: () => <AllOrdersIcon />
    },
    {
        path: "/userPreferences",
        title: "User Preferences",
        permissions: [Role.officeLeader, Role.associate],
        icon: () => <UserPreferencesIcon />
    },
    {
        path: "/security",
        title: "Security Administration",
        permissions: [Role.securityAdmin],
        icon: () => <SecurityIcon />
    }
]

export const consolidatedSubmenuItems = [
    { key: "delegate", text: "Delegate Departments", isDelegate: true, iconProps: { iconName: 'BusinessCenterLogo', style: { color: 'salmon' } } },
    { key: "associate", text: "Associate Departments", isDelegate: false }
]