import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { createRoot } from 'react-dom/client';
import App from './components/App';
// import { themes } from '@fluentui/react-northstar';

import store from './store/store';
import { Provider } from 'react-redux'

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <Provider store={store}>
    {/* <Provider theme={themes.teams} store={store}> */}
        <App />
    </Provider>
);