import API from '../api';
import { TextFilterAction, NumericFilterAction, Field, FilterCategory } from '../constants/constants';
import { convertToTrueFalse, isNo } from '../constants/service-filters';
import { clone } from '../utils/clone';

function createFiltersForAPI(filters) {
    const filtersForAPI = [];
    filters.forEach(f => {
        const clonedFilters = [];

        const cloneFilter1 = clone(f);

        switch (cloneFilter1.Option) {
            case Field.pafo_quantity:
            case Field.trm_rank:
                cloneFilter1.Option += "_int";
                clonedFilters.push(cloneFilter1);
                break;

            case Field.job_order_is_exclusive:
                cloneFilter1.Option = Field.job_order_is_exclusive_filterable;
                cloneFilter1.Action = NumericFilterAction.Equals.key;
                cloneFilter1.Value = convertToTrueFalse(f.Value);
                clonedFilters.push(cloneFilter1);
                break;

            case Field.job_order_is_hotlist_filterable:
                cloneFilter1.Option = Field.job_order_is_hotlist_filterable;
                cloneFilter1.Action = NumericFilterAction.Equals.key;
                cloneFilter1.Value = convertToTrueFalse(f.Value);
                clonedFilters.push(cloneFilter1);
                break;

            case Field.kf_advancedservicesrequired:
                cloneFilter1.Option = Field.kf_advancedservicesrequired;
                cloneFilter1.Action = NumericFilterAction.Equals.key;
                cloneFilter1.Value = convertToTrueFalse(f.Value);
                clonedFilters.push(cloneFilter1);
                break;

            case Field.trm_post_to_ilabor:
                clonedFilters.push(cloneFilter1);
                if (isNo(cloneFilter1.Value)) {
                    const cloneFilter2 = clone(cloneFilter1);
                    cloneFilter2.Value = '';
                    clonedFilters.push(cloneFilter2);
                }
                break;

            default:
                clonedFilters.push(cloneFilter1);
                break;
        }

        clonedFilters.forEach(x => {
            switch (x.Action) {
                case NumericFilterAction.Between.key:
                    const splitValue = x.Value?.split(',');

                    const greaterThanFilter = {
                        Option: x.Option,
                        Action: NumericFilterAction.GreaterThan.key,
                        Value: splitValue && splitValue[0] ? splitValue[0] : "0",
                        Group: x.Group
                    };
                    filtersForAPI.push(greaterThanFilter);

                    const lowerThanFilter = {
                        Option: x.Option,
                        Action: NumericFilterAction.LowerThan.key,
                        Value: splitValue && splitValue[1] ? splitValue[1] : "0",
                        Group: x.Group
                    };
                    filtersForAPI.push(lowerThanFilter);
                    break;

                default:
                    filtersForAPI.push(x);
                    break;
            }
        })

    });
    return filtersForAPI;
}

const baseFilterOptions = [
    // INDEXES: 0: DisplayName, 1: Key, 3: Category, 2: Filter fn
    ["Client", Field.account_name, FilterCategory.General],
    ["AB Status", Field.allocation_board_status, FilterCategory.Status],
    ["TRM Status", Field.trm_status, FilterCategory.Status],
    ["Account Manager", Field.owner_fullname, FilterCategory.General],
    ["Job Type", Field.pafo_jobtype, FilterCategory.General],
    ["TRM Priority", Field.trm_priority, FilterCategory.General, x => parseInt(x) !== x && x !== null && x !== ''],
    ["Rank", Field.trm_rank, FilterCategory.General, x => x !== null && x !== ''],
    ["City", Field.trm_worksitecity, FilterCategory.Location],
    ["State", Field.trm_worksitestate, FilterCategory.Location],
    ["Phase", Field.stepname, FilterCategory.AdvancedServices],
    ["Primary Practice Area", Field.kf_practicearea, FilterCategory.AdvancedServices],
    ["Service Capability", Field.kf_servicecapabilityprimary, FilterCategory.AdvancedServices],
    ["Delivery Model", Field.kf_engagementmodel, FilterCategory.AdvancedServices],
];

const departmentFilterOption = ["Department", Field.department_display_name];

function mapFilterOptions(filterOptions, data) {
    const f = data.facets;
    return filterOptions.map(([displayName, key, category, filter]) => {
        let values = f[key] ? f[key].filter(x => x.value).map(x => x.value) : [];
        if (values && filter) {
            values = values.filter(filter);
        }
        values = values.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
        return { displayName, key, values, category };
    });
}

const apiService = {
    getJobOrders: (filters, size, skip, text = "", orderBy = [], cancelToken) => {
        const body = {
            Text: text,
            Size: size,
            Skip: skip,
            OrderBy: orderBy,
            Facets: [],
            Filters: createFiltersForAPI(filters)
        };
        return API.post('/joborder/getjoborders', body, { cancelToken });
    },
    getJobOrdersByHierarchy: (by, name, filters, size, skip, text = "", orderBy = [], cancelToken) => {
        const body = {
            Text: text,
            Size: size,
            Skip: skip,
            OrderBy: orderBy,
            Facets: [],
            Filters: createFiltersForAPI(filters),
            JobOrdersBy: by,
            Name: name
        };
        return API.post('/joborder/getjobordersbyhierarchy', body, { cancelToken });
    },
    getJobOrdersNotAssigned: (departmentId, cancelToken) => {
        const body = {
            Text: "",
            Size: null,
            Skip: null,
            OrderBy: [`${Field.createdon} desc`],
            Facets: [],
            Filters: [{
                Option: Field.owner_kf_financialhierarchyid,
                Action: TextFilterAction.Equals.key,
                Value: departmentId
            }, {
                Option: Field.allocation_board_microteam_id,
                Action: TextFilterAction.Equals.key,
                Value: ''
            }]
        };

        return API.post('/joborder/getjoborders', body, { cancelToken });
    },
    getJobOrderFilters: (filters, useDepartmentFilterOption, cancelToken) => {
        const filterOptions = [...baseFilterOptions];
        if (useDepartmentFilterOption) {
            filterOptions.push(departmentFilterOption)
        }

        const body = {
            Text: "",
            Size: 0,
            Skip: null,
            OrderBy: [],
            Facets: filterOptions.map(x => x[1]),
            Filters: createFiltersForAPI(filters)
        };

        return API.post('/joborder/getjoborderfilters', body, { cancelToken })
            .then(f => mapFilterOptions(filterOptions, f));
    },
    getJobOrderByHierarchyFilters: (by, name, filters, useDepartmentFilterOption, cancelToken) => {
        const filterOptions = [...baseFilterOptions];
        if (useDepartmentFilterOption) {
            filterOptions.push(departmentFilterOption)
        }

        const body = {
            Text: "",
            Size: 0,
            Skip: null,
            OrderBy: [],
            Facets: filterOptions.map(x => x[1]),
            Filters: createFiltersForAPI(filters),
            JobOrdersBy: by,
            Name: name
        };

        return API.post('/joborder/getjobordersbyhierarchyfilters', body, { cancelToken })
            .then(f => mapFilterOptions(filterOptions, f));
    },
    exportJobOrdersToCsv: (filters, text = "", orderBy = [], columnsForExport, cancelToken) => {
        const body = {
            Text: text,
            // Size: null,
            // Skip: null,
            OrderBy: orderBy,
            Facets: [],
            Filters: createFiltersForAPI(filters),
            ColumnsForExport: columnsForExport
        };
        return API.post('/joborder/exportjoborderstocsv', body, { cancelToken, responseType: 'blob' });
    },
    setUserFilters: (userId, pageName, filters) => {
        const body = {
            userid: userId,
            pageName: pageName,
            filters: filters
        };

        return API.post('/usersetting/setfilters', body);
    },
    setUserColumns: (userId, pageName, columns) => {
        const body = {
            userid: userId,
            pageName: pageName,
            columns: columns
        };

        return API.post('/usersetting/setcolumns', body);
    },
    setUserSort: (userId, pageName, columnName, sortOrder) => {
        const body = {
            userid: userId,
            pageName: pageName,
            columnName: columnName,
            sortOrder: sortOrder
        }

        return API.post('/usersetting/setsort', body);
    },
    saveDefaultHomePage: (userId, pageName, departmentId) => {
        const body = {
            userid: userId,
            pageName: pageName,
            departmentId: departmentId
        }

        return API.post('/usersetting/savedefaulthomepage', body);
    },
    getSystemUsers: (text) => {
        const body = {
            Text: text,
            OrderBy: []
        }

        return API.post('/systemuser/getsystemusers', body);
    },
    getSystemUsersAllDepartments: () => {
        const body = {
            OrderBy: []
        }

        return API.post('/systemuser/getsystemusersalldepartments', body);
    },
    getCoverageRecruiterFromDynamics: (userId, jobOrderId) => {
        const body = {
            UserId: userId,
            JobOrderId: jobOrderId
        }

        return API.post('/joborder/getcoveragerecruiterfromdynamics', body);
    },
    getCoverageRecruiterFromIndex: (jobOrderId) => {
        const body = {
            JobOrderId: jobOrderId
        }

        return API.post('/joborder/getcoveragerecruiterfromindex', body);
    },
    addRecruiterCoverage: (systemUserId, jobOrderId, recruiterIds) => {
        const body = {
            UserId: systemUserId,
            JobOrderId: jobOrderId,
            recruiterIds: recruiterIds
        }

        return API.post('/joborder/addrecruitercoverage', body);
    },
    removeRecruiterCoverage: (systemUserId, trmJobOrderRecruiterIds, jobOrderId) => {
        const body = {
            UserId: systemUserId,
            TrmJobOrderRecruiterIds: trmJobOrderRecruiterIds,
            JobOrderId: jobOrderId
        }

        return API.post('/joborder/removerecruitercoverage', body);
    },
    assignOrRemoveIsExclusive: (isExclusive, jobOrderIds) => {
        const body = {
            jobOrderIds: jobOrderIds,
            isExclusive: isExclusive
        };

        return API.post('/joborder/assignorremoveisexclusiveonjoborder', body);
    },
    assignOrRemoveIsHotlist: (isHotlist, jobOrderIds) => {
        const body = {
            jobOrderIds: jobOrderIds,
            isHotlist: isHotlist
        };

        return API.post('/joborder/assignorremoveishotlistonjoborder', body);
    },
    assignOrRemoveMicroteam: (microteamId, jobOrderIds) => {
        const body = {
            jobOrderIds: jobOrderIds,
            microteamId: microteamId
        };

        return API.post('/joborder/assignorremovejobordertomicroteam', body);
    },
    updateJobOrderABStatus(jobOrderId, abStatus) {
        const body = {
            pafo_joborderid: jobOrderId,
            allocation_board_status: abStatus
        };

        return API.post('/joborder/updatejoborderstatus', body);
    },
    addMicroteam: (name, departmentId, userId, jobOrderIds) => {
        const body = {
            microteamname: name,
            departmentid: departmentId,
            createdby: userId,
            jobOrderIds: jobOrderIds
        };

        return API.post('/joborder/addmicroteam', body);
    },
    deleteMicroteam: (id) => {
        const body = {
            microteamid: id,
        };

        return API.post('/joborder/deletemicroteam', body);
    },
    getAnnotation: (userId, jobOrderId) => {
        var body = {
            UserId: userId,
            JobOrderId: jobOrderId
        }

        return API.post('/joborder/getanotation', body);
    },
    saveAnnotation: (userId, jobOrderId, subject, noteText) => {
        var body = {
            UserId: userId,
            JobOrderId: jobOrderId,
            Subject: subject,
            noteText: noteText
        };

        return API.post('/joborder/saveanotation', body);
    },
    assignJobOrderPriority: (jobOrderId, priority) => {
        const body = {
            pafo_joborderid: jobOrderId,
            allocation_board_priority: Number(priority)
        }

        return API.post('/joborder/assignjoborderpriority', body);
    },
    getSecurityUsers: (filters, size, skip, text = '', orderBy = []) => {
        const body = {
            Text: text,
            Size: size,
            Skip: skip,
            OrderBy: orderBy,
            Facets: [],
            Filters: filters
        };

        return API.post('/security/getusers', body);
    },
    getSecurityFilters: async () => {

        const departments = await API.get('/security/getdepartments');
        const roles = await API.get('/security/getroles');

        return [
            {
                displayName: "Role",
                key: "roleName",
                values: roles.map(x => ({
                    id: x.roleName,
                    name: x.roleName
                }))
            },
            {
                displayName: "Department",
                key: "departmentName",
                values: departments.map(x => ({
                    id: x.kf_financialhierarchyid,
                    name: x.kf_departmentdisplayname
                }))
            }
        ];
    },
    assignRole: (objectId, roleName, departments) => {
        const body = {
            ObjectId: objectId,
            RoleName: roleName,
            DepartmentList: departments
        };

        return API.post('/security/assignrole', body);
    },
    getRegions: () => {
        return API.get('/jobordersrleader/getregions');
    },
    getMarketsByRegion: (region) => {
        return API.get(`/jobordersrleader/getmarketsbyregion/${region}`);
    },
    getOfficesByMarket: (market) => {
        return API.get(`/jobordersrleader/getofficesbymarket/${market}`);
    },
    getDepartmentsByOffice: (office) => {
        return API.get(`/jobordersrleader/getdepartmentsbyoffice/${office}`);
    },
    addDelegate: function (officeLeaderId, delegateListIds) {
        const body = { officeLeaderId, delegateListIds };
        return API.post('/usersetting/adddelegate', body);
    },
    removeDelegate: function (officeLeaderId, delegateListIds) {
        const body = { officeLeaderId, delegateListIds };
        return API.post('/usersetting/removedelegate', body);
    },
    delegatesAssigned: function (officeLeaderId) {
        const body = { officeLeaderId };
        return API.post('/usersetting/delegatesassigned', body);
    },
    searchDelegate: function () {
        return API.post('/usersetting/searchdelegates');
    },
    saveImportantNote: (jobOrderId, importantNote, cancelToken) => {
        const body = {
            JobOrderId: jobOrderId,
            ImportantNote: importantNote
        };
        return API.post('/joborder/saveimportantnote', body, { cancelToken });
    }
}

export default apiService;