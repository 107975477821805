import './GridColumnHeader.css';
import React from 'react';
import { connect } from 'react-redux';
import { setUser } from '../../../store/actions';
import BasicPortal from '../BasicPortal/BasicPortal';
import { Dropdown, PrimaryButton, DefaultButton, TextField } from '@fluentui/react';
import { TextFilterAction, NumericFilterAction, Page, textFilterActionList, numericFilterActionList, Field } from '../../../constants/constants';
import apiService from '../../../services/api-service';

class GridColumnHeader extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentSort: 'none',
            showFilter: false,
            textFilterAction: TextFilterAction.Contains.key,
            defaultTextFilterAction: TextFilterAction.Contains.key,
            defaultTextFilterValue: '',
            numericFilterAction: NumericFilterAction.Equals.key,
            defaultNumericFilterAction: NumericFilterAction.Equals.key,
            defaultNumericFilterValue: '',
            filterModalX: 0,
            betweenGreaterThanErrorMessage: "",
            betweenLowerThanErrorMessage: "",
            x: 0,
            y: 0
        };

        this.dragMouseDown = this.dragMouseDown.bind(this);
        this.elementDrag = this.elementDrag.bind(this);
        this.closeDragElement = this.closeDragElement.bind(this);
        this.reff = React.createRef();
        this.pos1 = 0;
        this.pos2 = 0;
        this.pos3 = 0;
        this.pos4 = 0;
    }

    componentDidMount() {
        const { column, userSort } = this.props;

        if (column.colId && userSort?.columnName === column.colId) {
            this.setState({ currentSort: userSort.sortOrder });
        }

        if (this.isYesNoColumn() || this.isFixedValuesColumn()) {
            this.setState({
                textFilterAction: TextFilterAction.Equals.key,
                defaultTextFilterAction: TextFilterAction.Equals.key
            });
        }
    }

    dragMouseDown(e) {
        e.preventDefault()
        this.pos3 = e.clientX
        this.pos4 = e.clientY
        document.onmouseup = this.closeDragElement
        document.onmousemove = this.elementDrag
    }

    elementDrag(e) {
        e.preventDefault()
        this.pos1 = this.pos3 - e.clientX
        this.pos2 = this.pos4 - e.clientY
        this.pos3 = e.clientX
        this.pos4 = e.clientY
        this.setState({
            y: (this.reff.current.offsetTop - this.pos2) + "px",
            x: (this.reff.current.offsetLeft - this.pos1) + "px",
        })
    }

    closeDragElement() {
        document.onmouseup = null
        document.onmousemove = null
    }

    updateFilters(value, action) {
        const { onApplyPageFilters } = this.props;
        const newFilters = this.getUpdatedFilters(value, action);
        onApplyPageFilters(newFilters);
    }

    getFilterValue() {
        const { tableFilters, column } = this.props;
        const filter = tableFilters.filter(x => x.Option === column.colId);
        return filter && filter.length > 0 && filter[0].Value ? filter[0].Value : "";
    }


    getUpdatedFilters(value, action) {
        const { tableFilters, column } = this.props;

        const filters = tableFilters.filter(x => x.Option !== column.colId);

        if (value) {
            filters.push({
                Option: column.colId,
                Action: action,
                Value: value
            });
        }

        return filters;
    }

    getOrderBy(column, sort) {
        return [`${column} ${sort}`];
    }

    closeColumnFilter(isNumeric) {
        const { onApplyPageFilters } = this.props;
        const { defaultTextFilterValue, defaultTextFilterAction, defaultNumericFilterValue, defaultNumericFilterAction } = this.state;

        const filterValue = isNumeric ? defaultNumericFilterValue : defaultTextFilterValue;
        const filterAction = isNumeric ? defaultNumericFilterAction : defaultTextFilterAction;

        this.setState({
            showFilter: false,
            betweenGreaterThanErrorMessage: "",
            betweenLowerThanErrorMessage: ""
        });
        const filters = this.getUpdatedFilters(filterValue, filterAction);
        onApplyPageFilters(filters);
    }

    applyFilter(filterValue, isNumeric) {
        const { textFilterAction, numericFilterAction } = this.state;
        const { column, onRefreshTableData, userSort } = this.props;

        const filterAction = isNumeric ? numericFilterAction : textFilterAction;

        if (!column.colId) {
            console.error("Something went wrong. The column name is not valid.");
            return;
        }

        const orderBy = this.getOrderBy(userSort.columnName, userSort.sortOrder);
        const filters = this.getUpdatedFilters(filterValue, filterAction);

        onRefreshTableData(orderBy, filters, true);
        this.setState({
            showFilter: false,
            [isNumeric ? "defaultNumericFilterValue" : "defaultTextFilterValue"]: filterValue,
            [isNumeric ? "defaultNumericFilterAction" : "defaultTextFilterAction"]: filterAction
        });
    }

    applySort() {
        const { currentSort } = this.state;
        const { column, user, tableFilters, onRefreshTableData, settingsPage } = this.props;

        if (!column.colId) {
            console.error("Something went wrong. The column name is not valid.");
            return;
        }

        const nextSort = this.getNextSort(currentSort)

        if (!nextSort) {
            console.error("Something went wrong. The sort order is not valid.");
            return;
        }

        user.settings[settingsPage + "Page"].sort = {
            columnName: column.colId,
            sortOrder: nextSort
        };

        this.props.setUser(user);

        const orderBy = this.getOrderBy(column.colId, nextSort);

        onRefreshTableData(orderBy, tableFilters, false);

        this.setState({ currentSort: nextSort });
        apiService.setUserSort(user.oid, Page.myDepartments, column.colId, nextSort);
    }

    getNextSort(currentSort) {
        switch (currentSort) {
            case 'asc':
                return 'desc';
            case 'desc':
                return 'asc';
            default:
                return 'asc';
        }
    }

    toggleColumnFilter(e, isNumeric) {
        const { showFilter } = this.state;

        if (showFilter) {
            this.closeColumnFilter(isNumeric);
        } else {
            this.setState({
                x: 0,
                y: 0,
                showFilter: true,
                filterModalX: e.clientX + 10
            }, () => {
                this.valueRef?.focus();
            });
        }
    }

    isYesNoColumn() {
        const { column } = this.props;
        const result = [
            Field.trm_issjoborder,
            Field.trm_vsdjoborder,
            Field.trm_posttoweb,
            Field.trm_post_to_ilabor,
            Field.kf_advancedservicesrequired
        ].includes(column.colId)
        return result;
    }

    isFixedValuesColumn() {
        const { column } = this.props;
        const result = [
            Field.stepname,
            Field.kf_engagementmodel,
            Field.kf_practicearea
        ].includes(column.colId)
        return result;
    }

    getFixedValues() {
        const { column, filterOptions } = this.props;

        if (this.isYesNoColumn()) {
            return {
                placeholder: "Yes or No",
                options: [
                    { key: "Yes", text: "Yes" },
                    { key: "No", text: "No" }
                ]
            }
        }

        if (this.isFixedValuesColumn()) {
            return {
                placeholder: "Fixed Values",
                options: filterOptions
                    .find(x => x.key === column.colId)?.values
                    .map(x => ({ key: x, text: x })) || []
            }
        }
    }

    isRecruitersOrCandidatesColumn() {
        const { column } = this.props;
        const result = [Field.recruiters, Field.candidates].includes(column.colId)
        return result;
    }

    getActionList(isNumeric) {
        if (isNumeric)
            return numericFilterActionList;

        if (this.isRecruitersOrCandidatesColumn())
            return [TextFilterAction.Contains];

        if (this.isYesNoColumn() || this.isFixedValuesColumn())
            return [TextFilterAction.Equals];

        return textFilterActionList;
    }

    renderFilter(filterValue, isNumeric) {
        const { column } = this.props;
        const { showFilter, textFilterAction, numericFilterAction, betweenGreaterThanErrorMessage, betweenLowerThanErrorMessage } = this.state;

        const splitValue = filterValue?.split(',');
        let betweenGreaterThanValue = splitValue && splitValue[0] ? splitValue[0] : "";
        let betweenLowerThanValue = splitValue && splitValue[1] ? splitValue[1] : "";

        const filterAction = isNumeric ? numericFilterAction : textFilterAction;
        const actionList = this.getActionList(isNumeric);

        const fixedValues = this.getFixedValues();

        return (
            <div className={"option" + (filterValue ? " with-filter" : "")} style={{ marginRight: '4px' }}>
                <i className="ag-icon ag-icon-menu" onClick={(e) => this.toggleColumnFilter(e, isNumeric)} />
                {
                    showFilter &&
                    <BasicPortal target="column-filter">
                        <div className="column-filter-container"
                            onKeyDown={(e => e.key === "Escape" && this.closeColumnFilter(isNumeric))}
                            style={{ left: this.state.x, top: this.state.y }}
                            ref={this.reff}>
                            <div
                                className="column-filter-wrapper"
                                style={{
                                    top: '180px',
                                    left: this.state.filterModalX + 'px',
                                    position: "relative"
                                }}>
                                <div className="column-filter-items">
                                    <div className="p-0 m-0 movable"
                                        onMouseDown={this.dragMouseDown}
                                    >
                                        <span>
                                            {column.userProvidedColDef.headerTooltip || column.userProvidedColDef.headerName}
                                        </span>
                                        <div style={{ float: 'right' }}>
                                            <button className="close-button"
                                                onClick={() => this.closeColumnFilter(isNumeric)}
                                            >X</button>
                                        </div>
                                    </div>

                                    <div>
                                        <Dropdown
                                            dropdownWidth="auto"
                                            placeholder="Select"
                                            onChange={(i, val) => {
                                                if (isNumeric) {
                                                    this.updateFilters("", val.key)
                                                    this.setState({
                                                        numericFilterAction: val.key,
                                                        betweenGreaterThanErrorMessage: "",
                                                        betweenLowerThanErrorMessage: ""
                                                    });
                                                } else {
                                                    this.setState({
                                                        textFilterAction: val.key
                                                    });
                                                }
                                            }}
                                            options={actionList}
                                            selectedKey={filterAction}
                                        />
                                    </div>
                                    <div>
                                        {
                                            numericFilterAction === NumericFilterAction.Between.key ?
                                                <>
                                                    {NumericFilterAction.GreaterThan.text}
                                                    <TextField
                                                        errorMessage={betweenGreaterThanErrorMessage}
                                                        value={betweenGreaterThanValue}
                                                        type="number"
                                                        onChange={d => {
                                                            if (!betweenLowerThanValue) {
                                                                betweenLowerThanValue = "0";
                                                            }
                                                            this.updateFilters(`${d.target.value},${betweenLowerThanValue}`, numericFilterAction);
                                                            const errorMessage = Number(d.target.value) < Number(betweenLowerThanValue)
                                                                ? "" : `This value should be less than "${NumericFilterAction.LowerThan.text}'s" value`;
                                                            this.setState({
                                                                betweenGreaterThanErrorMessage: errorMessage,
                                                                betweenLowerThanErrorMessage: ""
                                                            });
                                                        }}
                                                    />
                                                    {NumericFilterAction.LowerThan.text}
                                                    <TextField
                                                        errorMessage={betweenLowerThanErrorMessage}
                                                        value={betweenLowerThanValue}
                                                        type="number"
                                                        onChange={d => {
                                                            if (!betweenGreaterThanValue) {
                                                                betweenGreaterThanValue = "0";
                                                            }
                                                            this.updateFilters(`${betweenGreaterThanValue},${d.target.value}`, numericFilterAction);
                                                            const errorMessage = Number(betweenGreaterThanValue) < Number(d.target.value)
                                                                ? "" : `This value should be greater than "${NumericFilterAction.GreaterThan.text}'s" value`;
                                                            this.setState({
                                                                betweenGreaterThanErrorMessage: "",
                                                                betweenLowerThanErrorMessage: errorMessage
                                                            });
                                                        }}
                                                    />
                                                </>
                                                :
                                                !!fixedValues?.options?.length
                                                    ? (
                                                        <Dropdown
                                                            componentRef={el => this.valueRef = el}
                                                            placeholder={fixedValues.placeholder}
                                                            options={fixedValues.options}
                                                            multiSelect
                                                            onChange={(i, val) => {
                                                                const value = val.selected ? val.key : undefined;
                                                                this.updateFilters(value, filterAction);
                                                                this.valueRef.dismissMenu();
                                                            }}
                                                            selectedKeys={filterValue ? [filterValue] : []}
                                                            onKeyDown={e => {
                                                                if (e.key === 'Enter') {
                                                                    this.applyFilter(this.getFilterValue(), isNumeric)
                                                                }
                                                            }}
                                                        />
                                                    )
                                                    : (
                                                        <TextField
                                                            componentRef={el => this.valueRef = el}
                                                            value={filterValue}
                                                            type={isNumeric ? "number" : "text"}
                                                            onChange={d => this.updateFilters(d.target.value, filterAction)}
                                                            onKeyDown={e => {
                                                                if (e.key === 'Enter') {
                                                                    this.applyFilter(this.getFilterValue(), isNumeric)
                                                                }
                                                            }}
                                                        />
                                                    )
                                        }
                                    </div>
                                    <div className='d-flex' style={{ gap: 5 }}>
                                        <DefaultButton
                                            className="col-6"
                                            iconProps={{ iconName: 'ClearFilter' }}
                                            onClick={(e) => this.applyFilter('', isNumeric)}
                                        >Clear</DefaultButton>
                                        <PrimaryButton
                                            disabled={isNumeric && numericFilterAction === NumericFilterAction.Between.key && (betweenGreaterThanErrorMessage || betweenLowerThanErrorMessage)}
                                            className="col-6"
                                            iconProps={{ iconName: 'Filter' }}
                                            onClick={(e) => this.applyFilter(this.getFilterValue(), isNumeric)}
                                        >Apply</PrimaryButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </BasicPortal>
                }
            </div>
        )
    }

    renderSort() {
        const { column, userSort } = this.props;
        const sortIcon = column.colId && userSort?.columnName === column.colId ? userSort.sortOrder : 'none';

        return (
            <div className="option" style={{ marginLeft: '4px' }} onClick={(e) => this.applySort()}>
                <i className={"ag-icon ag-icon-" + sortIcon} />
            </div>
        )
    }

    render() {
        const { displayName, column } = this.props;

        const isFilterable = column.colDef.optional?.filterable;
        const isNumeric = column.colDef.optional?.filterableType === "numeric";
        const filterValue = isFilterable
            ? this.getFilterValue()
            : undefined;

        return (
            <>
                <div className="column-options">
                    {
                        isFilterable &&
                        this.renderFilter(filterValue, isNumeric)
                    }
                    <div className="columnHeaderLabel ag-header-cell-text">
                        {displayName}
                    </div>
                    {
                        column.colDef.optional?.sortable &&
                        this.renderSort()
                    }
                </div>
                {/* {
                    filterValue &&
                    <div style={{ color: 'darkblue', fontSize: '8px' }}>{filterValue} ({textFilterAction})</div>
                } */}
            </>
        )
    }
}

const mapStatetoProps = state => ({
    user: state.session.user
})

const mapDispatchToProps = (dispatch) => ({
    setUser: user => dispatch(setUser(user))
})

export default connect(mapStatetoProps, mapDispatchToProps)(GridColumnHeader);