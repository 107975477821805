import { Field, TextFilterAction } from "../constants/constants";
import { advancedServicesFilterOption, exclusiveFilterOption, hotlistFilterOption, iLaborFilterOption, postToWebFilterOption } from "../constants/service-filters";

const myDepartmentsService = {

    createGroupBy: function (sort) {
        if (!sort || !sort.columnName || !sort.sortOrder) {
            return [];
        }
        return [`${sort.columnName} ${sort.sortOrder}`];
    },

    createMandatoryFilter: function (microteamId, departmentId) {
        const mandatoryFilter = {
            Option: microteamId ? Field.allocation_board_microteam_id : Field.owner_kf_financialhierarchyid,
            Action: TextFilterAction.Equals.key,
            Value: microteamId ? microteamId : departmentId,
            Group: this.getGroupId(microteamId, departmentId)
        }
        return mandatoryFilter;
    },

    createFilterOptions: function (facetsData) {
        return [
            ...facetsData, 
            exclusiveFilterOption, 
            hotlistFilterOption,
            postToWebFilterOption,
            iLaborFilterOption,
            advancedServicesFilterOption
        ];
    },

    getGroupId: function (microteamId, departmentId, forceUseMicroteam) {
        return microteamId || forceUseMicroteam ? `MT_${microteamId}` : departmentId
    },

    mapFiltersToUI: function (filters) {
        return filters && filters.length > 0
            ? filters.map(x => ({
                columnName: x.Option,
                action: x.Action,
                value: x.Value,
                group: x.Group
            }))
            : [];
    },

    mapFiltersToAPI: function (filters) {
        return filters && filters.length > 0
            ? filters.map(x => ({
                Option: x.columnName,
                Action: x.action,
                Value: x.value,
                Group: x.group
            }))
            : [];
    }
}

export default myDepartmentsService;