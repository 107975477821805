import * as actionTypes from './actionTypes'

export const setAllOrdersLoading = payload => ({
    type: actionTypes.SET_ALLORDERS_LOADING,
    payload
})

export const setAllOrdersSelectedRows = payload => ({
    type: actionTypes.SET_ALLORDERS_SELECTEDROWS,
    payload
})

export const setAllOrdersRowDetail = payload => ({
    type: actionTypes.SET_ALLORDERS_ROWDETAIL,
    payload
})

export const setAllOrdersData = payload => ({
    type: actionTypes.SET_ALLORDERS_DATA,
    payload
})

export const setAllOrdersPagination = (size, skip) => ({
    type: actionTypes.SET_ALLORDERS_PAGINATION,
    payload: { size, skip }
})

export const setAllOrdersFilters = payload => ({
    type: actionTypes.SET_ALLORDERS_FILTERS,
    payload
})

export const setAllOrdersFilterOptions = payload => ({
    type: actionTypes.SET_ALLORDERS_FILTEROPTIONS,
    payload
})

export const setAllOrdersColumns = payload => ({
    type: actionTypes.SET_ALLORDERS_COLUMNS,
    payload
})

export const setAllOrdersSearchText = payload => ({
    type: actionTypes.SET_ALLORDERS_SEARCHTEXT,
    payload
})

export const setAllOrdersShowNotes = payload => ({
    type: actionTypes.SET_ALLORDERS_SHOWNOTES,
    payload
})

export const setAllOrdersFirms = payload => ({
    type: actionTypes.SET_ALLORDERS_FIRMS,
    payload
})

export const setAllOrdersSelectedFirm = payload => ({
    type: actionTypes.SET_ALLORDERS_SELECTEDFIRM,
    payload
})

export const setAllOrdersRegions = payload => ({
    type: actionTypes.SET_ALLORDERS_REGIONS,
    payload
})

export const setAllOrdersSelectedRegion = payload => ({
    type: actionTypes.SET_ALLORDERS_SELECTEDREGION,
    payload
})

export const setAllOrdersMarkets = payload => ({
    type: actionTypes.SET_ALLORDERS_MARKETS,
    payload
})

export const setAllOrdersSelectedMarket = payload => ({
    type: actionTypes.SET_ALLORDERS_SELECTEDMARKET,
    payload
})

export const setAllOrdersOffices = payload => ({
    type: actionTypes.SET_ALLORDERS_OFFICES,
    payload
})

export const setAllOrdersSelectedOffice = payload => ({
    type: actionTypes.SET_ALLORDERS_SELECTEDOFFICE,
    payload
})

export const setAllOrdersDepartments = payload => ({
    type: actionTypes.SET_ALLORDERS_DEPARTMENTS,
    payload
})

export const setAllOrdersSelectedDepartment = payload => ({
    type: actionTypes.SET_ALLORDERS_SELECTEDDEPARTMENT,
    payload
})

export const setAllOrdersHierarchy = payload => ({
    type: actionTypes.SET_ALLORDERS_HIERARCHY,
    payload
})

export const setAllOrdersBreadcrumbs = payload => ({
    type: actionTypes.SET_ALLORDERS_BREADCRUMBS,
    payload
})