import React from 'react';
import jwt_decode from "jwt-decode";
import { withRouter } from 'react-router-dom';

// Utils
import ErrorPage from '../shared/errorPage';
import API from '../../api';
import { useMockUser } from '../../config';

// Model
import { Page, Role } from '../../constants/constants';

//Redux
import { connect, batch } from 'react-redux';
import { setUser, setActiveDepartment, setConsolidatedIsDelegate } from '../../store/actions';
import Loading from '../shared/Loading/Loading';

class InitialValidator extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isAuthorized: null,
            percentComplete: 0.79
        }
    }

    componentDidMount() {
        this.authorizedUser();
    }

    authorizedUser() {
        API.post('/authprocess')
            .then(data => {
                this.setState({ percentComplete: 0.91 });

                window.setTimeout(() => {
                    const jwt = jwt_decode(this.props.token);
                    const user = Object.assign({}, data, jwt);

                    switch (data.roleName) {
                        case Role.securityAdmin:
                            this.handleSecurityUsers(user);
                            break;
                        case Role.seniorLeader:
                            this.handleSeniorLeaderUsers(user);
                            break;
                        case Role.officeLeader:
                            this.handleOfficeLeaderUsers(user);
                            break;
                        case Role.associate:
                            this.handleAssociateUsers(user);
                            break;
                        default:
                            throw new Error("Invalid role");
                    }
                }, 500);
            })
            .catch(error => {
                if (useMockUser) {
                    const user = {
                        roleName: 'Office Leader',
                        officeLeaderDepartmentList: [],
                        settings: {
                            consolidatedOrdersPage: {
                                filter: {
                                    filters: []
                                }
                            },
                            allOrdersPage: {
                                filter: {
                                    filters: []
                                }
                            },
                            myDepartmentsPage: {
                                filter: {
                                    filters: []
                                }
                            },
                            preferredHomePage: {
                                page: 'consolidatedOrders'
                            }
                        }
                    };
                    this.props.setUser(user);
                    this.props.history.push({
                        pathname: 'consolidatedOrders'
                    });
                } else {
                    this.setState({ isAuthorized: false });
                }
            });
    }

    handleSecurityUsers(user) {
        this.props.setUser(user);
        this.props.history.push({
            pathname: 'security'
        });
    }

    handleSeniorLeaderUsers(user) {
        this.props.setUser(user);
        this.props.history.push({
            pathname: 'allOrders'
        });
    }

    handleOfficeLeaderUsers(user) {
        const hasDepartments = user.officeLeaderDepartmentList && user.officeLeaderDepartmentList.length > 0;

        const departmentId = this.getActiveDepartmentId(user);

        const page = hasDepartments
            ? this.getPreferredPath(user.settings.preferredHomePage.page)
            : Page.allOrders;

        batch(() => {
            this.props.setUser(user);
            if (page === Page.myDepartments) {
                this.props.setActiveDepartment(departmentId);
            }
        })

        this.props.history.push({ pathname: page });
    }

    handleAssociateUsers(user) {
        const hasDepartments = user.officeLeaderDepartmentList && user.officeLeaderDepartmentList.length > 0;

        const isDelegate = hasDepartments && user.officeLeaderDepartmentList.some(x => x.isDelegate);

        const departmentId = this.getActiveDepartmentId(user);

        const page = hasDepartments
            ? this.getPreferredPath(user.settings.preferredHomePage.page)
            : Page.allOrders;

        batch(() => {
            this.props.setConsolidatedIsDelegate(isDelegate);
            this.props.setUser(user);
            if (page === Page.myDepartments) {
                this.props.setActiveDepartment(departmentId);
            }
        });

        this.props.history.push({ pathname: page });
    }

    getActiveDepartmentId(user) {
        const hasDepartments = user.officeLeaderDepartmentList && user.officeLeaderDepartmentList.length > 0;

        let departmentId = undefined;
        if (hasDepartments && user.settings.preferredHomePage.page === Page.myDepartments) {
            let department = user.officeLeaderDepartmentList.find(x => x.kf_financialhierarchyid === user.settings.preferredHomePage.data);
            if (!department) {
                department = user.officeLeaderDepartmentList[0];
            }
            departmentId = department.kf_financialhierarchyid;
        }

        return departmentId;
    }

    getPreferredPath(page) {
        switch (page) {
            case Page.consolidatedOrders:
                return "consolidatedOrders";
            case Page.myDepartments:
                return "myDepartments";
            case Page.allOrders:
                return "allOrders";
            default:
                return "consolidatedOrders";
        }
    }

    render() {
        const { isAuthorized, percentComplete } = this.state;
        return (
            isAuthorized !== false
                ? <Loading description="Checking authorization, please wait..." percentComplete={percentComplete} />
                : <ErrorPage />
        )
    }
}

const mapStateToProps = (state) => ({
    token: state.session.token
})
const mapDispatchToProps = (dispatch) => ({
    setUser: user => dispatch(setUser(user)),
    setActiveDepartment: id => dispatch(setActiveDepartment(id)),
    setConsolidatedIsDelegate: data => dispatch(setConsolidatedIsDelegate(data))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InitialValidator))