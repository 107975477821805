import * as React from 'react';
import { connect } from 'react-redux';
import { ActionButton, PrimaryButton, SearchBox, Spinner } from '@fluentui/react';
import MicroTeamList from '../MicroTeamList/MicroTeamList';
import apiService from '../../../services/api-service';
import { addNotification } from '../../../store/actions';
import PermissionControllerRender from '../../../utils/auth';

class MicroteamDetail extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            showAddPopup: false,
            selectedMicroTeam: null,
            microteamsData: [],
            allMicroteams: []
        }
    }

    componentDidMount() {
        const { department } = this.props;

        if (!department || !department.microteams) {
            return;
        }

        const allMicroteams = department.microteams.map(mt => ({
            id: mt.microteamid,
            description: mt.microteamname,
            name: mt.microteamname
                ? mt.microteamname.length > 1
                    ? mt.microteamname.slice(0, 2).toUpperCase()
                    : mt.microteamname[0].toUpperCase()
                : '',
            thumbnail: ''
        }));

        this.setState({ allMicroteams: allMicroteams }, () => this.refreshMicroeamsData());
    }

    refreshMicroeamsData(searchValue) {
        let mteams = this.state.allMicroteams;
        if (searchValue && (searchValue !== '')) {
            const searchValueLowerCase = searchValue.toLowerCase();
            mteams = mteams.filter(mt => mt.description?.toLowerCase().indexOf(searchValueLowerCase) !== -1);
        }
        this.setState({ microteamsData: mteams });
    }

    getAssignedMicroteam() {
        const microTeamId = this.props.rowDetail.data.allocation_board_microteam_id;
        if (!microTeamId) {
            return [];
        }
        return this.state.allMicroteams.filter(x => x.id === microTeamId);
    }

    assignMicroTeam(mt) {
        const { rowDetail } = this.props;

        this.toggleModal(false);
        this.setState({ loading: true });

        const mtId = mt?.id || null;
        const mtName = mt?.description || "";
        const microteamTitle = mtName ? `'${mtName}'` : "";
        const lastMtId = mtId || rowDetail?.node?.data?.allocation_board_microteam_id;
        const status = mtId ? "assigned" : "unassigned";

        let notificationType = 'error';
        let notificationText = `Microteam ${microteamTitle} was not ${status} successfully${lastMtId ? ". Microteam ID: " + lastMtId : ""}`;

        apiService
            .assignOrRemoveMicroteam(mtId, [this.props.rowDetail.data.pafo_joborderid])
            .then(data => {
                if (data === true) {
                    notificationType = 'success';
                    notificationText = `Microteam ${microteamTitle} ${status}`
                }
            })
            .catch(error => {
                console.error(error);
            })
            .finally(() => {
                this.props.addNotification({ type: notificationType, text: notificationText });
                this.setState({ loading: false });
            });

        rowDetail.node.data.allocation_board_microteam_id = mtId;
        rowDetail.node.setData(rowDetail.node.data);
        const column = rowDetail.columnApi.getColumn("mt");
        if (column?.colId) {
            rowDetail.api.refreshCells({ rowNodes: [rowDetail.node], force: true });
        }
    }

    toggleModal(showAddPopup) {
        this.setState({
            showAddPopup: showAddPopup,
            selectedMicroTeam: null
        });
        this.refreshMicroeamsData();
    }

    render() {
        const { showAddPopup, selectedMicroTeam, microteamsData, loading } = this.state;

        return (
            <section>
                <div className="d-flex justify-content-between align-items-center">
                    <div className='w-100'>
                        {
                            !loading ?
                                <MicroTeamList
                                    data={this.getAssignedMicroteam()}
                                    removable={true}
                                    itemRemoved={(i) => this.assignMicroTeam()}
                                ></MicroTeamList>
                                :
                                <div className="mt-3">
                                    <Spinner label="Loading microteam..." ariaLive="assertive" labelPosition="right" />
                                </div>
                        }
                    </div>
                    <div>
                        <div className="position-relative">
                            <PermissionControllerRender for="ADD_MICROTEAM" skip={this.props.department.isDelegate}>
                                <ActionButton
                                    onClick={() => this.toggleModal(!showAddPopup)}
                                    iconProps={{ iconName: !showAddPopup ? 'AddTo' : 'Blocked2' }}
                                    disabled={loading}
                                />
                            </PermissionControllerRender>
                            {
                                !loading && showAddPopup &&
                                <div className="position-absolute card bg-white shadow border p-2"
                                    style={{ right: '0px', zIndex: 3, top: '40px', width: '240px' }}>
                                    <SearchBox
                                        placeholder="Search"
                                        className="mb-3"
                                        onSearch={newValue => this.refreshMicroeamsData(newValue)}
                                    />
                                    <MicroTeamList
                                        selectable={true}
                                        itemSelected={(i) => this.setState({ selectedMicroTeam: i })}
                                        data={microteamsData}
                                    ></MicroTeamList>
                                    <PrimaryButton
                                        className="mt-3"
                                        disabled={!selectedMicroTeam}
                                        iconProps={{ iconName: 'Add' }}
                                        onClick={() => this.assignMicroTeam(selectedMicroTeam)}
                                    >Assign</PrimaryButton>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}


const mapStateToProps = state => ({
    user: state.session.user,
})

const mapDispatchToProps = (dispatch) => ({
    addNotification: not => dispatch(addNotification(not))
})

export default connect(mapStateToProps, mapDispatchToProps)(MicroteamDetail)