import React from 'react';
import { connect } from 'react-redux';
import { ActivityItem } from '@fluentui/react';

class MicroteamCell extends React.Component {

    render() {
        const { microteams, id } = this.props;

        const microteam = microteams?.filter(x => x.microteamid === id)[0];

        if (!microteam?.microteamname) {
            return null;
        }

        const activityPersonas = [{
            imageInitials: microteam.microteamname.length > 1 
                ? microteam.microteamname.slice(0, 2).toUpperCase() 
                : microteam.microteamname,
            text: microteam.microteamname
        }];

        return <ActivityItem
            // className="h-100"
            activityPersonas={activityPersonas}
            isCompact={true}
        />
    }
};

const mapStateToProps = state => ({
    microteams: state.session.activeDepartment.microteams
})

export default connect(mapStateToProps)(MicroteamCell);
