import * as React from 'react';
import './MicroTeamList.css';
import { ActivityItem, List } from '@fluentui/react';
import NA from '../NA/NA';

class MicroTeamList extends React.Component {

    onSelectItem(item) {
        this.props.itemSelected(item);
    }

    onRenderCell = item => {
        const { selectable, removable } = this.props;

        return (
            <div className={`microteam-list d-flex justify-content-between align-items-center col-12 ${!selectable ? 'pl-0' : 'pl-1'}`}>
                {
                    selectable &&
                    <input type="radio" name="group" onChange={() => this.onSelectItem(item)} />
                }
                <ActivityItem activityPersonas={[
                    { imageInitials: item.name, text: item.description },
                ]} className="d-flex align-items-center col-11 px-1 pb-1 jo-list" activityDescription={item.description}>
                </ActivityItem>
                {
                    removable &&
                    <i className="fa fa-close" onClick={() => this.props.itemRemoved(item)} />
                }
            </div>
        );
    };

    render() {
        const { data } = this.props;

        return !data?.length
            ? <NA />
            : <List
                items={data}
                onRenderCell={this.onRenderCell}
                className="scrollbar"
                style={{ maxHeight: 200, overflowY: 'auto' }}
            />
    }
}

export default MicroTeamList