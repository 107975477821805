import { combineReducers } from 'redux'
import session from './session'
import consolidated from './consolidated'
import mydepartments from './mydepartments'
import allorders from './allorders'
import security from './security'
import notification from './notification'
import system from './system'

export default combineReducers({
    session,
    consolidated,
    mydepartments,
    allorders,
    security,
    notification,
    system
})