import React from 'react';
import { Pivot, PivotItem, PrimaryButton } from '@fluentui/react';
import NewMicroteam from './NewMicroteam/NewMicroteam';
import {
    setActiveMicroteam,
    setMyDepartmentsData,
    setMyDepartmentsPagination,
    setMyDepartmentsLoading,
    setMyDepartmentsSearchText,
    setMyDepartmentsFilters,
    setUser
} from '../../../../store/actions';
import { connect, batch } from 'react-redux';
import myDepartmentsService from '../../../../services/mydepartments-service';
import PermissionControllerRender from '../../../../utils/auth';
import apiService from '../../../../services/api-service';

class MicroteamsSection extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showNewMicroteam: false
        };
    }

    selectMicroteam(microteamId) {
        const { activeDepartment, tablePagination, user } = this.props;

        const isMicroteam = microteamId && microteamId !== "All";

        const filterGroup = myDepartmentsService.getGroupId(microteamId, activeDepartment.kf_financialhierarchyid, isMicroteam);
        // const saveFilters = user.settings.myDepartmentsPage.filter.filters.filter(x => x.group !== filterGroup);
        const newUserFilters = user.settings.myDepartmentsPage.filter.filters.filter(x => x.group === filterGroup);

        const filters = [
            myDepartmentsService.createMandatoryFilter(isMicroteam ? microteamId : "", activeDepartment.kf_financialhierarchyid),
            ...myDepartmentsService.mapFiltersToAPI(newUserFilters)
        ];

        // user.settings.myDepartmentsPage.filter.filters = newUserFilters;

        const orderBy = myDepartmentsService.createGroupBy(user.settings.myDepartmentsPage.sort);

        batch(() => {
            this.props.setMyDepartmentsFilters([]);
            this.props.setMyDepartmentsSearchText('');
            this.props.setMyDepartmentsLoading(true);
            // this.props.setUser(user);
        });


        apiService
            .getJobOrders(filters, tablePagination.size, 0, '', orderBy)
            .then(data => {
                batch(() => {
                    this.props.setActiveMicroteam(microteamId);
                    this.props.setMyDepartmentsData(data);
                    this.props.setMyDepartmentsPagination(tablePagination.size, 0);
                    this.props.setMyDepartmentsLoading(false);
                })
            });
    }

    render() {
        const { activeMicroteam, activeDepartment } = this.props;
        const { showNewMicroteam } = this.state;

        const items = activeDepartment.microteams.map(mt => ({
            id: mt.microteamid,
            name: mt.microteamname
        }));
        items.unshift({ id: "All", name: "All" });

        return (
            <>
                {
                    showNewMicroteam &&
                    <NewMicroteam
                        isOpen={showNewMicroteam}
                        onSelectMicroteam={id => this.selectMicroteam(id)}
                        onClose={() => this.setState({ showNewMicroteam: false })}
                    />
                }

                <div className="d-flex justify-content-end">
                    <Pivot
                        style={{ overflow: 'hidden' }}
                        onLinkClick={({ props }) => this.selectMicroteam(props.itemKey)}
                        selectedKey={activeMicroteam ? activeMicroteam.microteamid : ''}
                        overflowBehavior="menu"
                        styles={{ link: { height: 30 } }}
                    >
                        {
                            items.map(x => (
                                <PivotItem
                                    key={x.id}
                                    itemKey={x.id}
                                    headerText={x.name}
                                />
                            ))
                        }
                    </Pivot>
                    <PermissionControllerRender for="CREATE" skip={activeDepartment?.isDelegate}>
                        <div className="ml-1">
                            <PrimaryButton
                                text='New Microteam'
                                onClick={() => this.setState({ showNewMicroteam: true })}
                                iconProps={{ iconName: 'Add' }}
                                allowDisabledFocus
                            />
                        </div>
                    </PermissionControllerRender>
                </div>
            </>
        )
    }
}


const mapStateToProps = (state) => ({
    user: state.session.user,
    tableData: state.mydepartments.data,
    tablePagination: state.mydepartments.pagination,
    activeDepartment: state.session.activeDepartment,
    activeMicroteam: state.session.activeMicroteam
})
const mapDispatchToProps = (dispatch) => ({
    setUser: user => dispatch(setUser(user)),
    setActiveMicroteam: id => dispatch(setActiveMicroteam(id)),
    setMyDepartmentsData: data => dispatch(setMyDepartmentsData(data)),
    setMyDepartmentsPagination: (size, skip) => dispatch(setMyDepartmentsPagination(size, skip)),
    setMyDepartmentsLoading: isLoading => dispatch(setMyDepartmentsLoading(isLoading)),
    setMyDepartmentsSearchText: text => dispatch(setMyDepartmentsSearchText(text)),
    setMyDepartmentsFilters: filters => dispatch(setMyDepartmentsFilters(filters))
})

export default connect(mapStateToProps, mapDispatchToProps)(MicroteamsSection);