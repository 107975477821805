import React from 'react';
import { connect } from 'react-redux';
import Status from "../Status/Status";
import { addNotification } from '../../../store/actions';
import { validatePermission } from '../../../utils/auth';
import apiService from '../../../services/api-service';

class GridABStatusCell extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            defaultValue: props.rowDetail.value || ''
        }
    }

    setABStatus(jobOrderId, status) {
        const { rowDetail } = this.props;

        const rowNode = rowDetail.api.getRowNode(jobOrderId);
        rowNode.data.allocation_board_status = status;
        rowNode.setData(rowNode.data);
    }

    updateABStatus(status) {
        const { defaultValue } = this.state;
        const { rowDetail } = this.props;

        const jobOrderId = rowDetail.data.pafo_joborderid;

        this.setABStatus(jobOrderId, status);

        apiService
            .updateJobOrderABStatus(jobOrderId, status)
            .then(data => {
                if (data !== true) {
                    this.setABStatus(jobOrderId, defaultValue);
                    this.props.addNotification({ type: 'error', text: 'Something went wrong' });
                    return;
                }

                this.setState({ defaultValue: status });

                const filtersForFilters = this.props.getFilters();
                apiService.getJobOrderFilters(filtersForFilters)
                    .then(data => {
                        this.props.setFilterOptions(data);
                        this.props.addNotification({ type: 'success', text: 'AB Status updated' });
                    });
            })
    }

    render() {
        const { rowDetail, isDelegate, user } = this.props;

        return <Status
            hideAllStatusOption={true}
            status={rowDetail.value || ''}
            readOnly={isDelegate ? false : !validatePermission('CHANGE_STATUS', user.roleName)}
            itemsType="ALLOCATION_BOARD"
            onChange={status => this.updateABStatus(status)}
        />
    }
};

const mapStateToProps = state => ({
    user: state.session.user,
})

const mapDispatchToProps = {
    addNotification
}

export default connect(mapStateToProps, mapDispatchToProps)(GridABStatusCell);
