import * as React from 'react';
import { connect } from 'react-redux';
import PeopleList from '../PeopleList/PeopleList';
import { CandidateStatusColors } from '../../../constants/constants'
import NoData from '../NoData/NoData';

export const getActivityPersonas = function (candidates) {
    const activityPersonas = [];

    for (const x of candidates) {
        if (x === null)
            continue;

        const initials = (x.firstname ? x.firstname[0] : '') + (x.lastname ? x.lastname[0] : '');
        const name = `${x.firstname || ''} ${x.lastname || ''}`;

        activityPersonas.push({
            initialsColor: CandidateStatusColors[x.pafo_stagename] || "black",
            imageInitials: initials,
            text: name,
            additional: x.pafo_stagename
        });
    }

    return activityPersonas;
}

class CandidatesDetail extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            activityPersonas: []
        };
    }

    componentDidMount() {
        const { rowDetail } = this.props;

        const candidates = rowDetail.data.candidates || [];
        const activityPersonas = getActivityPersonas(candidates);

        this.setState({ activityPersonas: activityPersonas });
    }



    render() {
        const { activityPersonas } = this.state;

        return (
            <section>
                {
                    !!activityPersonas?.length
                        ? <PeopleList
                            data={activityPersonas}
                            maxHeight='100%'
                        />
                        : <NoData />
                }
            </section>
        )
    }
}

const mapStateToProps = state => ({
    user: state.session.user
})

export default connect(mapStateToProps)(CandidatesDetail)