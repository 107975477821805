import React, { createRef } from 'react';
import { DefaultButton, PrimaryButton, Spinner, TextField, Panel, PanelType } from '@fluentui/react';
import { connect, batch } from 'react-redux';
import { setActiveMicroteam, setUser, setActiveDepartment } from '../../../../../store/actions';
import { AutoCompleteFullScreen } from '../../../../shared/AutoCompleteFullScreen/AutoCompleteFullScreen';
import { getCancelTokenSource } from '../../../../../api';
import apiService from '../../../../../services/api-service';

class NewMicroteam extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            jobOrders: null,
            loading: false,
            errorFetchingJobOrders: false,
            mtTitle: '',
            jobSelectedName: null,
            joSelected: []
        }
        this.source = createRef();
    }


    componentWillUnmount() {
        this.source.current?.cancel?.();
    }

    componentDidMount() {
        const { activeDepartment } = this.props;

        this.source.current?.cancel?.();
        this.source.current = getCancelTokenSource();
        
        apiService.getJobOrdersNotAssigned(activeDepartment.kf_financialhierarchyid, this.source.current.token)
            .then(data => {
                const jobOrders = data.results.map(d => ({
                    key: d.pafo_joborderid,
                    text: `[${d.trm_status}] ${d.trm_jobtitle} - ${d.trm_recruitmaxid} - ${activeDepartment.departmentName} - ${d.trm_worksitestate} ${d.trm_worksitecity}`
                }));
                this.setState({ jobOrders: jobOrders });
            })
            .catch(e => {
                console.error(e);
                this.setState({ jobOrders: [], errorFetchingJobOrders: true });
            });
    }

    createMicroteam() {
        const { activeDepartment, user, setUser, setActiveDepartment } = this.props;
        const { mtTitle, joSelected } = this.state;

        this.setState({ loading: true });

        const jobOrderIds = joSelected.map(e => e.key);

        apiService.addMicroteam(mtTitle, activeDepartment.kf_financialhierarchyid, user.oid, jobOrderIds)
            .then(data => {
                if (!data || !data.microteamid) {
                    return;
                }

                user.officeLeaderDepartmentList
                    .filter(x => x.kf_financialhierarchyid === data.departmentid)[0]
                    .microteams.push({
                        microteamid: data.microteamid,
                        microteamname: data.microteamname
                    });

                batch(() => {
                    setUser(user);
                    setActiveDepartment(data.departmentid);
                    setActiveMicroteam(data.microteamid);
                });

                this.props.onSelectMicroteam(data.microteamid);
            })
            .finally(() => {
                this.setState({ loading: false });
                this.props.onClose();
            });
    }

    render() {
        const { isOpen, onClose } = this.props;
        const { jobOrders, mtTitle, loading, errorFetchingJobOrders } = this.state;

        return (
            <Panel
                headerText="New Microteam"
                type={PanelType.medium}
                isOpen={isOpen}
                onDismiss={onClose}
                isFooterAtBottom
                // isBlocking={false}
                onRenderFooterContent={() =>
                    <div className="col-12 p-0 d-flex mt-5 justify-content-end">
                        {
                            loading ?
                                <Spinner
                                    className="mr-3"
                                    label="Creating Microteam"
                                    ariaLive="assertive"
                                    labelPosition="right"
                                />
                                :
                                <DefaultButton
                                    className="mr-2"
                                    onClick={onClose}
                                >Cancel</DefaultButton>
                        }
                        <PrimaryButton
                            onClick={() => this.createMicroteam()}
                            disabled={(mtTitle.trim() === '') || loading}
                        >Create</PrimaryButton>
                    </div>
                }
            >
                <div className="col-12 row mt-4 p-0 px-4 d-flex align-items-end">
                    <div className="col-12 p-0">
                        <TextField
                            label="Name"
                            placeholder="Enter a microteam name"
                            value={mtTitle}
                            onChange={d => this.setState({ mtTitle: d.target.value })}
                        />

                        {
                            jobOrders ?
                                errorFetchingJobOrders ?
                                    <div>Something went wrong, job orders were not loaded.</div>
                                    :
                                    <div>
                                        <AutoCompleteFullScreen
                                            // className="my-1"
                                            placeholder="Select"
                                            options={jobOrders}
                                            label="Job Orders"
                                            onSelectItem={(i) => this.setState({ joSelected: i })}
                                        />
                                    </div>
                                :
                                <div className="mt-4">
                                    <Spinner
                                        ariaLive="assertive"
                                        labelPosition="right"
                                        label="Fetching job orders not assigned"
                                    />
                                </div>
                        }
                    </div>
                </div>
            </Panel >
        )
    }
};

const mapStateToProps = state => ({
    user: state.session.user,
    activeDepartment: state.session.activeDepartment
})
const mapDispatchToProps = dispatch => ({
    setUser: user => dispatch(setUser(user)),
    setActiveDepartment: id => dispatch(setActiveDepartment(id)),
    setActiveMicroteam: id => dispatch(setActiveMicroteam(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(NewMicroteam)