
import React, { createRef } from 'react';
import { connect, batch } from 'react-redux';
import {
    setConsolidatedLoading,
    setConsolidatedData,
    setConsolidatedPagination,
    setConsolidatedColumns,
    setConsolidatedFilters,
    setConsolidatedSort,
    setUser
} from '../../../../../store/actions';
import apiService from '../../../../../services/api-service';
import { getCancelTokenSource } from '../../../../../api';
import consolidatedService from '../../../../../services/consolidated-service';
import { Page } from '../../../../../constants/constants';
import GridColumnHeader from '../../../../shared/GridColumnHeader/GridColumnHeader';

class ColumnHeader extends React.Component {

    constructor(props) {
        super(props);
        this.applyPageFilters = this.applyPageFilters.bind(this);
        this.refreshTableData = this.refreshTableData.bind(this);
        this.source = createRef();
    }

    componentWillUnmount() {
        this.source.current?.cancel?.();
    }

    applyPageFilters(filters) {
        this.props.setConsolidatedFilters(filters);
    }

    refreshTableData(orderBy, tableFilters, resetPagination) {
        const { user, tableSearchText, tablePagination, isDelegate } = this.props;

        const filterGroup = consolidatedService.getGroupId(user, isDelegate);
        const userFilters = user.settings.consolidatedOrdersPage.filter.filters.filter(x => x.group === filterGroup);

        const filters = [
            ...consolidatedService.createBaseFilters(user, [...userFilters, ...tableFilters], isDelegate),
            ...consolidatedService.mapFiltersToAPI(userFilters)
        ];

        if (tableFilters && tableFilters.length > 0) {
            filters.push(...tableFilters);
        }

        const skip = resetPagination
            ? 0
            : tablePagination.skip;

        batch(() => {
            this.props.setConsolidatedLoading(true);
            this.props.setConsolidatedFilters(tableFilters);
            if (resetPagination) {
                this.props.setConsolidatedPagination(tablePagination.size, skip);
            }
        })

        this.source.current?.cancel?.();
        this.source.current = getCancelTokenSource();

        apiService
            .getJobOrders(filters, tablePagination.size, skip, tableSearchText, orderBy, this.source.current.token)
            .then(data => {
                batch(() => {
                    this.props.setConsolidatedData(data);
                    this.props.setConsolidatedLoading(false);
                })
            });
    }

    render() {
        const { column, displayName, sort, tableFilters, filterOptions } = this.props;

        return (
            <GridColumnHeader
                column={column}
                filterOptions={filterOptions}
                displayName={displayName}
                userSort={sort}
                tableFilters={tableFilters}
                settingsPage={Page.consolidatedOrders}
                onRefreshTableData={this.refreshTableData}
                onApplyPageFilters={this.applyPageFilters}
            />
        )
    }
}

const mapStatetoProps = state => ({
    user: state.session.user,
    sort: state.session.user.settings.consolidatedOrdersPage.sort,
    tableSearchText: state.consolidated.searchText,
    tablePagination: state.consolidated.pagination,
    tableFilters: state.consolidated.filters,
    filterOptions: state.consolidated.filterOptions,
    tableSort: state.consolidated.sort,
    isDelegate: state.consolidated.isDelegate
})

const mapDispatchToProps = (dispatch) => ({
    setUser: user => dispatch(setUser(user)),
    setConsolidatedLoading: isLoading => dispatch(setConsolidatedLoading(isLoading)),
    setConsolidatedData: data => dispatch(setConsolidatedData(data)),
    setConsolidatedPagination: (size, skip) => dispatch(setConsolidatedPagination(size, skip)),
    setConsolidatedColumns: columns => dispatch(setConsolidatedColumns(columns)),
    setConsolidatedFilters: filters => dispatch(setConsolidatedFilters(filters)),
    setConsolidatedSort: (columnName, sortOrder) => dispatch(setConsolidatedSort(columnName, sortOrder))
})

export default connect(mapStatetoProps, mapDispatchToProps)(ColumnHeader);