import React from 'react';
import { connect } from 'react-redux';
import { Spinner } from '@fluentui/react';
import PaginatedTable from './PaginatedTable';
import { addNotification, setUser } from '../../../store/actions';
import apiService from '../../../services/api-service';
import { Field } from '../../../constants/constants';

class JobOrdersTable extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedRow: null,
            lastSelectedRow: null
        }
    }

    componentDidMount() {
        const columnsToShow = this.getInitialColumns();
        this.props.setColumns(columnsToShow);
    }

    getInitialColumns() {
        const { user, page, columnsDef } = this.props;

        const userColumns = user.settings[page + "Page"].columns;
        let columnsToShow = columnsDef;

        if (userColumns && (userColumns.length > 0)) {
            columnsToShow = columnsDef.filter(rc => {
                if (!rc.optional || !rc.optional.hideable) {
                    return rc;
                }
                const userColumn = userColumns.filter(userC => ((rc.headerName === userC.columnName) || (rc.field === userC.columnName)))[0];
                if (userColumn) {
                    if (userColumn.width) {
                        rc.width = userColumn.width
                    }
                    return rc;
                }

                return undefined;
            });
        }

        return columnsToShow;
    }

    refreshTableData(size, skip) {
        this.setState({ selectedRow: null });
        this.props.refresh(size, skip);
    }

    onCellValueChanged(cellData) {
        if (!cellData || cellData.column.colId !== Field.allocation_board_priority) {
            return;
        }

        const { data } = cellData;

        apiService.assignJobOrderPriority(data.pafo_joborderid, data.allocation_board_priority)
            .then(_ => {
                this.props.addNotification({ type: 'success', text: 'Priority changed' });
            });
    }

    onRowClicked(params) {
        const isMore = params.event?.target?.dataset?.iconName === "More";
        const isNotes = params.event?.target?.dataset?.iconName === "DietPlanNotebook";
        if (!isMore) {
            this.props.setRowDetail(params);
        }
        const selectedNodes = params.api.selectionService.selectedNodes;
        const selectedNode = selectedNodes[params.node.id];

        params.node.setSelected(isMore || isNotes || !selectedNode, !isMore);
    }

    onRowDoubleClicked(params) {
        this.props.onRowDoubleClicked?.(params);
        params.node.setSelected(true);
        // this.setState((state) => ({
        //     selectedRow: params,
        //     lastSelectedRow: state.selectedRow
        // }), () => {
        //     const selectedRow = this.state.selectedRow;
        //     const lastSelectedRow = this.state.lastSelectedRow;
        //     const rowNodes = [];
        //     if (selectedRow) {
        //         rowNodes.push(selectedRow.node);
        //     }
        //     if (lastSelectedRow) {
        //         rowNodes.push(lastSelectedRow.node);
        //     }
        //     this.props.onRowDoubleClicked?.(params);
        //     params.api.redrawRows(rowNodes.length > 0 ? { rowNodes: rowNodes } : undefined);
        // });
    }

    onColumnResized(params) {
        if (!params.finished || params.source !== "uiColumnDragged") {
            return;
        }

        const { user, page } = this.props;

        user.settings[page + "Page"].columns.forEach(c => {
            const column = params.columns.filter(x => [x.colDef.headerTooltip, x.colDef.headerName, x.colDef.field].includes(c.columnName))[0];
            if (column) {
                c.width = column.actualWidth && !isNaN(column.actualWidth)
                    ? Math.round(column.actualWidth)
                    : column.actualWidth;
            }
        });

        this.props.setUser(user);

        apiService
            .setUserColumns(user.oid, page, user.settings[page + "Page"].columns)
            .then(data => {
                // IF FAIL... ROLLBACK ?
            });
    }

    render() {
        const {
            context,
            fetching,
            columnHeaderComponent,
            data,
            pagination,
            columns,
        } = this.props;

        return (
            <>
                {
                    fetching ?
                        <div className="mt-3">
                            <Spinner label="Fetching job orders..." ariaLive="assertive" labelPosition="right" />
                        </div>
                        :
                        <PaginatedTable
                            context={context}
                            getRowNodeId={rowData => rowData.pafo_joborderid}
                            frameworkComponents={{ agColumnHeader: columnHeaderComponent }}
                            columns={columns}
                            data={data}
                            perPage={pagination.size}
                            currentPage={pagination.skip === 0 ? 0 : pagination.skip / pagination.size}
                            onChangePage={(size, skip) => this.refreshTableData(size, skip)}
                            onChangePerPage={(size) => this.refreshTableData(size, 0)}
                            onSelectionChanged={data => this.props.setSelectedRows(data)}
                            onCellValueChanged={data => this.onCellValueChanged(data)}
                            onRowClicked={data => this.onRowClicked(data)}
                            onRowDoubleClicked={data => this.onRowDoubleClicked(data)}
                            onColumnResized={data => this.onColumnResized(data)}
                            rowClassRules={{
                                'is-exclusive': function (params) {
                                    var isExclusive = params.data.job_order_is_exclusive;
                                    return isExclusive === true || isExclusive === 'true' || isExclusive === 'True';
                                },
                                'is-hotlist': function (params) {
                                    var isHotlist = params.data.job_order_is_hotlist_filterable;
                                    return isHotlist === true || isHotlist === 'true' || isHotlist === 'True';
                                },
                                'clicked-row': (params) => {
                                    return ((p, s) => {
                                        if (!s.selectedRow) {
                                            return false;
                                        }
                                        return p.data.pafo_joborderid === s.selectedRow.data.pafo_joborderid;
                                    })(params, this.state);
                                }
                            }}
                        />
                }
            </>
        )
    }
}


const mapStateToProps = (state) => ({
    user: state.session.user
})
const mapDispatchToProps = {
    addNotification,
    setUser
}

export default connect(mapStateToProps, mapDispatchToProps)(JobOrdersTable)