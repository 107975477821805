import React from 'react';
import { connect } from 'react-redux';
import { IconButton } from '@fluentui/react';
import { setMyDepartmentsShowNotes } from '../../../../../store/actions';
class NotesCell extends React.Component {

    render() {
        const { setMyDepartmentsShowNotes, showNotes } = this.props;

        return (
            <IconButton
                iconProps={{ iconName: 'DietPlanNotebook' }}
                onClick={() => setMyDepartmentsShowNotes(!showNotes) }
            />
        )
    }
};

const mapStateToProps = state => ({
    showNotes: state.mydepartments.showNotes
})


const mapDispatchToProps = (dispatch, props) => ({
    setMyDepartmentsShowNotes: (i) => dispatch(setMyDepartmentsShowNotes(i))
})


export default connect(mapStateToProps, mapDispatchToProps)(NotesCell);
