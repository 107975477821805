import React from 'react';
import { ActivityItem, HoverCard, HoverCardType, IconButton } from '@fluentui/react';
import RecruiterList from '../RecruiterList/RecruiterList';

class GridRecruitersCell extends React.Component {

    renderCompactCard(activityPersonas) {
        return <RecruiterList data={activityPersonas} maxHeight="100%" />
    }

    renderEditButton() {
        const { onClickEdit } = this.props;
        return <>
            <IconButton
                iconProps={{ iconName: 'AddFriend' }}
                onClick={onClickEdit || (() => console.log("Edit recruiters"))}
            />
        </>

    }

    render() {
        const { recruiters, showEdit } = this.props;

        const editRecruitersButton = showEdit
            ? this.renderEditButton()
            : null;

        if (!recruiters || !Array.isArray(recruiters) || recruiters.length === 0) {
            return editRecruitersButton;
        }

        let activityPersonas = recruiters.filter(x => x !== null).map(v => ({
            imageInitials: (v.firstname ? v.firstname[0] : '') + (v.lastname ? v.lastname[0] : ''),
            text: `${v.firstname || ''} ${v.lastname || ''}`,
            additional: v.title
        }));

        if (activityPersonas.length === 0) {
            return editRecruitersButton;
        }

        const limit = 3;
        const more = recruiters.length > limit
            ? <div style={{ color: 'gray', marginLeft: '5px', fontSize: '12px' }}>{`+${recruiters.length - limit}`}</div>
            : null;

        const activityPersonasBubbles = activityPersonas.length > limit
            ? activityPersonas.slice(0, limit)
            : activityPersonas;

        return (
            <HoverCard
                instantOpenOnClick={false}
                type={HoverCardType.plain}
                plainCardProps={{ onRenderPlainCard: () => this.renderCompactCard(activityPersonas) }}
            >
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    {editRecruitersButton}
                    <ActivityItem className="h-100" activityPersonas={activityPersonasBubbles} isCompact={true} />
                    {more}
                </div>
            </HoverCard>
        )
    }
};

export default GridRecruitersCell;
