// session
export const TOKEN = 'TOKEN';
export const USER = 'USER';
export const SIDEMENU = 'SIDEMENU';
export const SET_ACTIVE_DEPARTMENT = 'SET_ACTIVE_DEPARTMENT';
export const SET_ACTIVE_MICROTEAM = 'SET_ACTIVE_MICROTEAM';

// system
export const SET_SYSTEM_LOADING = 'SET_SYSTEM_LOADING';

// notification
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';

// consolidated orders
export const SET_CONSOLIDATED_LOADING = 'SET_CONSOLIDATED_LOADING';
export const SET_CONSOLIDATED_SELECTEDROWS = 'SET_CONSOLIDATED_SELECTEDROWS';
export const SET_CONSOLIDATED_ROWDETAIL = 'SET_CONSOLIDATED_ROWDETAIL';
export const SET_CONSOLIDATED_DATA = 'SET_CONSOLIDATED_DATA';
export const SET_CONSOLIDATED_PAGINATION = 'SET_CONSOLIDATED_PAGINATION';
export const SET_CONSOLIDATED_FILTERS = 'SET_CONSOLIDATED_FILTERS';
export const SET_CONSOLIDATED_FILTEROPTIONS = 'SET_CONSOLIDATED_FILTEROPTIONS';
export const SET_CONSOLIDATED_SORT = 'SET_CONSOLIDATED_SORT';
export const SET_CONSOLIDATED_COLUMNS = 'SET_CONSOLIDATED_COLUMNS';
export const SET_CONSOLIDATED_SEARCHTEXT = 'SET_CONSOLIDATED_SEARCHTEXT';
export const SET_CONSOLIDATED_SHOWNOTES = 'SET_CONSOLIDATED_SHOWNOTES';
export const SET_CONSOLIDATED_SHOWRECRUITERS = 'SET_CONSOLIDATED_SHOWRECRUITERS';
export const SET_CONSOLIDATED_ISDELEGATE = 'SET_CONSOLIDATED_ISDELEGATE';

// mydepartments
export const SET_MYDEPARTMENTS_LOADING = 'SET_MYDEPARTMENTS_LOADING';
export const SET_MYDEPARTMENTS_SELECTEDROWS = 'SET_MYDEPARTMENTS_SELECTEDROWS';
export const SET_MYDEPARTMENTS_ROWDETAIL = 'SET_MYDEPARTMENTS_ROWDETAIL';
export const SET_MYDEPARTMENTS_DATA = 'SET_MYDEPARTMENTS_DATA';
export const SET_MYDEPARTMENTS_PAGINATION = 'SET_MYDEPARTMENTS_PAGINATION';
export const SET_MYDEPARTMENTS_FILTERS = 'SET_MYDEPARTMENTS_FILTERS';
export const SET_MYDEPARTMENTS_SORT = 'SET_MYDEPARTMENTS_SORT';
export const SET_MYDEPARTMENTS_FILTEROPTIONS = 'SET_MYDEPARTMENTS_FILTEROPTIONS';
export const SET_MYDEPARTMENTS_COLUMNS = 'SET_MYDEPARTMENTS_COLUMNS';
export const SET_MYDEPARTMENTS_SEARCHTEXT = 'SET_MYDEPARTMENTS_SEARCHTEXT';
export const SET_MYDEPARTMENTS_SHOWNOTES = 'SET_MYDEPARTMENTS_SHOWNOTES';
export const SET_MYDEPARTMENTS_SHOWRECRUITERS = 'SET_MYDEPARTMENTS_SHOWRECRUITERS';

// allorders
export const SET_ALLORDERS_LOADING = 'SET_ALLORDERS_LOADING';
export const SET_ALLORDERS_SELECTEDROWS = 'SET_ALLORDERS_SELECTEDROWS';
export const SET_ALLORDERS_ROWDETAIL = 'SET_ALLORDERS_ROWDETAIL';
export const SET_ALLORDERS_DATA = 'SET_ALLORDERS_DATA';
export const SET_ALLORDERS_PAGINATION = 'SET_ALLORDERS_PAGINATION';
export const SET_ALLORDERS_FILTERS = 'SET_ALLORDERS_FILTERS';
export const SET_ALLORDERS_FILTEROPTIONS = 'SET_ALLORDERS_FILTEROPTIONS';
export const SET_ALLORDERS_COLUMNS = 'SET_ALLORDERS_COLUMNS';
export const SET_ALLORDERS_SEARCHTEXT = 'SET_ALLORDERS_SEARCHTEXT';
export const SET_ALLORDERS_SHOWNOTES = 'SET_ALLORDERS_SHOWNOTES';
export const SET_ALLORDERS_FIRMS = 'SET_ALLORDERS_FIRMS';
export const SET_ALLORDERS_SELECTEDFIRM = 'SET_ALLORDERS_SELECTEDFIRM';
export const SET_ALLORDERS_REGIONS = 'SET_ALLORDERS_REGIONS';
export const SET_ALLORDERS_SELECTEDREGION = 'SET_ALLORDERS_SELECTEDREGION';
export const SET_ALLORDERS_MARKETS = 'SET_ALLORDERS_MARKETS';
export const SET_ALLORDERS_SELECTEDMARKET = 'SET_ALLORDERS_SELECTEDMARKET';
export const SET_ALLORDERS_OFFICES = 'SET_ALLORDERS_OFFICES';
export const SET_ALLORDERS_SELECTEDOFFICE = 'SET_ALLORDERS_SELECTEDOFFICE';
export const SET_ALLORDERS_DEPARTMENTS = 'SET_ALLORDERS_DEPARTMENTS';
export const SET_ALLORDERS_SELECTEDDEPARTMENT = 'SET_ALLORDERS_SELECTEDDEPARTMENT';
export const SET_ALLORDERS_HIERARCHY = 'SET_ALLORDERS_HIERARCHY';
export const SET_ALLORDERS_BREADCRUMBS = 'SET_ALLORDERS_BREADCRUMBS';

// security
export const SET_SECURITY_LOADING = 'SET_SECURITY_LOADING';
export const SET_SECURITY_ROWDETAIL = 'SET_SECURITY_ROWDETAIL';
export const SET_SECURITY_DATA = 'SET_SECURITY_DATA';
export const SET_SECURITY_PAGINATION = 'SET_SECURITY_PAGINATION';
export const SET_SECURITY_FILTERS = 'SET_SECURITY_FILTERS';
export const SET_SECURITY_FILTEROPTIONS = 'SET_SECURITY_FILTEROPTIONS';
export const SET_SECURITY_COLUMNS = 'SET_SECURITY_COLUMNS';
export const SET_SECURITY_SEARCHTEXT = 'SET_SECURITY_SEARCHTEXT';
export const SET_SECURITY_SORT = 'SET_SECURITY_SORT';
export const SET_SECURITY_SHOWFORM = 'SET_SECURITY_SHOWFORM';
