import React, { createRef } from 'react';
import { connect, batch } from 'react-redux';
import {
    setAllOrdersData,
    setAllOrdersPagination,
    setAllOrdersSearchText,
    setAllOrdersLoading,
    setAllOrdersFilterOptions,
    setAllOrdersFilters,
    setUser
} from '../../../../store/actions';
import { ActionButton, IconButton } from '@fluentui/react';
import { SearchBox } from '@fluentui/react/lib/SearchBox';
import Status from '../../../shared/Status/Status';
import apiService from '../../../../services/api-service';
import { getCancelTokenSource } from '../../../../api';
import allOrdersService from '../../../../services/allorders-service';
import { TextFilterAction, Page, Hierarchy } from '../../../../constants/constants';
import ExportCsvModal from '../../../shared/ExportCsvModal/ExportCsvModal';
import GridFilters from '../../../shared/GridFilters/GridFilters';
import GridDisplayFilters from '../../../shared/GridDisplayFilters/GridDisplayFilters';
import { columnsDef } from '../TableSection/Columns'

class OptionsSection extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showFilters: false,
            showExportCsv: false,
            selectedFilterOptions: []
        }
        this.source = createRef();
    }

    componentWillUnmount() {
        this.source.current?.cancel?.();
    }

    search(textValue) {
        const { pagination, setAllOrdersSearchText } = this.props;
        setAllOrdersSearchText(textValue);
        this.refreshTableData(pagination.size, 0, textValue, this.getDefaultFilters());
    }

    getDefaultFilters() {
        const { user, hierarchy } = this.props;

        const groupId = allOrdersService.getGroupId(hierarchy);
        const defaultFilters = allOrdersService.mapFiltersToAPI(user.settings.allOrdersPage.filter.filters.filter(x => x.group === groupId));

        if (hierarchy.by === Hierarchy.department) {
            defaultFilters.push(allOrdersService.createMandatoryFilter(hierarchy.name))
        }
        return defaultFilters;
    }

    getFilters(filters) {
        const { tableFilters } = this.props;

        if (tableFilters && tableFilters.length > 0) {
            filters.push(...tableFilters);
        }

        return filters;
    }

    getOrderBy() {
        const { user } = this.props;
        return allOrdersService.createGroupBy(user.settings.allOrdersPage.sort);
    }

    refreshTableData(size, skip, textValue = '', baseFilters = []) {
        const { hierarchy } = this.props;

        const filters = this.getFilters(baseFilters);
        const orderBy = this.getOrderBy();

        this.props.setAllOrdersLoading(true);

        this.source.current?.cancel?.();
        this.source.current = getCancelTokenSource();

        if ([Hierarchy.department, Hierarchy.firm].includes(hierarchy.by)) {
            apiService
                .getJobOrders(filters, size, skip, textValue, orderBy, this.source.current.token)
                .then(data => {
                    batch(() => {
                        this.props.setAllOrdersData(data);
                        this.props.setAllOrdersPagination(size, skip);
                        this.props.setAllOrdersLoading(false);
                    })
                });
        } else {
            apiService
                .getJobOrdersByHierarchy(hierarchy.by, hierarchy.name, filters, size, skip, textValue, orderBy, this.source.current.token)
                .then(data => {
                    batch(() => {
                        this.props.setAllOrdersData(data);
                        this.props.setAllOrdersPagination(size, skip);
                        this.props.setAllOrdersLoading(false);
                    })
                });
        }
    }

    setTrmStatusFilter(status) {
        const { user, setUser, pagination, searchText, hierarchy } = this.props;

        const groupId = allOrdersService.getGroupId(hierarchy);

        let userFilters = user.settings.allOrdersPage.filter.filters.filter(x => x.group !== groupId);
        let groupFilters = user.settings.allOrdersPage.filter.filters.filter(x => x.columnName !== "trm_status" && x.group === groupId);
        if (status !== "All Status") {
            groupFilters.push({
                columnName: "trm_status",
                action: TextFilterAction.Equals.key,
                value: status,
                group: groupId
            });
        }

        user.settings.allOrdersPage.filter.filters = [...userFilters, ...groupFilters];
        setUser(user);

        apiService.setUserFilters(user.oid, Page.allOrders, user.settings.allOrdersPage.filter.filters);

        const statusFilters = allOrdersService.mapFiltersToAPI(groupFilters);
        if (hierarchy.by === Hierarchy.department) {
            statusFilters.push(allOrdersService.createMandatoryFilter(hierarchy.name))
        }

        this.refreshTableData(pagination.size, 0, searchText, statusFilters);
    }

    getTrmStatusFilter() {
        const { user, hierarchy } = this.props;

        const groupId = allOrdersService.getGroupId(hierarchy);

        const filters = user.settings.allOrdersPage.filter.filters;
        if (filters && filters.length > 0) {
            const trm_statuses = filters.filter(x => x.columnName === "trm_status" && x.group === groupId);
            if (trm_statuses.length > 0) {
                return trm_statuses[0].value;
            }
        }
        return "All Status";
    }

    refresh(resetSkip) {
        const { pagination, searchText } = this.props;

        const skip = resetSkip ? 0 : pagination.skip;
        this.refreshTableData(pagination.size, skip, searchText, this.getDefaultFilters())
        this.refreshFilterOptions();
    }

    refreshFilterOptions() {
        const { user, hierarchy } = this.props;
        const filterGroup = allOrdersService.getGroupId(hierarchy);
        const userFilters = user.settings.allOrdersPage.filter.filters.filter(x => x.group === filterGroup);
        this.setState({ selectedFilterOptions: userFilters });
    }

    applyFilter() {
        const { selectedFilterOptions } = this.state;
        const { pagination, searchText, hierarchy, tableFilters, user } = this.props;

        const filters = allOrdersService.mapFiltersToAPI(selectedFilterOptions);

        if (tableFilters && tableFilters.length > 0) {
            filters.push(...tableFilters);
        }

        const filterGroup = allOrdersService.getGroupId(hierarchy);
        const saveFilters = user.settings.allOrdersPage.filter.filters.filter(x => x.group !== filterGroup);
        saveFilters.push(...selectedFilterOptions);

        apiService.setUserFilters(user.oid, Page.allOrders, saveFilters)
            .then(data => {
                // IF FAIL... ROLLBACK ?
            });

        user.settings.allOrdersPage.filter.filters = saveFilters;
        const orderBy = allOrdersService.createGroupBy(user.settings.allOrdersPage.sort);

        this.props.setAllOrdersLoading(true);

        if ([Hierarchy.department, Hierarchy.firm].includes(hierarchy.by)) {
            if (hierarchy.by === Hierarchy.department) {
                filters.push(allOrdersService.createMandatoryFilter(hierarchy.name));
            }
            apiService
                .getJobOrders(filters, pagination.size, 0, searchText, orderBy)
                .then(data => {
                    batch(() => {
                        this.props.setAllOrdersData(data);
                        this.props.setAllOrdersPagination(pagination.size, 0);
                        this.props.setAllOrdersLoading(false);
                        this.props.setUser(user);
                    });
                })
        } else {
            apiService
                .getJobOrdersByHierarchy(hierarchy.by, hierarchy.name, filters, pagination.size, 0, searchText, orderBy)
                .then(data => {
                    batch(() => {
                        this.props.setAllOrdersData(data);
                        this.props.setAllOrdersPagination(pagination.size, 0);
                        this.props.setAllOrdersLoading(false);
                        this.props.setUser(user);
                    });
                })
        }
    }

    clearAllFilters() {
        const { user } = this.props;
        const emptyFilters = [];

        user.settings.allOrdersPage.filter.filters = emptyFilters;
        this.props.setAllOrdersFilters(emptyFilters);

        window.setTimeout(() => this.applyFilter(), 100);
    }

    render() {
        const { searchText, filterOptions, hierarchy, userFilters, tableFilters, tableData, showData, showNotes } = this.props;
        const { showFilters, showExportCsv } = this.state;

        if (!showData) {
            const hasFilters = !!userFilters?.length || !!tableFilters?.length;
            return (
                <div className="pt-5">
                    <h6>
                        Please use the general filters to search orders.
                    </h6>
                    {
                        hasFilters &&
                        <div className='bg-light mt-3'>
                            <div className='mb-2'>
                                If you don't get results <span className='text-danger font-weight-bold'>after searching with the general filters</span>, try clearing filters:
                            </div>
                            <ActionButton
                                text="Clear All Filters"
                                onClick={() => this.clearAllFilters()}
                                style={{ height: 'auto', paddingLeft: 0, marginLeft: 0 }}
                                iconProps={{ iconName: 'ClearFilter', styles: { root: { paddingLeft: 0, marginLeft: 0 } } }}
                            />
                        </div>
                    }
                </div>
            )
        }

        const status = this.getTrmStatusFilter();
        const groupId = allOrdersService.getGroupId(hierarchy);
        
        return (
            <>
                <div className="mb-2">
                    <div className="d-sm-block d-md-flex justify-content-between align-items-center order--filter mt-3">
                        <div className="d-flex col-6 p-0 align-items-center">
                            <SearchBox
                                placeholder="Search Orders"
                                className="col-5 mr-2"
                                onClear={() => this.search('')}
                                onSearch={nv => this.search(nv)}
                            />
                            <Status
                                readOnly={false}
                                onChange={(status) => this.setTrmStatusFilter(status)}
                                status={status}
                                itemsType="TRM"
                            />
                            <IconButton
                                title="Filters"
                                className="ml-1"
                                disabled={showNotes}
                                onClick={() => this.setState({ showFilters: !showFilters })}
                                iconProps={{ iconName: 'Filter' }}
                            />
                            <IconButton
                                title="Refresh Data"
                                onClick={() => this.refresh()}
                                iconProps={{ iconName: 'Refresh' }}
                            />
                            <IconButton
                                title="Export to CSV"
                                disabled={!tableData?.results?.length || showNotes}
                                onClick={() => this.setState({ showExportCsv: true })}
                                iconProps={{ iconName: 'DownloadDocument' }}
                            />
                        </div>
                    </div>
                </div>

                <GridDisplayFilters
                    userFilters={this.props.userFilters}
                    tableFilters={this.props.tableFilters}
                    filterOptions={this.props.filterOptions}
                    columns={columnsDef}
                    page={Page.allOrders}
                    groupId={groupId}
                    onChange={filters => this.props.setAllOrdersFilters(filters)}
                    onRefresh={() => this.refresh(true)}
                />

                <GridFilters
                    loading={this.props.loading}
                    isOpen={showFilters}
                    filterOptions={filterOptions}
                    selectedFilterOptions={this.state.selectedFilterOptions}
                    onOpen={() => this.refreshFilterOptions()}
                    onClose={() => this.setState({ showFilters: false })}
                    onUpdate={filterOptions => this.setState({ selectedFilterOptions: filterOptions })}
                    onApply={() => this.applyFilter()}
                    groupId={groupId}
                />

                <ExportCsvModal
                    page={Page.allOrders}
                    isOpen={showExportCsv}
                    onDismiss={() => this.setState({ showExportCsv: false })}
                    filters={this.getFilters(this.getDefaultFilters())}
                    text={searchText}
                    orderBy={this.getOrderBy()}
                    columnsDef={columnsDef}
                />
            </>
        )
    }
}

const mapStateToProps = state => ({
    loading: state.allorders.loading,
    pagination: state.allorders.pagination,
    searchText: state.allorders.searchText,
    tableData: state.allorders.data,
    tableFilters: state.allorders.filters,
    filterOptions: state.allorders.filterOptions,
    showNotes: state.allorders.showNotes,
    hierarchy: state.allorders.hierarchy,
    user: state.session.user,
    userFilters: state.session.user.settings.allOrdersPage.filter.filters
});

const mapDispatchToProps = {
    setAllOrdersData,
    setAllOrdersPagination,
    setAllOrdersLoading,
    setAllOrdersSearchText,
    setAllOrdersFilterOptions,
    setAllOrdersFilters,
    setUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(OptionsSection)