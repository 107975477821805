import PeopleList from "../../../shared/PeopleList/PeopleList";
import EditCell from './EditCell/EditCell';

export const columnsDef = [
    {
        optional: { sortable: true },
        headerName: "Name",
        headerTooltip: "Name",
        minWidth: 150,
        field: "fullname",
        sortable: true,
        filter: true,
        cellRendererFramework: params => {
            return (
                <PeopleList
                    randomInitialsColor={true}
                    data={[{
                        id: params.data.id,
                        description: params.value,
                        name: params.value && params.value.length > 1 ? params.value.slice(0, 2).toUpperCase() : params.value,
                        thumbnail: ''
                    }]}
                />
            )
        }
    },
    {
        optional: { sortable: true },
        headerName: "Email",
        headerTooltip: "Email",
        minWidth: 300,
        field: "email",
        sortable: true,
    },
    {
        optional: { sortable: false },
        headerName: "Role",
        headerTooltip: "Role",
        minWidth: 100,
        field: "rolename",
        sortable: true,
    },
    {
        headerName: "Departments",
        headerTooltip: "Departments",
        minWidth: 100,
        field: "departments",
        sortable: true,
        filter: false,
        cellRendererFramework: params => {
            const value = params.data.departments || params.value || [];
            return <div style={{ overflowX: 'overlay' }}>
                {
                    value.map((x, i) => <span key={`${i}-${x.kf_financialhierarchyid}`}> • {x.departmentName}</span>)
                }
            </div>
        }
    },
    {
        headerName: "",
        width: 100,
        minWidth: 100,
        maxWidth: 100,
        sortable: false,
        filter: false,
        resizable: false,
        tooltipField: 'Edit',
        tooltipComponentParams: { color: '#ececec' },
        cellRendererFramework: params => {
            return <EditCell />
        }
    },
]//.map((row, i) => ({ order: i, ...row }))