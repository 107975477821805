import React from 'react';
import './errorPage.css';
function ErrorPage() {

    return (
        <div className="col-12 d-flex justify-content-center p-0 mt-5">
            <div className="col-sm-9 col-md-9">
                <div className="box-error-page">
                    <h1>401</h1>
                    <h2>User unauthorized</h2>
                    <hr />
                    <p>The page you are looking for denied access due to lack of permissions</p>
                    <p>Please try the following:</p>
                    <ul>
                        <li>If you are sure you have permission, please <strong>refresh</strong> the page.</li>
                        <li>If you wish to have access to the site please contact the site administrator.</li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default ErrorPage;