import React from 'react';
import { connect } from 'react-redux';
import consolidatedService from "../../../../../services/consolidated-service";
import { setConsolidatedFilterOptions } from '../../../../../store/actions';
import GridABStatusCell from '../../../../shared/GridABStatusCell/GridABStatusCell';

class ABStatusCell extends React.Component {

    getFilters() {
        const { tableFilters, user, isDelegate } = this.props;

        const mixedFilters = [...consolidatedService.mapFiltersToAPI(user.settings.consolidatedOrdersPage.filter.filters), ...tableFilters];
        const baseFilters = consolidatedService.createBaseFilters(user, mixedFilters, isDelegate);
        return consolidatedService.getFiltersForFilters(baseFilters, mixedFilters);
    }

    setFilterOptions(data) {
        const { user, isDelegate } = this.props;

        const filterOptions = consolidatedService.createFilterOptions(user, data, isDelegate);
        this.props.setConsolidatedFilterOptions(filterOptions);
    }

    render() {
        const { rowDetail, isDelegate } = this.props;

        return <GridABStatusCell
            isDelegate={isDelegate}
            rowDetail={rowDetail}
            getFilters={() => this.getFilters()}
            setFilterOptions={data => this.setFilterOptions(data)}
        />
    }
};

const mapStateToProps = state => ({
    user: state.session.user,
    tableFilters: state.consolidated.filters,
    isDelegate: state.consolidated.isDelegate
})
const mapDispatchToProps = {
    setConsolidatedFilterOptions
}

export default connect(mapStateToProps, mapDispatchToProps)(ABStatusCell);
