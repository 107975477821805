import React from 'react';
import { DefaultButton, PrimaryButton, Spinner, TextField } from '@fluentui/react';
import apiService from '../../../services/api-service';

const MAX_LENGTH = 1024;

class ImportantNote extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isEditing: false,
            isSaving: false,
            note: ''
        };
    }

    componentDidMount() {
        const importantNote = this.getJobOrderImportantNote();
        this.setState({ note: importantNote });
    }

    getJobOrderImportantNote() {
        const { jobOrder } = this.props;
        return jobOrder.allocation_important_note || "";
    }

    cancel() {
        const importantNote = this.getJobOrderImportantNote();
        this.setState({ note: importantNote });
        this.toggleEdit(false);
    }

    clear() {
        this.setState({
            note: ''
        });
    }

    save() {
        const { note } = this.state;
        const { jobOrder } = this.props;

        const jobOrderId = jobOrder.pafo_joborderid;
        const importantNote = note.length > MAX_LENGTH
            ? note.substring(0, MAX_LENGTH)
            : note;

        this.setState({ isSaving: true });

        apiService.saveImportantNote(jobOrderId, importantNote)
            .then(data => {
                this.toggleEdit(false);
                this.props.onSaved?.(importantNote);
            })
            .catch(error => {
                console.error(error);
            })
            .finally(() => this.setState({
                isSaving: false,
                note: importantNote
            }));
    }

    toggleEdit(value) {
        this.setState({ isEditing: value });
        this.props.onEditChange?.(value);
    }

    renderUpdating() {
        return (
            <div className="mt-3">
                <Spinner label="Updating Important Note..." ariaLive="assertive" labelPosition="right" />
            </div>
        )
    }

    renderComponent() {
        const { readOnly } = this.props;
        const { note, isEditing } = this.state;

        const isEmpty = !this.getJobOrderImportantNote()?.length;

        if (isEmpty && !isEditing) {
            return (
                <div className='d-flex justify-content-center'>
                    <PrimaryButton
                        text="Add Important Note"
                        size={3}
                        disabled={readOnly}
                        iconProps={{ iconName: 'Add' }}
                        onClick={() => this.toggleEdit(true)}
                    />
                </div>
            )
        }

        return (
            <div className='d-flex flex-column' style={{ overflow: 'hidden' }}>
                <div className='w-100'>
                    <TextField
                        autoFocus
                        multiline
                        maxLength={MAX_LENGTH}
                        style={isEditing
                            ? {
                                maxHeight: '200px',
                            }
                            : {
                                color: '#DC3333',
                                fontWeight: 500,
                                fontSize: 16,
                                backgroundColor: '#fdf6e6',
                                boxShadow: '1px 5px 6px #CCC',
                                maxHeight: '200px'
                            }}
                        onClick={event => {
                            if (!isEditing && !readOnly) {
                                this.toggleEdit(true);
                            }
                        }}
                        borderless={!isEditing}
                        readOnly={!isEditing}
                        value={note}
                        resizable
                        onChange={(event, nextValue) => {
                            this.setState({ note: nextValue });
                        }} />
                </div>
                {
                    isEditing && (
                        <div className='d-flex justify-content-end mt-2' style={{ gap: 5 }}>
                            <DefaultButton
                                text="Cancel"
                                iconProps={{ iconName: 'Cancel' }}
                                onClick={() => this.cancel()}
                            />
                            <DefaultButton
                                text="Clear"
                                disabled={!note?.length}
                                onClick={() => this.clear()}
                            />
                            <PrimaryButton
                                text="Save"
                                iconProps={{ iconName: 'Save' }}
                                onClick={() => this.save()}
                            />
                        </div>
                    )
                }
            </div>
        )
    }

    render() {
        const { isSaving } = this.state;

        return (
            <>
                {
                    isSaving
                        ? this.renderUpdating()
                        : this.renderComponent()
                }
            </>
        )
    }
};

export default ImportantNote;