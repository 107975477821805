import React from 'react';
import { connect } from 'react-redux';
import myDepartmentsService from "../../../../../services/mydepartments-service";
import { setMyDepartmentsFilterOptions } from '../../../../../store/actions';
import GridABStatusCell from '../../../../shared/GridABStatusCell/GridABStatusCell';


class ABStatusCell extends React.Component {

    getFilters() {
        const { activeMicroteam, activeDepartment } = this.props;

        return [
            myDepartmentsService.createMandatoryFilter(activeMicroteam?.microteamid, activeDepartment?.kf_financialhierarchyid)
        ];
    }

    setFilterOptions(data) {
        const filterOptions = myDepartmentsService.createFilterOptions(data);
        this.props.setMyDepartmentsFilterOptions(filterOptions);
    }

    render() {
        const { rowDetail, activeDepartment } = this.props;

        return <GridABStatusCell
            isDelegate={activeDepartment?.isDelegate}
            rowDetail={rowDetail}
            getFilters={() => this.getFilters()}
            setFilterOptions={data => this.setFilterOptions(data)}
        />
    }
};

const mapStateToProps = state => ({
    user: state.session.user,
    activeDepartment: state.session.activeDepartment,
    activeMicroteam: state.session.activeMicroteam
})
const mapDispatchToProps = {
    setMyDepartmentsFilterOptions
}

export default connect(mapStateToProps, mapDispatchToProps)(ABStatusCell);