import * as actionTypes from '../actions/actionTypes';

const defaultState = {
    loading: false,
    selectedRows: [],
    rowDetail: null,
    data: [],
    pagination: { size: 25, skip: null },
    filters: [],
    filterOptions: [],
    sort: { columnName: 'createdon', sortOrder: 'desc' },

    columns: [],
    searchText: '',
    showNotes: false,
    showRecruiters: false,
    isDelegate: true
}

const newState = (state, newData) => {
    return Object.assign({}, state, newData)
}

const consolidated = (state = defaultState, action) => {
    switch (action.type) {
        case actionTypes.SET_CONSOLIDATED_LOADING:
            return newState(state, {
                loading: action.payload
            })
        case actionTypes.SET_CONSOLIDATED_SELECTEDROWS:
            return newState(state, {
                selectedRows: action.payload
            })
        case actionTypes.SET_CONSOLIDATED_ROWDETAIL:
            return newState(state, {
                rowDetail: action.payload
            })
        case actionTypes.SET_CONSOLIDATED_DATA:
            return newState(state, {
                data: action.payload
            })
        case actionTypes.SET_CONSOLIDATED_PAGINATION:
            return newState(state, {
                pagination: action.payload
            })
        case actionTypes.SET_CONSOLIDATED_FILTERS:
            return newState(state, {
                filters: action.payload
            })
        case actionTypes.SET_CONSOLIDATED_FILTEROPTIONS:
            return newState(state, {
                filterOptions: action.payload
            })
        case actionTypes.SET_CONSOLIDATED_SORT:
            return newState(state, {
                sort: action.payload
            })
        case actionTypes.SET_CONSOLIDATED_COLUMNS:
            return newState(state, {
                columns: action.payload
            })
        case actionTypes.SET_CONSOLIDATED_SEARCHTEXT:
            return newState(state, {
                searchText: action.payload
            })
        case actionTypes.SET_CONSOLIDATED_SHOWNOTES:
            return newState(state, {
                showNotes: action.payload
            })
        case actionTypes.SET_CONSOLIDATED_SHOWRECRUITERS:
            return newState(state, {
                showRecruiters: action.payload
            })
        case actionTypes.SET_CONSOLIDATED_ISDELEGATE:
            return newState(state, {
                isDelegate: action.payload
            })
        default:
            return state
    }
}
export default consolidated;