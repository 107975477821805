import React from 'react';
import { connect, batch } from 'react-redux';
import { ContextualMenuItemType, CommandButton } from '@fluentui/react';
import { setUser } from '../../../store/actions';
import apiService from '../../../services/api-service';
import ColumnsSettings from '../ColumnsSettings/ColumnsSettings'

class GridActionHeader extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showColumnsSettings: false
        };
        this.saveColumnsSettings = this.saveColumnsSettings.bind(this);
        this.reset = this.reset.bind(this);
    }

    saveColumnsSettings(columnsFilter, columnsToSet) {
        const { user, page } = this.props;

        user.settings[page + "Page"].columns = columnsToSet;

        batch(() => {
            this.props.setColumns(columnsFilter);
            this.props.setUser(user);
        });

        apiService
            .setUserColumns(user.oid, page, columnsToSet)
            .then(data => {
                // IF FAIL... ROLLBACK ?
            });

        this.setState({ showColumnsSettings: false });
    }

    reset() {
        const { rowDetail } = this.props;

        rowDetail.columnApi.columnModel.columnDefs.forEach(c => {
            c.flex = 1
            c.width = undefined
        });

        rowDetail.api.sizeColumnsToFit()

        rowDetail.columnApi.columnModel.columnDefs.forEach(c => {
            c.flex = undefined;
        });
    }

    render() {
        const { columnsDef, columnsToShow, page, user } = this.props;
        const { showColumnsSettings } = this.state;

        const defaultActions = [
            {
                key: 'Columns',
                itemType: ContextualMenuItemType.Header,
                text: 'COLUMNS'
            },
            {
                key: 1,
                text: 'Show/Hide',
                onClick: () => this.setState({ showColumnsSettings: true })
            },
            {
                key: 2,
                text: 'Reset',
                onClick: this.reset
            }
        ]

        const menuProps = {
            shouldFocusOnMount: true,
            items: defaultActions
        }
        return (
            <>
                <div className="col-12 p-0 position-absolute" style={{ right: '-7px', top: '10px' }}>
                    <CommandButton
                        className="action-table"
                        text=""
                        iconProps={{ iconName: 'ColumnOptions' }}
                        menuProps={menuProps}
                    />
                </div>
                <ColumnsSettings
                    open={showColumnsSettings}
                    columnsDef={columnsDef}
                    userColumns={user.settings[page + "Page"].columns}
                    columnsToShow={columnsToShow}
                    onSave={this.saveColumnsSettings}
                    onClose={() => this.setState({ showColumnsSettings: false })}
                />
            </>
        );
    }
};

const mapStateToProps = state => ({
    user: state.session.user
})

const mapDispatchToProps = {
    setUser
}

export default connect(mapStateToProps, mapDispatchToProps)(GridActionHeader);