import React from 'react';
import { connect } from 'react-redux';
import { FontIcon, TooltipHost } from '@fluentui/react';

class TitleSection extends React.Component {
    render() {
        const { activeDepartment, activeMicroteam } = this.props;
        const microteamName = activeMicroteam?.microteamname || "";

        return (
            <div className="d-flex align-items-center">
                <h5 className="m-0">
                    <b>My Departments</b> •
                    {
                        activeDepartment?.isDelegate &&
                        <span className="ml-2">
                            <TooltipHost content="Delegated Department">
                                <FontIcon iconName="BusinessCenterLogo" style={{ color: 'salmon' }} />
                            </TooltipHost>
                        </span>
                    }
                    <span className='ml-2'>
                        {activeDepartment.departmentName}
                    </span>
                    {
                        microteamName &&
                        <span style={{ fontSize: 14, color: 'GrayText' }}> • {`${microteamName} Orders`}</span>
                    }
                </h5>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    activeDepartment: state.session.activeDepartment,
    activeMicroteam: state.session.activeMicroteam
})

export default connect(mapStateToProps)(TitleSection);