import * as React from 'react';

class AllOrdersIcon extends React.Component {

    render() {
        return (
            <svg viewBox="0 0 24 24" fill="currentColor" height="1em" width="1em" >
                <path d="M9 21h12V3H3v18h6zm10-4v2h-6v-6h6v4zM15 5h4v6h-6V5h2zM5 7V5h6v6H5V7zm0 12v-6h6v6H5z" />
            </svg>
        )
    }
}

export default AllOrdersIcon