import './GridJobOrderDetail.css';
import React from 'react';
import Notes from '../Notes/Notes';
import RecruitersDetail from '../RecruitersDetail/RecruitersDetail';
import CandidatesDetail from '../CandidatesDetail/CandidatesDetail';
import { formatISODate } from '../../../utils/date';
import Section from '../Section/Section';
import Details from '../Details/Details';
import ImportantNote from '../ImportantNote/ImportantNote';
import MicroteamDetail from '../MicroteamDetail/MicroteamDetail';
import NA from '../NA/NA';


class GridJobOrderDetail extends React.Component {

    render() {
        const { rowDetail, department, readOnly } = this.props;

        const jobOrder = rowDetail?.data;

        if (!jobOrder) {
            return null;
        }

        return (
            <div className="row pt-1">
                <div className="col-8">
                    <Section icon="QuickNote" title="Important JO Note">
                        <ImportantNote
                            readOnly={readOnly}
                            jobOrder={jobOrder}
                            onSaved={(importantNote) => {
                                rowDetail.node.data.allocation_important_note = importantNote;
                                rowDetail.node.setData(rowDetail.node.data);
                            }}
                        />
                    </Section>

                    <Section icon="ExploreData" title="Details">
                        <div className='row'>
                            <div className='col-6'>
                                <Details items={[
                                    { label: "Job Order #", value: jobOrder.pafo_foid },
                                    { label: "Job Type", value: jobOrder.pafo_jobtype },
                                    { label: "Client", value: jobOrder.account_name },
                                    { label: "Client Requisition ID", value: jobOrder.trm_clientrequisitionnumber },
                                    { label: "Department", value: jobOrder.department_display_name },
                                    { label: "Max Bill Rate", value: jobOrder.pafo_billrangehigh },
                                    { label: "Max Salary From", value: jobOrder.trm_salaryrangefrom },
                                    { label: "Max Salary To", value: jobOrder.trm_salaryrangeto },
                                    { label: "ISS JO", value: jobOrder.trm_issjoborder },
                                    { label: "VSD JO", value: jobOrder.trm_vsdjoborder },
                                    { label: "Send to Vendor Network", value: jobOrder.trm_post_to_ilabor },
                                    { label: "Post to Web", value: jobOrder.trm_posttoweb },
                                ]} />
                            </div>
                            <div className='col-6'>
                                <Details items={[
                                    {
                                        label: "TRM Status", value: jobOrder.trm_status, onRenderValue: value => {
                                            const statusClass = value?.replace(' ', '-');
                                            return (
                                                <>
                                                    <span className={`pl-1 status-circle status-${statusClass} fa fa-circle mr-2`}></span>
                                                    <b>{value}</b>
                                                </>
                                            )
                                        }
                                    },
                                    { label: "Created On", value: formatISODate(jobOrder.createdon) },
                                    { label: "Estimated Start Date", value: formatISODate(jobOrder.pafo_estimatedstartdate) },
                                    { label: "Hiring Manager", value: jobOrder.hiringmanager },
                                    { label: "Customer Unit", value: jobOrder.trm_customerunitname },
                                    { label: "Division", value: jobOrder.kf_division },
                                    { label: "Worksite City", value: jobOrder.trm_worksitecity },
                                    { label: "Worksite State", value: jobOrder.trm_worksitestate },
                                    { label: "Remote Work", value: jobOrder.trm_remotework },
                                    { label: "Send Out", value: jobOrder.sendouts },
                                    { label: "Submittals", value: jobOrder.external_submittals },
                                    {
                                        label: "Position", value: jobOrder.pafo_quantity, onRenderValue: value => {
                                            return (
                                                <div className="d-flex ml-1">
                                                    <b>{jobOrder.pafo_quantity}</b>
                                                    <div className="d-flex">
                                                        <span className="mx-1 px-1" style={{ background: '#eee' }}>
                                                            Filled {jobOrder.pafo_quantityfilled | 0}
                                                        </span>
                                                        <span className="mx-1 px-1" style={{ background: '#eee' }}>
                                                            Open {jobOrder.pafo_quantityopen | 0}
                                                        </span>
                                                        <span className="mx-1 px-1" style={{ background: '#eee' }}>
                                                            Cancelled {jobOrder.pafo_quantitycancelled | 0}
                                                        </span>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    }
                                ]} />
                            </div>
                        </div>
                    </Section>

                    <Section icon="BuildDefinition" title="KCS">
                        {
                            jobOrder.kf_advancedservicesrequired
                                ? (
                                    <div className='row'>
                                        <div className='col-6'>
                                            <Details items={[
                                                { label: "Opportunity", value: jobOrder.opportunityname },
                                                { label: "Phase", value: jobOrder.stepname }
                                            ]} />
                                        </div>
                                        <div className='col-6'>
                                            <Details items={[
                                                { label: "Primary Practice Area", value: jobOrder.kf_practicearea },
                                                { label: "Service Capability", value: jobOrder.kf_servicecapabilityprimary },
                                                { label: "Delivery Model", value: jobOrder.kf_engagementmodel }
                                            ]} />
                                        </div>
                                    </div>
                                )
                                : (
                                    <NA />
                                )
                        }
                    </Section>

                    <Section icon="ActivityFeed" title="Activity">
                        <Notes jobOrder={jobOrder} readOnly={readOnly} />
                    </Section>
                </div>

                <div className="col-4">
                    {
                        department &&
                        <Section icon="HomeGroup" title="Microteam">
                            <MicroteamDetail
                                department={department}
                                rowDetail={rowDetail}
                            />
                        </Section>
                    }
                    <Section icon="RecruitmentManagement" title="Recruiters">
                        <RecruitersDetail rowDetail={rowDetail} compact={true} readOnly={readOnly} />
                    </Section>
                    <Section icon="Group" title="Candidates">
                        <CandidatesDetail rowDetail={rowDetail} />
                    </Section>
                </div>
            </div>
        )
    }
};

export default GridJobOrderDetail;