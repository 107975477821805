import * as actionTypes from '../actions/actionTypes';

const defaultState = [];

const notification = (state = defaultState, action) => {
    switch (action.type) {
        case actionTypes.ADD_NOTIFICATION:
            return [action.payload, ...state]
        case actionTypes.REMOVE_NOTIFICATION:
            return state.filter(not => not.id !== action.payload);
        default:
            return state
    }
}
export default notification;