import * as React from 'react';
import { connect } from 'react-redux';
import {
    ActionButton,
    PrimaryButton,
    SearchBox,
    DefaultButton,
    Pivot,
    PivotItem,
    Panel,
    PanelType,
    ProgressIndicator,
    MessageBar,
    MessageBarType
} from '@fluentui/react';
import RecruiterList from '../RecruiterList/RecruiterList';
import apiService from '../../../services/api-service';
import PermissionControllerRender, { validatePermission } from '../../../utils/auth';
import NoData from '../NoData/NoData';

class RecruitersDetail extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showAddPopup: false,
            selectedRecruiters: [],
            removeSelectedRecruiters: [],
            loading: true,
            recruitersData: [],
            coverageRecruiters: [],
            thisDepartmentRecruiters: [],
            allDepartmentsRecruiters: [],
            isAdding: false,
            isRemoving: false,
            notificationMessage: '',
            notificationType: MessageBarType.info,
            tabKey: '.0',
            checkRecruitersSelection: false,
            fetchingCoverageRecruiterIndex: true,
            fetchingCoverageRecruiterDynamics: false,
            fetchingThisDepartment: true,
            fetchingAllDepartments: true,
            extraWaitSeconds: 0,
            extraWaitInterval: null,
            isDelegate: false
        };
        this.addingRecruiters = [];
        this.removingRecruiters = [];
    }

    componentDidMount() {
        const { user, rowDetail } = this.props;

        this.getThisDepartmentRecruiters(() => {
            this.setState({ fetchingThisDepartment: false })
        });
        this.getAllDepartmentsRecruiters(() => {
            this.setState({ fetchingAllDepartments: false });
            if (this.state.fetchingCoverageRecruiterIndex && this.state.coverageRecruiters.length > 0) {
                const covRec = [...this.state.coverageRecruiters];
                covRec.forEach(x => x.additional = this.getAdditionalLabel(x.id));
                this.setState({ coverageRecruiters: covRec });
            }
        });
        // this.getCoverageRecruiterFromDynamics(() => {
        //     this.refreshRecruitersData();
        //     this.setState({ fetchingCoverageRecruiterDynamics: false });
        // });
        this.getCoverageRecruiterFromIndex(() => {
            this.refreshRecruitersData();
            this.setState({ fetchingCoverageRecruiterIndex: false });
        });

        const department = user
            .officeLeaderDepartmentList
            .find(x => x.kf_financialhierarchyid === rowDetail.data.owner_kf_financialhierarchyid);

        if (department) {
            this.setState({ isDelegate: department.isDelegate })
        }
    }

    toggleModal(showAddPopup, skipRefresh) {
        this.setState({
            showAddPopup: showAddPopup,
            selectedRecruiters: [],
            tabKey: '.0'
        });
        if (skipRefresh !== true) {
            this.refreshRecruitersData();
        }
    }

    getAllDepartmentsRecruiters(cb) {
        apiService
            .getSystemUsersAllDepartments()
            .then(data => {
                const result = data && data.results && data.results.length > 0
                    ? data.results.map(dr => ({
                        id: dr.id,
                        description: dr.fullname,
                        raw: dr,
                        name: (dr.firstname ? dr.firstname[0] : '') + (dr.lastname ? dr.lastname[0] : ''),
                        thumbnail: '',
                        additional: dr.title
                    }))
                    : [];
                this.setState({ allDepartmentsRecruiters: result }, () => {
                    if (cb) cb();
                });
            })
            .catch(error => {
                console.error(error);
            });
    }

    getThisDepartmentRecruiters(cb) {
        const { rowDetail } = this.props;
        const jobOrder = rowDetail.data;

        apiService
            .getSystemUsers(jobOrder.owner_kf_financialhierarchyid)
            .then(data => {
                const result = data && data.results && data.results.length > 0
                    ? data.results.map(dr => ({
                        id: dr.id,
                        description: dr.fullname,
                        raw: dr,
                        name: (dr.firstname ? dr.firstname[0] : '') + (dr.lastname ? dr.lastname[0] : ''),
                        thumbnail: '',
                        additional: dr.title
                    }))
                    : [];
                this.setState({ thisDepartmentRecruiters: result }, () => {
                    if (cb) cb();
                });
            })
            .catch(error => {
                console.error(error);
            });
    }

    processCoverageRecruiter(data, cb) {
        if (data.hasError) {
            console.error(data);
            return;
        }
        if (data.isSynchronizing) {
            this.addNotificationMessage("Dynamics updates are in progress. Check back soon for synchronization status.");
        }
        let recruiterCoveragesData = data.recruiterCoverages
            .map(rc => {
                return {
                    id: rc.trmRecruiterId,
                    trmJobOrderRecruiterId: rc.trmJobOrderRecruiterId,
                    trmJobOrderId: rc.trmJobOrderId,
                    description: rc.fullname,
                    name: (rc.firstname ? rc.firstname[0] : '') + (rc.lastname ? rc.lastname[0] : ''),
                    thumbnail: '',
                    additional: this.getAdditionalLabel(rc.trmRecruiterId),
                    disableSelectable: !rc.isDeletable
                }
            });

        [...this.addingRecruiters].forEach(ar => {
            if (!recruiterCoveragesData.some(x => x.id === ar.id)) {
                recruiterCoveragesData.push(ar);
            }
        });

        [...this.removingRecruiters].forEach(rr => {
            if (recruiterCoveragesData.some(x => x.id === rr.id)) {
                recruiterCoveragesData = recruiterCoveragesData.filter(x => x.id !== rr.id);
            }
        });

        this.setState({ coverageRecruiters: recruiterCoveragesData }, () => {
            if (cb) cb();
        });

        const syncData = recruiterCoveragesData.map(x => {
            let firstname = "";
            let lastname = "";
            if (x.description && x.description.includes(' ')) {
                var values = x.description.split(' ');
                firstname = (values.slice(0, -1)).join(' ');
                lastname = values[values.length - 1];
            }
            return {
                id: x.id,
                firstname: firstname,
                lastname: lastname,
                fullname: x.description
            }
        });
        this.syncRecruiters(syncData);
    }

    getCoverageRecruiterFromDynamics(cb) {
        const { user, rowDetail } = this.props;
        const jobOrder = rowDetail.data;

        apiService
            .getCoverageRecruiterFromDynamics(user.systemUserId, jobOrder.pafo_joborderid)
            .then(data => this.processCoverageRecruiter(data, cb))
            .catch(error => {
                console.error(error);
                if (cb) cb();
            });
    }

    getCoverageRecruiterFromIndex(cb) {
        const { rowDetail } = this.props;
        const jobOrder = rowDetail.data;

        apiService
            .getCoverageRecruiterFromIndex(jobOrder.pafo_joborderid)
            .then(data => this.processCoverageRecruiter(data, cb))
            .catch(error => {
                console.error(error);
                if (cb) cb();
            });
    }

    getAdditionalLabel(recruiterId) {
        const { allDepartmentsRecruiters } = this.state;

        const recruiter = allDepartmentsRecruiters.find(x => x.id === recruiterId);
        return recruiter?.additional || undefined;
    }

    syncRecruiters(data) {
        try {
            const { rowDetail } = this.props;
            rowDetail.node.data.recruiters = data;
            rowDetail.node.setData(rowDetail.node.data);
            const column = rowDetail.columnApi.getColumn("recruiters");
            if (column?.colId) {
                rowDetail.api.refreshCells({ rowNodes: [rowDetail.node], force: true });
            }
        } catch (e) {
            console.error(e);
        }
    }

    refreshRecruitersData(searchValue) {
        const { thisDepartmentRecruiters, coverageRecruiters, allDepartmentsRecruiters, selectedRecruiters, tabKey } = this.state;

        this.setState({ recruitersData: [] });

        let recruiters;
        switch (tabKey) {
            case ".0":
                recruiters = thisDepartmentRecruiters;
                break;
            case ".1":
                recruiters = allDepartmentsRecruiters;
                break;
            default:
                recruiters = [];
                break;
        }

        let data = recruiters.filter(x => !coverageRecruiters.some(cr => cr.id === x.id));
        if (searchValue && (searchValue !== '')) {
            const searchValueLowerCase = searchValue.toLowerCase();
            data = data.filter(mt => mt.description?.toLowerCase().indexOf(searchValueLowerCase) !== -1);
        }
        data.forEach((x, i) => {
            x.isChecked = selectedRecruiters.some(rd => rd.id === x.id);
        });

        setTimeout(() => {
            this.setState({ recruitersData: data });
        }, 10);
    }

    checkAddRecruiter(recruiter) {
        const { selectedRecruiters } = this.state;
        this.setState({ selectedRecruiters: [...selectedRecruiters, recruiter] });
    }

    uncheckAddRecruiter(recruiter) {
        const { selectedRecruiters } = this.state;
        this.setState({ selectedRecruiters: [...selectedRecruiters].filter(x => x.id !== recruiter.id) });
    }

    checkRemoveRecruiter(recruiter) {
        const { removeSelectedRecruiters } = this.state;
        this.setState({ removeSelectedRecruiters: [...removeSelectedRecruiters, recruiter] });
    }

    uncheckRemoveRecruiter(recruiter) {
        const { removeSelectedRecruiters } = this.state;
        this.setState({ removeSelectedRecruiters: [...removeSelectedRecruiters].filter(x => x.id !== recruiter.id) });
    }

    addNotificationMessage(message, type = MessageBarType.error) {
        this.setState({ notificationMessage: message, notificationType: type });
        setTimeout(() => {
            this.setState({ notificationMessage: "" });
        }, 15000)
    }

    setExtraWait() {
        this.setState({ extraWaitSeconds: 0 });

        if (this.state.extraWaitInterval) {
            return;
        }

        const extraWaitIntervalId = setInterval(() => {
            this.setState((state) => ({
                extraWaitSeconds: state.extraWaitSeconds + 1
            }), () => {
                if (this.state.extraWaitSeconds >= 15) {
                    clearInterval(extraWaitIntervalId);
                    this.setState({ extraWaitInterval: null });
                }
            });
        }, 1000);

        this.setState({ extraWaitInterval: extraWaitIntervalId });
    }

    addRecruiters() {
        const { coverageRecruiters, selectedRecruiters } = this.state;

        this.toggleModal(false);

        if (selectedRecruiters.length === 0) {
            this.addNotificationMessage("Please select a least one recruiter");
            return;
        }

        if (selectedRecruiters.length > 10) {
            this.addNotificationMessage("It's not posible to add more than 10 recruiters per batch");
            return;
        }

        const recruitersToAdd = selectedRecruiters.map(recruiter => ({
            id: recruiter.id,
            trmJobOrderRecruiterId: undefined,
            description: recruiter.description,
            name: recruiter.name,
            thumbnail: recruiter.name,
            hideRemovable: true,
            disableSelectable: true,
            isChecked: false,
            additional: this.getAdditionalLabel(recruiter.id)
        }));
        const recruitersIds = recruitersToAdd.map(x => x.id);

        Array.prototype.push.apply(this.addingRecruiters, recruitersToAdd);
        this.setState({
            coverageRecruiters: [...coverageRecruiters, ...recruitersToAdd],
            checkRecruitersSelection: false,
            isAdding: true
        });
        this.updateBubbles(selectedRecruiters, false);

        const { user, rowDetail } = this.props;
        const jobOrder = rowDetail.data;

        let failedIds = [];
        apiService
            .addRecruiterCoverage(user.systemUserId, jobOrder.pafo_joborderid, recruitersIds)
            .then(data => {
                // ====== THIS LOGIC IS TO ROLLBACK THE CHANGES IN THE UI. IT'S NEED CHANGES IN THE API TO WORK.
                // if (data.hasError === true || data.errorMessage || data.errorDetail) {
                //     this.addNotificationMessage(`${data.errorDetail} -> ${data.errorMessage}`);
                //     failedIds = data.errorMessage.split(",");
                //     this.updateBubbles(selectedRecruiters.filter(x => failedIds.includes(x.id)), true);
                // }
                if (data.hasError === true) {
                    this.addNotificationMessage(data.errorMessage, MessageBarType.warning);
                }
            })
            .catch(error => {
                console.error(error);
            })
            .finally(() => {
                this.addingRecruiters = this.addingRecruiters.filter(x => ![...recruitersIds, ...failedIds].includes(x.id));
                this.setState({ isAdding: false });
                this.setExtraWait();
            });
    }

    removeRecruiters(recruiters) {
        if (recruiters.length === 0) {
            this.addNotificationMessage("Please select at least one recruiter");
            return;
        }

        const { user } = this.props;
        const { coverageRecruiters } = this.state;

        const trmJobOrderRecruiterIds = recruiters.map(x => x.trmJobOrderRecruiterId);
        const recruitersIds = recruiters.map(x => x.id);

        Array.prototype.push.apply(this.removingRecruiters, recruiters);

        const coverageRecruitersFiltered = coverageRecruiters.filter(x => !recruitersIds.includes(x.id));
        coverageRecruitersFiltered.forEach(x => x.isChecked = false);

        this.setState({
            coverageRecruiters: [],
            removeSelectedRecruiters: [],
            isRemoving: true,
            checkRecruitersSelection: false
        });
        this.updateBubbles(recruiters, true);

        setTimeout(() => {
            this.setState({
                coverageRecruiters: coverageRecruitersFiltered
            });
        }, 500);

        let failedIds = [];
        apiService
            .removeRecruiterCoverage(user.systemUserId, trmJobOrderRecruiterIds, recruiters[0].trmJobOrderId)
            .then(data => {
                // ====== THIS LOGIC IS TO ROLLBACK THE CHANGES IN THE UI. IT'S NEED CHANGES IN THE API TO WORK.
                // if (data.hasError === true || data.errorMessage || data.errorDetail) {
                //     this.addNotificationMessage(`${data.errorDetail} -> ${data.errorMessage}`);
                //     failedIds = data.errorMessage.split(",");
                //     this.updateBubbles(recruiters.filter(x => failedIds.includes(x.id)), false);
                // }
                if (data.hasError === true) {
                    this.addNotificationMessage(data.errorMessage, MessageBarType.warning);
                }
            })
            .catch(error => {
                console.error(error);
            })
            .finally(() => {
                this.removingRecruiters = this.removingRecruiters.filter(x => ![...recruitersIds, ...failedIds].includes(x.id));
                this.setState({ isRemoving: false });
                this.setExtraWait();
            });

        this.toggleModal(false);
    }

    updateBubbles(recruiters, isRemove) {
        const { rowDetail } = this.props;

        if (!rowDetail.node.data.recruiters) {
            rowDetail.node.data.recruiters = [];
        }

        if (isRemove) {
            rowDetail.node.data.recruiters = rowDetail.node.data.recruiters.filter(x => !recruiters.some(rr => rr.id === x.id));
        } else {
            Array.prototype.push.apply(rowDetail.node.data.recruiters, recruiters.map(x => x.raw));
        }

        try {
            rowDetail.node.setData(rowDetail.node.data);
            const column = rowDetail.columnApi.getColumn("recruiters");
            if (column?.colId) {
                rowDetail.api.refreshCells({ rowNodes: [rowDetail.node], force: true });
            }
        } catch (e) {
            console.error(e);
        }
    }

    changeTab(item) {
        this.setState({
            loading: true,
            tabKey: item.key,
            recruitersData: [],
            selectedRecruiters: []
        }, () => this.refreshRecruitersData())
    }

    toggleRecruitersSelection(checked) {
        const { coverageRecruiters } = this.state;

        const data = [];
        coverageRecruiters.forEach(x => {
            const cloneX = { ...x };
            if (cloneX.disableSelectable !== true && cloneX.trmJobOrderRecruiterId) {
                cloneX.isChecked = checked;
            } else {
                cloneX.isChecked = false;
                cloneX.disableSelectable = true;
            }
            data.push(cloneX);
        });

        this.setState({
            checkRecruitersSelection: checked,
            coverageRecruiters: [],
            removeSelectedRecruiters: checked === true ? data.filter(x => x.disableSelectable !== true) : []
        });
        setTimeout(() => {
            this.setState({ coverageRecruiters: data });
        }, 1)
    }

    refreshCoverageRecruiter(dynamics) {
        if (dynamics) {
            this.setState({ fetchingCoverageRecruiterDynamics: true });
            this.getCoverageRecruiterFromDynamics(() => this.setState({ fetchingCoverageRecruiterDynamics: false }));
        } else {
            this.setState({ fetchingCoverageRecruiterIndex: true });
            this.getCoverageRecruiterFromIndex(() => this.setState({ fetchingCoverageRecruiterIndex: false }));
        }
    }

    render() {
        const { compact, user, readOnly } = this.props;
        const {
            showAddPopup,
            selectedRecruiters,
            fetchingCoverageRecruiterDynamics,
            fetchingCoverageRecruiterIndex,
            fetchingThisDepartment,
            fetchingAllDepartments,
            recruitersData,
            coverageRecruiters,
            isAdding,
            isRemoving,
            removeSelectedRecruiters,
            notificationMessage,
            notificationType,
            checkRecruitersSelection,
            extraWaitInterval,
            isDelegate
        } = this.state;

        return (
            <div className="pb-2">
                {
                    !readOnly && (
                        <>
                            <div className="d-flex justify-content-between align-items-center">
                                <div className='d-flex'>
                                    <PermissionControllerRender for="ADD_RECRUITER" skip={isDelegate}>
                                        <ActionButton
                                            style={{ paddingLeft: 0 }}
                                            text="Select All"
                                            onRenderText={props => <span className='small'>{props.text}</span>}
                                            disabled={coverageRecruiters.length === 0 || fetchingCoverageRecruiterDynamics || fetchingCoverageRecruiterIndex || isAdding || showAddPopup}
                                            onClick={() => this.toggleRecruitersSelection(!checkRecruitersSelection)}
                                            iconProps={{ iconName: 'ReceiptCheck' }}
                                        />
                                    </PermissionControllerRender>
                                </div>

                                <div className="position-relative">
                                    <div className='d-flex'>
                                        <PermissionControllerRender for="ADD_RECRUITER" skip={isDelegate}>
                                            <ActionButton
                                                text={compact ? "" : "Add Recruiters"}
                                                disabled={fetchingCoverageRecruiterDynamics || fetchingCoverageRecruiterIndex || fetchingThisDepartment || fetchingAllDepartments || isRemoving}
                                                onClick={() => this.toggleModal(!showAddPopup)}
                                                iconProps={{ iconName: 'AddTo' }}
                                            />
                                            <ActionButton
                                                text={compact ? "" : "Remove Recruiters"}
                                                disabled={removeSelectedRecruiters.length === 0 || fetchingCoverageRecruiterDynamics || fetchingCoverageRecruiterIndex || fetchingThisDepartment || fetchingAllDepartments || isAdding || showAddPopup}
                                                onClick={() => this.removeRecruiters(removeSelectedRecruiters)}
                                                iconProps={{ iconName: 'Trash' }}
                                            />
                                        </PermissionControllerRender>
                                        <ActionButton
                                            // split
                                            text={compact ? "" : "Dynamics Refresh"}
                                            iconProps={{ iconName: 'Refresh' }}
                                            onClick={() => this.refreshCoverageRecruiter(true)}
                                            disabled={extraWaitInterval || fetchingCoverageRecruiterDynamics || fetchingCoverageRecruiterIndex || isAdding || isRemoving || showAddPopup}
                                        // styles={{
                                        //     splitButtonMenuButton: { backgroundColor: 'white', width: 28, border: 'none' },
                                        //     splitButtonMenuIcon: { fontSize: '7px' },
                                        //     splitButtonDivider: { backgroundColor: '#c8c8c8', width: 1, right: 26, position: 'absolute', top: 4, bottom: 4 },
                                        //     splitButtonContainer: {
                                        //         selectors: {
                                        //             [HighContrastSelector]: { border: 'none' },
                                        //         },
                                        //     },
                                        // }}
                                        // menuProps={{
                                        //     items: [
                                        //         {
                                        //             key: 'refresh',
                                        //             text: 'Index Refresh',
                                        //             iconProps: { iconName: 'LightningBolt' },
                                        //             onClick: () => this.refreshCoverageRecruiter(false)
                                        //         },
                                        //         // {
                                        //         //     key: 'dynamicsRefresh',
                                        //         //     text: 'Dynamics Refresh',
                                        //         //     iconProps: { iconName: 'Refresh' },
                                        //         //     onClick: () => this.refreshCoverageRecruiter(true)
                                        //         // },
                                        //     ],
                                        // }}
                                        />
                                    </div>
                                    <Panel
                                        type={PanelType.medium}
                                        isOpen={showAddPopup}
                                        onDismiss={() => this.toggleModal(false, true)}
                                        onRenderHeader={() =>
                                            <div style={{ width: '100%' }}>
                                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                    <Pivot onLinkClick={(item) => this.changeTab(item)}>
                                                        <PivotItem headerText="This Department" itemIcon="Suitcase" />
                                                        <PivotItem headerText="All Departments" itemIcon="CityNext" />
                                                    </Pivot>
                                                    <div className="m-1">
                                                        <SearchBox
                                                            placeholder="Search"
                                                            className="mb-3"
                                                            onSearch={newValue => this.refreshRecruitersData(newValue)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        isFooterAtBottom
                                        onRenderFooterContent={() =>
                                            <div style={{ display: 'flex', gap: 5 }}>
                                                <DefaultButton
                                                    className="col-6"
                                                    iconProps={{ iconName: 'Cancel' }}
                                                    onClick={() => this.toggleModal(false, true)}
                                                >Cancel</DefaultButton>
                                                <PrimaryButton
                                                    className="col-6"
                                                    disabled={selectedRecruiters.length === 0}
                                                    iconProps={{ iconName: 'Add' }}
                                                    onClick={() => this.addRecruiters()}
                                                >Add</PrimaryButton>
                                            </div>
                                        }
                                    >
                                        <RecruiterList
                                            selectable={true}
                                            itemChecked={(i) => this.checkAddRecruiter(i)}
                                            itemUnchecked={(i) => this.uncheckAddRecruiter(i)}
                                            data={recruitersData}
                                            maxHeight="100%"
                                        />
                                    </Panel>
                                </div>
                            </div>
                            {
                                notificationMessage &&
                                <MessageBar
                                    delayedRender={false}
                                    messageBarType={notificationType}
                                >
                                    {notificationMessage}
                                </MessageBar>
                            }
                        </>
                    )
                }
                {
                    (fetchingCoverageRecruiterDynamics || fetchingCoverageRecruiterIndex) ?
                        <ProgressIndicator description={`Fetching recruiters from ${fetchingCoverageRecruiterDynamics ? "Dynamics" : "Index"}, please wait...`} />
                        :
                        <>
                            {
                                (isAdding || isRemoving) &&
                                <ProgressIndicator description="Operation in progress..." />
                            }
                            {
                                !!coverageRecruiters?.length
                                    ? <RecruiterList
                                        data={coverageRecruiters}
                                        itemChecked={(i) => this.checkRemoveRecruiter(i)}
                                        itemUnchecked={(i) => this.uncheckRemoveRecruiter(i)}
                                        selectable={!readOnly && (isDelegate || validatePermission('REMOVE_RECRUITER', user.roleName))}
                                        // disableSelectable={isAdding}
                                        maxHeight="100%"
                                    />
                                    : <NoData />
                            }
                        </>
                }
            </div>
        )
    }
}

const mapStateToProps = state => ({
    user: state.session.user
})

export default connect(mapStateToProps)(RecruitersDetail)