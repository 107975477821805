import React from 'react';
import { connect } from 'react-redux';
import PeopleList from '../../../shared/PeopleList/PeopleList';
import { PrimaryButton, DefaultButton, SearchBox, Panel, PanelType, ProgressIndicator } from '@fluentui/react';
import { addNotification } from '../../../../store/actions';
import apiService from '../../../../services/api-service';

class OfficeDelegates extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            adding: false,
            loading: true,
            selectedSearchDelegates: [],
            searchDelegatesData: [],
            delegatesAssignedData: [],
            showAddDelegates: false
        }
        this.allSearchDelegates = [];
    }

    componentDidMount() {
        this.fetchSearchDelegates();
        this.fetchDelegatesAssigned();
    }

    notify(type, text) {
        window.setTimeout(() => {
            this.props.addNotification({ type: type, text: text });
        }, 2000);
    }

    addDelegate() {
        const { user } = this.props;
        const { selectedSearchDelegates } = this.state;

        this.setState({ adding: true });

        const officeLeaderId = user.oid;
        const delegateListId = selectedSearchDelegates.map(x => x.id);

        apiService.addDelegate(officeLeaderId, delegateListId)
            .then(res => {
                let data = JSON.parse(res);
                if (data === true) {
                    this.fetchDelegatesAssigned();
                    this.notify("success", "Delegate added");
                }
                else {
                    this.notify("error", "Something went wrong");
                }
            })
            .finally(() => {
                this.setState({ selectedSearchDelegates: [], adding: false, showAddDelegates: false });
            });
    }

    removeDelegate(delegate) {
        const { user } = this.props;

        const officeLeaderId = user.oid;
        const delegateListId = [delegate.id];

        apiService.removeDelegate(officeLeaderId, delegateListId)
            .then(res => {
                let data = JSON.parse(res);
                if (data === true) {
                    const { delegatesAssignedData } = this.state;
                    const newData = delegatesAssignedData.filter(x => x.id !== delegate.id);
                    this.setState({ delegatesAssignedData: newData });
                    this.fetchDelegatesAssigned();
                    this.notify("success", "Delegate removed");
                }
                else {
                    this.notify("error", "Something went wrong");
                }
            })
            .finally(() => {
                this.refreshSearchDelegatesData();
                this.setState({ selectedSearchDelegates: [] });
            });
    }

    fetchSearchDelegates() {
        apiService.searchDelegate()
            .then(data => {
                this.allSearchDelegates = data.map(mt => ({
                    id: mt.userId,
                    description: mt.displayName,
                    name: mt.displayName
                        ? mt.displayName.length > 1
                            ? mt.displayName.slice(0, 2).toUpperCase()
                            : mt.displayName[0].toUpperCase()
                        : '',
                    thumbnail: ''
                }));
                this.refreshSearchDelegatesData();
            });
    }

    fetchDelegatesAssigned() {
        const { user } = this.props;

        this.setState({ loading: true });

        const officeLeaderId = user.oid;
        apiService.delegatesAssigned(officeLeaderId)
            .then(data => {
                if (!data || data === '') {
                    return;
                }
                const delegatesAssignedData = data.map(mt => ({
                    id: mt.userId,
                    description: mt.displayName,
                    name: mt.displayName
                        ? mt.displayName.length > 1
                            ? mt.displayName.slice(0, 2).toUpperCase()
                            : mt.displayName[0].toUpperCase()
                        : '',
                    thumbnail: ''
                }));
                this.setState({ delegatesAssignedData: delegatesAssignedData, loading: false });
            });
    }

    refreshSearchDelegatesData(searchValue) {
        let searchDelegates = this.allSearchDelegates;
        if (searchValue && (searchValue !== '')) {
            const searchValueLowerCase = searchValue.toLowerCase();
            searchDelegates = searchDelegates.filter(mt => mt.description?.toLowerCase().indexOf(searchValueLowerCase) !== -1);
        }
        this.setState({ searchDelegatesData: searchDelegates });
    }

    onSelectItem(item, checked) {
        let { selectedSearchDelegates } = this.state;

        const onList = selectedSearchDelegates.filter(e => e.id === item.id).length > 0;
        if (!onList) {
            selectedSearchDelegates.push(item)
        } else {
            selectedSearchDelegates = selectedSearchDelegates.filter(e => e.id !== item.id);
        }

        this.setState({ selectedSearchDelegates: selectedSearchDelegates })
    }


    render() {
        const { selectedSearchDelegates, searchDelegatesData, delegatesAssignedData, adding, loading, showAddDelegates } = this.state;

        return (
            <>
                <div className='ml-3'>
                    <PrimaryButton
                        className='col-1 mb-1'
                        iconProps={{ iconName: 'Add' }}
                        onClick={() => this.setState({ showAddDelegates: true })}
                    >Assign</PrimaryButton>
                    {
                        !loading ?
                            <PeopleList
                                data={delegatesAssignedData}
                                removable={true}
                                randomInitialsColor={true}
                                itemRemoved={(i) => this.removeDelegate(i)}
                                maxHeight="100%"
                            />
                            :
                            <div className="mt-3">
                                <ProgressIndicator description="Loading delegates..." />
                            </div>
                    }
                </div>

                <Panel
                    type={PanelType.medium}
                    isOpen={showAddDelegates}
                    onDismiss={() => this.setState({ showAddDelegates: false })}
                    onRenderHeader={() =>
                        <div className='pl-4 pr-1 py-1' style={{ width: '100%' }}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <h5>Assign Delegates</h5>
                                <SearchBox
                                    placeholder="Search"
                                    className="mb-3"
                                    onSearch={newValue => this.refreshSearchDelegatesData(newValue)}
                                />
                            </div>
                        </div>
                    }
                    isFooterAtBottom
                    onRenderFooterContent={() =>
                        <div style={{ display: 'flex', gap: 5 }}>
                            <DefaultButton
                                className="col-6"
                                disabled={adding}
                                iconProps={{ iconName: 'Cancel' }}
                                onClick={() => this.setState({ showAddDelegates: false })}
                            >Cancel</DefaultButton>
                            <PrimaryButton
                                className="col-6"
                                disabled={adding || selectedSearchDelegates.length === 0}
                                iconProps={{ iconName: 'Add' }}
                                onClick={() => this.addDelegate()}
                            >Add</PrimaryButton>
                        </div>
                    }
                >
                    <div>
                        {
                            !adding ?
                                <PeopleList
                                    selectable={true}
                                    randomInitialsColor={true}
                                    onSelectItem={(item, checked) => this.onSelectItem(item, checked)}
                                    data={searchDelegatesData}
                                    maxHeight="100%"
                                />
                                :
                                <div className="mt-3">
                                    <ProgressIndicator description="Assigning delegates..." />
                                </div>
                        }
                    </div>
                </Panel>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    token: state.session.token,
    user: state.session.user,
})

const mapDispatchToProps = (dispatch) => ({
    addNotification: not => dispatch(addNotification(not))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OfficeDelegates)