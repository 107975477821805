import './App.css';
import React from 'react';

import { Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";

import { app, authentication } from "@microsoft/teams-js";
import { initializeIcons } from '@fluentui/react';

// Table styles
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

// Redux
import { connect } from 'react-redux'
import { setToken } from '../store/actions';

// Components
import SideBar from './SideBar/SideBar';
import ErrorPage from "./shared/errorPage";
import MyDepartments from './views/MyDepartments/MyDepartments';
import Security from './views/Security/Security';
import AllOrders from './views/AllOrders/AllOrders';
import InitialValidator from './views/InitialValidator';
import UserPreferences from './views/UserPreferences/UserPreferences';
import ConsolidatedOrders from './views/ConsolidatedOrders/ConsolidatedOrders';
import GlobalNotifications from './shared/GlobalNotifications/GlobalNotifications';
import Loading from './shared/Loading/Loading';

import { localToken } from '../config';
import { LocalTokenKey } from '../constants/constants';

const history = createBrowserHistory();

const AuthStatus = {
    notAuthenticated: "notAuthenticated",
    localDevelopment: "localDevelopment",
    gettingContext: "gettingContext",
    gettingAuthToken: "gettingAuthToken",
    authenticated: "authenticated",
    error: "error"
}

const rootPath = '/';

class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            context: null,
            currentPath: window.location.pathname,
            isAuthenticating: false,
            authStatus: AuthStatus.notAuthenticated
        }
        initializeIcons();
    }

    componentDidMount() {
        app.initialize();
        window.setTimeout(() => {
            this.authenticate();
        }, 600);
    }

    authenticate() {

        // ============== LOCAL DEVELOPMENT ============== 
        if (localToken) {
            this.setState({ isAuthenticating: true, authStatus: AuthStatus.localDevelopment });
            console.log('%cLOCAL DEVELOPMENT', 'background: #222; color: #bada55; font-size: 24px');
            this.ssoLoginSuccess(localToken, true);
            return;
        }
        // ============ end: LOCAL DEVELOPMENT ============

        this.setState({ isAuthenticating: true, authStatus: AuthStatus.gettingContext });
        app.getContext((context, error) => {
            this.setState({ context: context });
        });

        this.setState({ authStatus: AuthStatus.gettingAuthToken });
        authentication.getAuthToken({ silent: true })
            .then(token => this.ssoLoginSuccess(token))
            .catch(error => this.ssoLoginFailure(error));
    }

    ssoLoginSuccess(result, isLocal) {
        window.localStorage.removeItem(LocalTokenKey);
        if (isLocal) {
            window.localStorage.setItem(LocalTokenKey, result);
        }
        this.props.setToken(result);
        this.setState({ authStatus: AuthStatus.authenticated });
        window.setTimeout(() => {
            this.setState({ isAuthenticating: false });
        }, 600);
    }

    ssoLoginFailure(error) {
        console.error("SSO failed: ", error);
        this.setState({ isAuthenticating: false, authStatus: AuthStatus.error });
    }

    render() {
        const { user } = this.props;
        const { isAuthenticating, authStatus, currentPath } = this.state;

        if (authStatus === AuthStatus.error) {
            return <ErrorPage />
        }

        if (isAuthenticating) {
            let description = "";
            let percentComplete = 0.22;

            switch (authStatus) {
                case AuthStatus.gettingContext:
                    description = "Checking context...";
                    percentComplete = 0.35;
                    break;

                case AuthStatus.gettingAuthToken:
                    description = "Validating token...";
                    percentComplete = 0.48;
                    break;

                case AuthStatus.authenticated:
                    description = "Successfully authenticated";
                    percentComplete = 0.62;
                    break;

                default:
                    break;
            }

            return <Loading description={description} percentComplete={percentComplete} />
        }

        if (authStatus !== AuthStatus.authenticated && !user) {
            return <Loading description="Starting authentication..." percentComplete={0.12} />
        }

        if (authStatus === AuthStatus.authenticated && !user && currentPath !== rootPath) {
            history.push({ pathname: rootPath });
            window.setTimeout(() => this.setState({ currentPath: rootPath }), 400);
            return <Loading description="Redirecting to authorization process..." percentComplete={0.79} />
        }

        return (
            <Router history={history}>
                <div id="column-filter"></div>
                <div className="d-flex" style={{ width: '100%', height: '100%' }}>
                    <GlobalNotifications />

                    <div>
                        {user && <SideBar />}
                    </div>

                    <div style={{ width: '100%', height: '100%' }}>
                        <Switch>
                            <Route exact path="/auth" component={InitialValidator} />
                            <Route exact path="/consolidatedOrders" component={ConsolidatedOrders} />
                            <Route exact path="/myDepartments" component={MyDepartments} />
                            <Route exact path="/allOrders" component={AllOrders} />
                            <Route exact path="/userPreferences" component={UserPreferences} />
                            <Route exact path="/security" component={Security} />
                            <Route exact path="/error" component={ErrorPage} />
                            <Route component={InitialValidator} />
                        </Switch>
                    </div>

                </div>
            </Router>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.session.user,
    notification: state.notification
})
const mapDispatchToProps = (dispatch) => ({
    setToken: token => dispatch(setToken(token))
})


export default connect(mapStateToProps, mapDispatchToProps)(App)