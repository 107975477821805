import './Status.css';
import * as React from 'react';
import { DefaultButton } from '@fluentui/react/lib/Button';

class Status extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selected: null,
            menuPropsItems: []
        }
        this.trmStatusItems = [
            { key: 1, text: 'Open', onRender: this.renderMenuItem },
            { key: 2, text: 'Pending', onRender: this.renderMenuItem },
            { key: 3, text: 'Heads Up', onRender: this.renderMenuItem }
        ];

        this.allocationBoardStatusItems = [
            { key: 1, text: 'Not Covered', onRender: this.renderMenuItem },
            { key: 2, text: 'Covered', onRender: this.renderMenuItem },
            { key: 3, text: 'Send Out', onRender: this.renderMenuItem },
            { key: 4, text: 'Offer Accepted', onRender: this.renderMenuItem },
            { key: 5, text: 'Direct Fill', onRender: this.renderMenuItem },
            { key: 6, text: 'Start', onRender: this.renderMenuItem },
            { key: 7, text: 'Opportunity', onRender: this.renderMenuItem },
            { key: 8, text: 'Canceled', onRender: this.renderMenuItem },
            { key: 9, text: 'Closed', onRender: this.renderMenuItem },
            { key: 10, text: 'Feedback', onRender: this.renderMenuItem },
            { key: 11, text: 'Needs Allocation', onRender: this.renderMenuItem }
        ];
    }

    componentDidMount() {

        this.setState({
            selected: this.props.status,
            // menuPropsItems: this.getStatusItems(itemsType)
        });
    }

    componentDidUpdate() {
        const { status } = this.props;
        if (this.state.selected !== status) {
            this.setState({ selected: status });
        }

    }

    setSelected = ({ target }) => {
        const { onChange } = this.props;
        this.setState({ selected: target.outerText });
        onChange && onChange(target.outerText);
    }

    renderMenuItem = (data, dismissMenu) => {
        const { onChange } = this.props;
        return (
            <div onClick={() => {
                dismissMenu();
                onChange && onChange(data.text);
                this.setState({ selected: data.text });
            }}
                role="presentation"
                className="ms-ContextualMenu-item item-148">
                <button className="ms-ContextualMenu-link option-button">
                    <div className="ms-ContextualMenu-linkContent linkContent-154">
                        <span className="ms-ContextualMenu-itemText label-160 d-flex align-items-center">
                            <span className={`status-circle status-${data.text?.replace(' ', '-')} fa fa-circle mr-2`}></span>
                            {data.text}
                        </span>
                    </div>
                </button>
            </div>
        )
    }

    renderLabel = () => {
        const { selected } = this.state;
        return (
            <div key="renderLabel">
                <span className={`status-circle status-${selected?.replace(' ', '-')} fa fa-circle mr-2`}></span>
                {selected}
            </div>
        )
    }

    getStatusItems() {
        const { itemsType } = this.props;

        let items = [];
        switch (itemsType) {
            case 'TRM':
                items = this.trmStatusItems
                break;

            case 'ALLOCATION_BOARD':
                items = this.allocationBoardStatusItems
                break;
            default: break;
        }
        return items;
    }


    render() {
        const { selected } = this.state;

        if (this.props.readOnly === true) {
            return (<div className="ms-ContextualMenu-linkContent linkContent-154">
                <span className="ms-ContextualMenu-itemText label-160 d-flex align-items-center">
                    <span className={`status-circle status-${selected?.replace(' ', '-')} fa fa-circle mr-2`}></span>
                    {selected}
                </span>
            </div>)
        }

        const menuProps = {
            shouldFocusOnMount: true,
            items: this.getStatusItems()
        }

        if (!this.props.hideAllStatusOption) {
            const allElement = { key: 0, text: 'All Status', onClick: this.setSelected };
            menuProps.items = [allElement].concat(menuProps.items);
        }

        return <DefaultButton
            disabled={this.props.disabled}
            onRenderText={this.renderLabel}
            text={selected}
            menuProps={menuProps}
            styles={{ textContainer: { textAlign: 'left' } }}
            className="border-0 status-button px-1"
        />;
    }
};

export default Status;