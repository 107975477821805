import React, { createRef } from 'react';
import { DirectionalHint, HoverCard, HoverCardType, Icon } from '@fluentui/react';
import ImportantNote from '../ImportantNote/ImportantNote';

class GridImportantNoteCell extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isEditing: false
        }
        this.hoverCardRef = createRef(null);
    }

    renderCompactCard(rowDetail, readOnly) {
        const { isEditing } = this.state;

        return (
            <div className='d-flex flex-column'
                style={{
                    borderRadius: 2,
                    border: 'solid 1px #ddd'
                }}>
                {
                    isEditing &&
                    <div className='d-flex justify-content-between align-items-center pt-1'>
                        <div style={{ marginRight: 'auto', paddingLeft: 5 }}>
                            <h6 className='m-0'>Important JO Note</h6>
                        </div>
                        <button
                            style={{
                                borderRadius: 2,
                                justifyContent: 'center',
                                fontSize: '12px',
                                display: 'inline-flex',
                                alignItems: 'center',
                                backgroundColor: 'red',
                                color: 'white',
                                fontWeight: 600,
                                border: 'none',
                                marginRight: 5
                            }}
                            onClick={() => this.hoverCardRef.current.dismiss()}
                        >X</button>
                    </div>
                }
                <div style={{
                    padding: isEditing ? 5 : 0
                }}>
                    <ImportantNote
                        readOnly={readOnly}
                        jobOrder={rowDetail.data}
                        onSaved={(importantNote) => {
                            rowDetail.node.data.allocation_important_note = importantNote;
                            rowDetail.node.setData(rowDetail.node.data);
                        }}
                        onEditChange={isEditing => this.setState({ isEditing })}
                    />
                </div>
            </div >
        )
    }

    render() {
        const { isEditing } = this.state;
        const { rowDetail, readOnly, importantNote } = this.props;

        return (
            <HoverCard
                onCardHide={() => this.setState({ isEditing: false })}
                componentRef={this.hoverCardRef}
                sticky={isEditing}
                instantOpenOnClick={false}
                type={HoverCardType.plain}
                plainCardProps={{
                    directionalHint: DirectionalHint.rightCenter,
                    onRenderPlainCard: () => this.renderCompactCard(rowDetail, readOnly)
                }}
            >
                {importantNote ? <Icon iconName="QuickNote" /> : "-"}
            </HoverCard>
        )
    }
};


export default GridImportantNoteCell;
