import React from 'react';
import { connect } from 'react-redux';
import { CommandButton } from '@fluentui/react/lib/Button';
import { ContextualMenuItemType } from '@fluentui/react';
import './GridActionsCell.css';
import { addNotification } from '../../../store/actions';
import { validatePermission } from '../../../utils/auth';
import apiService from '../../../services/api-service';
import { Field } from '../../../constants/constants';

class GridActionsCell extends React.Component {

    getSelectedJobOrders() {
        const { selectedRows, rowDetail } = this.props;

        const selectedJobOrder = rowDetail.data;

        let jobOrders = [selectedJobOrder];
        if (!!selectedRows?.length) {
            const rest = selectedRows.filter(x => x.pafo_joborderid !== selectedJobOrder.pafo_joborderid);
            jobOrders = jobOrders.concat(rest);
        }

        return jobOrders;
    }

    getJobOrdersGroupBy(propertyName) {
        const selectedJobOrders = this.getSelectedJobOrders();

        const trueJobOrders = selectedJobOrders.filter(x => x[propertyName]);
        const falseJobOrders = selectedJobOrders.filter(x => !x[propertyName]);

        return [trueJobOrders, falseJobOrders];
    }

    setMicroteam(microteam) {
        const { rowDetail } = this.props;

        const jobOrderIds = this.getSelectedJobOrders().map(x => x.pafo_joborderid);

        jobOrderIds.forEach(jobOrderId => {
            const rowNode = rowDetail.api.getRowNode(jobOrderId);
            rowNode.data.allocation_board_microteam_id = microteam.microteamid;
            rowNode.setData(rowNode.data);
            const column = rowDetail.columnApi.getColumn("mt");
            if (column?.colId) {
                rowDetail.api.refreshCells({ rowNodes: [rowNode], force: true });
            }
        });

        apiService.assignOrRemoveMicroteam(microteam.microteamid, jobOrderIds)
            .then(data => {
                this.notify(data, 'Microteam updated succesfully');
            });
    }

    setPriority() {
        const { rowDetail } = this.props;

        rowDetail.api.startEditingCell({
            rowIndex: rowDetail.node.rowIndex,
            colKey: Field.allocation_board_priority
        })
    }

    setExclusive() {
        const { rowDetail } = this.props;

        const jobOrdersGrouped = this.getJobOrdersGroupBy(Field.job_order_is_exclusive);

        jobOrdersGrouped.forEach(jobOrderGroup => {
            if (!jobOrderGroup?.length)
                return;

            const isExclusive = !jobOrderGroup[0].job_order_is_exclusive;
            const jobOrderIds = jobOrderGroup.map(x => x.pafo_joborderid);

            jobOrderGroup.forEach(j => {
                const rowNode = rowDetail.api.getRowNode(j.pafo_joborderid);
                rowNode.data.job_order_is_exclusive = isExclusive;
                rowNode.setData(rowNode.data);
            });

            apiService.assignOrRemoveIsExclusive(isExclusive, jobOrderIds)
                .then(data => {
                    this.notify(data, isExclusive ? 'Added to the list of exclusives' : 'Removed from the exclusive list');
                })
        });
    }

    setHotlist() {
        const { rowDetail } = this.props;

        const jobOrdersGrouped = this.getJobOrdersGroupBy(Field.job_order_is_hotlist_filterable);

        jobOrdersGrouped.forEach(jobOrderGroup => {
            if (!jobOrderGroup?.length)
                return;

            const isHotlist = !jobOrderGroup[0].job_order_is_hotlist_filterable;
            const jobOrderIds = jobOrderGroup.map(x => x.pafo_joborderid);

            jobOrderGroup.forEach(j => {
                const rowNode = rowDetail.api.getRowNode(j.pafo_joborderid);
                rowNode.data.job_order_is_hotlist_filterable = isHotlist;
                rowNode.setData(rowNode.data);
            });

            apiService.assignOrRemoveIsHotlist(isHotlist, jobOrderIds)
                .then(data => {
                    this.notify(data, isHotlist ? 'Added to the hotlist' : 'Removed from the hotlist');
                })
        });
    }

    notify(isSuccess, message) {
        this.props.addNotification({
            type: isSuccess ? 'success' : 'error',
            text: isSuccess ? message : 'Something went wrong'
        });
    }

    render() {
        const { user, isDelegate, microteams } = this.props;

        if (!isDelegate && !validatePermission('SHOW_ACTIONS', user.roleName)) {
            return null;
        }

        const menuItems = [{
            key: 1,
            text: 'ACTIONS',
            itemType: ContextualMenuItemType.Header
        }, {
            key: 2,
            text: 'Exclusive',
            iconProps: { iconName: 'TagSolid', style: { color: 'green' } },
            onClick: () => this.setExclusive()
        }, {
            key: 3,
            text: 'Hotlist',
            iconProps: { iconName: 'FlameSolid', style: { color: 'salmon' } },
            onClick: () => this.setHotlist()
        }, {
            key: 4,
            text: 'Change Priority',
            iconProps: { iconName: 'NumberedList' },
            onClick: () => this.setPriority()
        }];

        if (!!microteams?.length) {
            const mtHeader = {
                key: 'microteams',
                text: 'ADD TO MICROTEAM',
                itemType: ContextualMenuItemType.Header
            };

            const mtItems = microteams.map(mt => ({
                key: mt.microteamid,
                text: mt.microteamname,
                onClick: () => this.setMicroteam(mt)
            }));

            menuItems.push(mtHeader, ...mtItems);
        }

        return <CommandButton
            className="action-table"
            text=""
            iconProps={{ iconName: 'More' }}
            menuProps={{
                shouldFocusOnMount: true,
                items: menuItems
            }}
        />;
    }
};

const mapStateToProps = state => ({
    user: state.session.user
})

const mapDispatchToProps = (dispatch) => ({
    addNotification: not => dispatch(addNotification(not))
})

export default connect(mapStateToProps, mapDispatchToProps)(GridActionsCell);
