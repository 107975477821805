import Status from "../../../shared/Status/Status";
import ActionsHeader from './ActionsHeader/ActionsHeader';
import RecruitersCell from "./RecruitersCell/RecruitersCell";
import ImportantNoteCell from "./ImportantNoteCell/ImportantNoteCell";
import { build } from "../../../../utils/columns";

// type AgGridColumnProps[] | AgGridColumnGroupProps[]
export const columnsDef = [
    build.trm_status(),
    build.allocation_board_priority(),
    build.department_display_name(),
    build.account_name(),
    build.opportunityname(),
    build.stepname(),
    build.kf_practicearea(),
    build.kf_servicecapabilityprimary(),
    build.kf_engagementmodel(),
    build.trm_jobtitle(),
    build.allocation_important_note(
        params => <ImportantNoteCell rowDetail={params} />
    ),
    build.kf_advancedservicesrequired(),
    build.pafo_foid(),
    build.trm_clientrequisitionnumber(),
    build.createdon(),
    build.pafo_estimatedstartdate(),
    build.pafo_quantity(),
    build.trm_priority(),
    build.trm_rank(),
    build.external_submittals(),
    build.sendouts(),
    build.allocation_board_status(
        params => (params.value && params.value !== '') && (
            <Status
                disabled={true}
                hideAllStatusOption={true}
                readOnly={true}
                status={params.value}
                itemsType="ALLOCATION_BOARD"
            />
        )
    ),
    build.trm_worksitecity(),
    build.trm_worksitestate(),
    build.trm_remotework(),
    build.trm_issjoborder(),
    build.trm_vsdjoborder(),
    build.trm_posttoweb(),
    build.trm_post_to_ilabor(),
    build.pafo_jobtype(),
    build.pafo_billrangehigh(),
    build.trm_salaryrangeto(),
    build.owner_fullname(),
    build.hiringmanager(),
    build.recruiters(
        params => <RecruitersCell recruiters={params.value} />
    ),
    build.candidates(),
    // build._notes(
    //     params => <NotesCell jobOrder={params.data} />
    // ),
    build._actions(
        params => <ActionsHeader rowDetail={params} columnsDef={columnsDef} />
    )
].map((row, i) => ({ order: i, ...row }))