import React from 'react';

class Details extends React.Component {

    render() {
        const { items } = this.props;

        return (
            <div className='d-flex flex-column' style={{ gap: 3 }}>
                {
                    items && items.map(item => (
                        <div className='row' key={item.label}>
                            <div className='col-4'>
                                {item?.label || ''}
                            </div>
                            <div className='col-8'>
                                <div style={{ overflow: 'auto', minHeight: '24px' }}>
                                    {
                                        item.onRenderValue?.(item?.value) || (
                                            <span className='pl-1'>
                                                <b>{item?.value}</b>
                                            </span>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        )
    }
}

export default Details;
