import * as React from 'react';
import './RecruiterList.css';
import { ActivityItem, List } from '@fluentui/react';
import CheckboxWithState from '../CheckboxWithState/CheckboxWithState';

class RecruiterList extends React.Component {
    ROWS_PER_PAGE = 3;
    MAX_ROW_HEIGHT = 250;
    
    constructor(props) {
        super(props);
        this.columnCount = React.createRef();
        this.rowHeight = React.createRef();

        this.getItemCountForPage = this.getItemCountForPage.bind(this);
        this.getPageHeight = this.getPageHeight.bind(this);
    }

    onSelectItem(checked, item) {
        if (checked === true) {
            item.isChecked = true;
            this.props.itemChecked(item);
        } else {
            item.isChecked = false;
            this.props.itemUnchecked(item);
        }
    }

    onRenderCell = (item, index) => {
        const { selectable, removable, disableSelectable } = this.props;

        return (
            <div className={`recruiter-list d-flex justify-content-between align-items-center col-12 ${!selectable ? 'pl-0' : 'pl-1'}`}>
                {
                    selectable &&
                    <CheckboxWithState
                        checked={item.isChecked === true}
                        useInternalState={true}
                        disabled={disableSelectable === true || item.disableSelectable === true}
                        onChange={(checked) => this.onSelectItem(checked, item)}
                    />
                }
                <ActivityItem
                    activityPersonas={[{ imageInitials: item.name || item.imageInitials, text: item.description || item.text }]}
                    className="d-flex align-items-center col-12 px-1 pb-1 jo-list"
                    activityDescription={item.description || item.text}
                    comments={item.additional}
                />
                {
                    removable && item.hideRemovable !== true &&
                    <i className="fa fa-close" onClick={() => this.props.itemRemoved(item)} />
                }
            </div>
        );
    };

    getItemCountForPage(itemIndex, surfaceRect) {
        if (itemIndex === 0) {
            this.columnCount.current = Math.ceil(surfaceRect.width / this.MAX_ROW_HEIGHT);
            this.rowHeight.current = Math.floor(surfaceRect.width / this.columnCount.current);
        }
        return this.columnCount.current * this.ROWS_PER_PAGE;
    }

    getPageHeight() {
        return this.rowHeight.current * this.ROWS_PER_PAGE;
    }

    render() {
        const { data, maxHeight } = this.props;
        return <List
            items={data}
            // onShouldVirtualize={() => false}
            getItemCountForPage={this.getItemCountForPage}
            getPageHeight={this.getPageHeight}
            renderedWindowsAhead={4}
            onRenderCell={this.onRenderCell}
            className="scrollbar"
            style={{ maxHeight: maxHeight || 200, overflowY: 'auto' }}
        />
    }
}

export default RecruiterList