import './PaginatedTable.css';
import React from 'react';
import ReactPaginate from 'react-paginate';
import { AgGridReact } from 'ag-grid-react';
import { ActionButton, Dropdown } from '@fluentui/react';

const optionsPerPage = [
    { key: 25, text: '25' },
    { key: 50, text: '50' },
    { key: 100, text: '100' },
    { key: 200, text: '200' }
];

const PaginatedTable = (props) => {
    const {
        data,
        currentPage,
        perPage,
        columns,
        frameworkComponents,
        onCellValueChanged,
        onRowDoubleClicked,
        onSelectionChanged,
        onRowClicked,
        onColumnResized,
        getRowNodeId,
        context,
        rowClassRules,
        onChangePerPage
    } = props;

    const handlePageClick = (data) => {
        const { perPage, onChangePage } = props;
        let skip = Math.ceil(data.selected * perPage);
        onChangePage(perPage, skip);
    };

    const dataCount = data.count || 0;

    return (
        <>
            <div className="paginate-options">
                <div>
                    {dataCount} <small>record{dataCount !== 1 && 's'}</small>
                </div>
                <div style={{ display: 'flex', alignContent: 'center' }}>
                    <ReactPaginate
                        previousLabel={<ActionButton iconProps={{ iconName: "Back" }}>Prev page</ActionButton>}
                        nextLabel={<ActionButton iconProps={{ iconName: "Forward" }}>Next page</ActionButton>}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        forcePage={currentPage}
                        pageCount={Math.ceil(dataCount / perPage)}
                        pageRangeDisplayed={8}
                        onPageChange={(data) => handlePageClick(data)}
                        containerClassName={'pagination'}
                        activeClassName={'active'}
                        renderOnZeroPageCount={null}
                    />
                </div>
                <div>
                    <Dropdown
                        dropdownWidth="auto"
                        placeholder="Select"
                        onChange={(i, val) => onChangePerPage(val.key)}
                        options={optionsPerPage}
                        selectedKey={perPage}
                    />
                </div>
            </div>
            <div style={{ overflow: 'hidden', flexGrow: '1' }}>
                <div className="ag-theme-alpine" style={{ width: '100%', height: '100%' }}>
                    <AgGridReact
                        defaultColDef={{
                            resizable: true,
                            suppressMovable: true,
                            sortable: false
                        }}
                        frameworkComponents={frameworkComponents}
                        onCellValueChanged={e => onCellValueChanged && onCellValueChanged(e)}
                        onRowClicked={e => onRowClicked?.(e)}
                        onRowDoubleClicked={e => onRowDoubleClicked?.(e)}
                        onSelectionChanged={e => onSelectionChanged && onSelectionChanged(e.api.getSelectedRows())}
                        onColumnResized={e => onColumnResized && onColumnResized(e)}
                        suppressRowClickSelection={true}
                        animateRows={true}
                        showLoadingOverlay={true}
                        pagination={false}
                        applyColumnDefOrder={true}
                        rowSelection="multiple"
                        rowData={data.results || []}
                        context={context}
                        getRowNodeId={getRowNodeId ? getRowNodeId : rowData => rowData.id}
                        rowClassRules={rowClassRules ? rowClassRules : {}}
                        suppressPropertyNamesCheck={true}
                        columnDefs={columns}
                    />
                </div>
            </div>
        </>
    )
}

export default PaginatedTable;