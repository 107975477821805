import React, { createRef } from 'react';
import { connect, batch } from 'react-redux';
import { Dropdown, PrimaryButton, Spinner } from '@fluentui/react';
import apiService from '../../../../services/api-service';
import {
    setAllOrdersLoading,
    setAllOrdersData,
    setAllOrdersPagination,
    setAllOrdersSearchText,
    setAllOrdersFilters,
    setAllOrdersFilterOptions,
    setAllOrdersFirms,
    setAllOrdersSelectedFirm,
    setAllOrdersRegions,
    setAllOrdersSelectedRegion,
    setAllOrdersMarkets,
    setAllOrdersSelectedMarket,
    setAllOrdersOffices,
    setAllOrdersSelectedOffice,
    setAllOrdersDepartments,
    setAllOrdersSelectedDepartment,
    setAllOrdersHierarchy,
    setAllOrdersBreadcrumbs
} from '../../../../store/actions';
import allOrdersService from '../../../../services/allorders-service';
import { Hierarchy } from '../../../../constants/constants';
import { getCancelTokenSource } from '../../../../api';


class GeneralFiltersSection extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectionChanged: true
        }

        this.source = createRef();
    }

    componentWillUnmount() {
        this.source.current?.cancel?.();
    }

    componentDidMount() {
        const { regions } = this.props;

        if (!regions || regions.length === 0) {
            this.getFirms();
            this.onSelectFirm({ key: 'Kforce', text: 'Kforce' });
        }
    }

    getFirms() {
        const firms = [{
            key: 'Kforce',
            text: 'Kforce'
        }];
        this.props.setAllOrdersFirms(firms);
    }

    onSelectFirm(selected) {
        const { pagination } = this.props;
        batch(() => {
            this.props.setAllOrdersSelectedFirm(selected);
            // this.props.setAllOrdersRegions([]);
            this.props.setAllOrdersSelectedRegion(null);
            this.props.setAllOrdersMarkets([]);
            this.props.setAllOrdersSelectedMarket(null);
            this.props.setAllOrdersOffices([]);
            this.props.setAllOrdersSelectedOffice(null);
            this.props.setAllOrdersDepartments([]);
            this.props.setAllOrdersSelectedDepartment(null);
            this.props.setAllOrdersData([]);
            this.props.setAllOrdersPagination(pagination.size, 0);
        });
        this.getRegions();
        this.setState({ selectionChanged: true });
    }

    getRegions() {
        const { regions } = this.props;

        if (regions && regions.length > 0) {
            return;
        }

        apiService
            .getRegions()
            .then(result => {
                const regions = result.map(r => ({
                    key: r,
                    text: r
                }));
                this.props.setAllOrdersRegions(regions);
            })
            .catch(error => console.error(error));

    }

    onSelectRegion(selected) {
        const { pagination } = this.props;
        batch(() => {
            this.props.setAllOrdersSelectedRegion(selected);
            this.props.setAllOrdersMarkets([]);
            this.props.setAllOrdersSelectedMarket(null);
            this.props.setAllOrdersOffices([]);
            this.props.setAllOrdersSelectedOffice(null);
            this.props.setAllOrdersDepartments([]);
            this.props.setAllOrdersSelectedDepartment(null);
            this.props.setAllOrdersData([]);
            this.props.setAllOrdersPagination(pagination.size, 0);
        });
        this.getMarkets(selected);
        this.setState({ selectionChanged: true });
    }

    getMarkets(selectedRegion) {
        apiService
            .getMarketsByRegion(selectedRegion.text)
            .then(result => {
                const markets = result.map(r => ({
                    key: r,
                    text: r
                }));
                this.props.setAllOrdersMarkets(markets);
            })
            .catch(error => console.error(error));
    }

    onSelectMarket(selected) {
        const { pagination } = this.props;
        batch(() => {
            this.props.setAllOrdersSelectedMarket(selected);
            this.props.setAllOrdersOffices([]);
            this.props.setAllOrdersSelectedOffice(null);
            this.props.setAllOrdersDepartments([]);
            this.props.setAllOrdersSelectedDepartment(null);
            this.props.setAllOrdersData([]);
            this.props.setAllOrdersPagination(pagination.size, 0);
        });
        this.getOffices(selected);
        this.setState({ selectionChanged: true });
    }

    getOffices(selectedMarket) {
        apiService
            .getOfficesByMarket(selectedMarket.text)
            .then(result => {
                const offices = result.map(r => ({
                    key: r,
                    text: r
                }));
                this.props.setAllOrdersOffices(offices);
            })
            .catch(error => console.error(error));
    }

    onSelectOffice(selected) {
        const { pagination } = this.props;
        batch(() => {
            this.props.setAllOrdersSelectedOffice(selected);
            this.props.setAllOrdersDepartments([]);
            this.props.setAllOrdersSelectedDepartment(null);
            this.props.setAllOrdersData([]);
            this.props.setAllOrdersPagination(pagination.size, 0);
        });
        this.getDepartments(selected);
        this.setState({ selectionChanged: true });
    }

    getDepartments(selectedOffice) {
        apiService
            .getDepartmentsByOffice(selectedOffice.text)
            .then(result => {
                const departments = result.map(r => ({
                    key: r.kf_financialhierarchyid,
                    text: r.kf_departmentdisplayname
                }));
                this.props.setAllOrdersDepartments(departments);
            })
            .catch(error => console.error(error));
    }

    onSelectDepartment(selected) {
        const { pagination } = this.props;
        batch(() => {
            this.props.setAllOrdersSelectedDepartment(selected);
            this.props.setAllOrdersData([]);
            this.props.setAllOrdersPagination(pagination.size, 0);
        })
        this.setState({ selectionChanged: true });
    }

    getHierarchy() {
        const { selectedFirm, selectedRegion, selectedMarket, selectedOffice, selectedDepartment } = this.props;

        if (selectedDepartment)
            return { by: Hierarchy.department, name: selectedDepartment.key };
        if (selectedOffice)
            return { by: Hierarchy.office, name: selectedOffice.key };
        if (selectedMarket)
            return { by: Hierarchy.market, name: selectedMarket.key };
        if (selectedRegion)
            return { by: Hierarchy.region, name: selectedRegion.key };
        if (selectedFirm)
            return { by: Hierarchy.firm, name: selectedFirm.key };
    }

    search() {
        const { pagination, user, tableFilters, selectedFirm, selectedRegion, selectedMarket, selectedOffice, selectedDepartment } = this.props;

        const hierarchy = this.getHierarchy();
        const groupId = allOrdersService.getGroupId(hierarchy);
        const filters = allOrdersService.mapFiltersToAPI(user.settings.allOrdersPage.filter.filters.filter(x => x.group === groupId));

        if (tableFilters && tableFilters.length > 0) {
            filters.push(...tableFilters);
        }

        const filtersForFilters = allOrdersService.getFiltersForFilters(filters);
        const orderBy = allOrdersService.createGroupBy(user.settings.allOrdersPage.sort);

        const breadcrumbs = [
            selectedFirm?.text, 
            selectedRegion?.text, 
            selectedMarket?.text, 
            selectedOffice?.text, 
            selectedDepartment?.text
        ].filter(x => !!x);

        batch(() => {
            this.props.setAllOrdersSearchText('');
            this.props.setAllOrdersFilters([]);
            this.props.setAllOrdersFilterOptions([]);
            this.props.setAllOrdersHierarchy(hierarchy);
            this.props.setAllOrdersLoading(true);
            this.props.setAllOrdersBreadcrumbs(breadcrumbs);
        })

        const isDepartmentHierarchy = hierarchy.by === Hierarchy.department;

        this.source.current?.cancel?.();
        this.source.current = getCancelTokenSource();

        if ([Hierarchy.department, Hierarchy.firm].includes(hierarchy.by)) {
            if (isDepartmentHierarchy) {
                const departmentFilter = allOrdersService.createMandatoryFilter(hierarchy.name);
                filters.push(departmentFilter);
                filtersForFilters.push(departmentFilter);
            }
            apiService
                .getJobOrders(filters, pagination.size, 0, '', orderBy, this.source.current.token)
                .then(data => {
                    batch(() => {
                        this.props.setAllOrdersData(data);
                        this.props.setAllOrdersPagination(pagination.size, 0);
                        this.props.setAllOrdersLoading(false);
                    })
                });

            apiService
                .getJobOrderFilters(filtersForFilters, !isDepartmentHierarchy, this.source.current.token)
                .then(data => {
                    const filterOptions = allOrdersService.createFilterOptions(data);
                    this.props.setAllOrdersFilterOptions(filterOptions);
                });
        } else {
            apiService
                .getJobOrdersByHierarchy(hierarchy.by, hierarchy.name, filters, pagination.size, 0, '', orderBy, this.source.current.token)
                .then(data => {
                    batch(() => {
                        this.props.setAllOrdersData(data);
                        this.props.setAllOrdersPagination(pagination.size, 0);
                        this.props.setAllOrdersLoading(false);
                    })
                });

            apiService
                .getJobOrderByHierarchyFilters(hierarchy.by, hierarchy.name, filtersForFilters, !isDepartmentHierarchy, this.source.current.token)
                .then(data => {
                    const filterOptions = allOrdersService.createFilterOptions(data);
                    this.props.setAllOrdersFilterOptions(filterOptions);
                });
        }

        this.setState({ selectionChanged: false });
    }

    render() {
        const { selectionChanged } = this.state;
        const { firms, regions, markets, offices, departments } = this.props;
        const { selectedFirm, selectedRegion, selectedMarket, selectedOffice, selectedDepartment } = this.props;

        return (
            <div className="mb-0 mt-2">
                <div className="col-12 p-0 row mb-0">
                    {
                        firms ?
                            <Dropdown
                                className="col-2"
                                placeholder="-- Select --"
                                label="Firm"
                                onChange={(i, val) => this.onSelectFirm(val)}
                                options={firms}
                                selectedKey={selectedFirm?.text ?? ''}
                                disabled={false}
                            />
                            :
                            <div className="mt-3 col-2">
                                <Spinner label="Loading firms..." ariaLive="assertive" labelPosition="right" />
                            </div>
                    }
                    {
                        regions ?
                            <Dropdown
                                className="col-2"
                                placeholder="-- Select --"
                                label="Region"
                                onChange={(i, val) => this.onSelectRegion(val)}
                                options={regions}
                                selectedKey={selectedRegion?.text ?? ''}
                                disabled={!selectedFirm}
                            />
                            :
                            <div className="mt-3 col-2">
                                <Spinner label="Loading regions..." ariaLive="assertive" labelPosition="right" />
                            </div>
                    }
                    {
                        markets ?
                            <Dropdown
                                className="col-2"
                                placeholder="-- Select --"
                                label="Market"
                                onChange={(i, val) => this.onSelectMarket(val)}
                                options={markets}
                                selectedKey={selectedMarket?.text ?? ''}
                                disabled={!selectedRegion}
                            />
                            :
                            <div className="mt-3 col-2">
                                <Spinner label="Loading markets..." ariaLive="assertive" labelPosition="right" />
                            </div>
                    }
                    {
                        offices ?
                            <Dropdown
                                className="col-2"
                                placeholder="-- Select --"
                                label="Office"
                                onChange={(i, val) => this.onSelectOffice(val)}
                                options={offices}
                                selectedKey={selectedOffice?.text ?? ''}
                                disabled={!selectedMarket}
                            />
                            :
                            <div className="mt-3 col-2">
                                <Spinner label="Loading markets..." ariaLive="assertive" labelPosition="right" />
                            </div>
                    }
                    {
                        departments ?
                            <Dropdown
                                className="col-2"
                                placeholder="-- Select --"
                                label="Department"
                                onChange={(i, val) => this.onSelectDepartment(val)}
                                options={departments}
                                selectedKey={selectedDepartment?.key ?? ''}
                                disabled={!selectedOffice}
                            />
                            :
                            <div className="mt-3 col-2">
                                <Spinner label="Loading departments..." ariaLive="assertive" labelPosition="right" />
                            </div>
                    }

                    <PrimaryButton
                        disabled={!selectionChanged || (!selectedFirm && !selectedRegion && !selectedMarket && !selectedOffice && !selectedDepartment)}
                        onClick={() => this.search(selectedDepartment)}
                        className="d-flex align-self-end">
                        Search
                    </PrimaryButton>
                </div>
            </div>
        )
    }
}


const mapStateToProps = state => ({
    user: state.session.user,
    pagination: state.allorders.pagination,
    tableFilters: state.allorders.filters,
    firms: state.allorders.firms,
    selectedFirm: state.allorders.selectedFirm,
    regions: state.allorders.regions,
    selectedRegion: state.allorders.selectedRegion,
    markets: state.allorders.markets,
    selectedMarket: state.allorders.selectedMarket,
    offices: state.allorders.offices,
    selectedOffice: state.allorders.selectedOffice,
    departments: state.allorders.departments,
    selectedDepartment: state.allorders.selectedDepartment,
})

const mapDispatchToProps = {
    setAllOrdersLoading,
    setAllOrdersData,
    setAllOrdersPagination,
    setAllOrdersSearchText,
    setAllOrdersFilters,
    setAllOrdersFilterOptions,

    setAllOrdersFirms,
    setAllOrdersSelectedFirm,
    setAllOrdersRegions,
    setAllOrdersSelectedRegion,
    setAllOrdersMarkets,
    setAllOrdersSelectedMarket,
    setAllOrdersOffices,
    setAllOrdersSelectedOffice,
    setAllOrdersDepartments,
    setAllOrdersSelectedDepartment,
    setAllOrdersHierarchy,
    setAllOrdersBreadcrumbs
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GeneralFiltersSection)