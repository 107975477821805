import React from 'react';
import { connect } from 'react-redux';
import GridActionsCell from '../../../../shared/GridActionsCell/GridActionsCell';

class ActionsCell extends React.Component {

    render() {
        const { rowDetail, selectedRows, isDelegate } = this.props;

        return <GridActionsCell
            rowDetail={rowDetail}
            selectedRows={selectedRows}
            isDelegate={isDelegate}
        />
    }
};

const mapStateToProps = state => ({
    isDelegate: state.consolidated.isDelegate,
    selectedRows: state.consolidated.selectedRows
})

export default connect(mapStateToProps)(ActionsCell);
