import '../../App.css';
import React from 'react';
import { connect } from 'react-redux';
import TitleSection from './TitleSection/TitleSection';
import GeneralFiltersSection from './GeneralFiltersSection/GeneralFiltersSection';
import OptionsSection from './OptionsSection/OptionsSection';
import TableSection from './TableSection/TableSection';

class AllOrders extends React.Component {

    clearAllFilters() {

    }

    render() {
        const { selectedFirm, tableData } = this.props;

        const showData = selectedFirm && tableData?.results;

        return (
            <div className="p-3 bg-white" id="appContent" style={{ width: '100%', height: '100%' }}>
                <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                    <div className="mb-2 d-sm-block d-md-flex justify-content-between align-items-center">
                        <TitleSection />
                    </div>
                    <div>
                        <GeneralFiltersSection />
                    </div>
                    <div>
                        <OptionsSection showData={showData} />
                    </div>
                    <TableSection showData={showData} />
                </div>
            </div>
        )

    }
}

const mapStateToProps = state => ({
    selectedFirm: state.allorders.selectedFirm,
    tableData: state.allorders.data
})

export default connect(mapStateToProps)(AllOrders);