import React, { createRef } from 'react';
import { connect, batch } from 'react-redux';
import {
    setAllOrdersLoading,
    setAllOrdersData,
    setAllOrdersPagination,
    setAllOrdersColumns,
    setAllOrdersFilters,
    setUser
} from '../../../../../store/actions';
import apiService from '../../../../../services/api-service';
import { getCancelTokenSource } from '../../../../../api';
import allOrdersService from '../../../../../services/allorders-service';
import { Page, Hierarchy } from '../../../../../constants/constants';
import GridColumnHeader from '../../../../shared/GridColumnHeader/GridColumnHeader';

class ColumnHeader extends React.Component {

    constructor(props) {
        super(props);
        this.applyPageFilters = this.applyPageFilters.bind(this);
        this.refreshTableData = this.refreshTableData.bind(this);
        this.source = createRef();
    }

    componentWillUnmount() {
        this.source.current?.cancel?.();
    }

    applyPageFilters(filters) {
        this.props.setAllOrdersFilters(filters);
    }

    refreshTableData(orderBy, tableFilters, resetPagination) {
        const { user, tableSearchText, tablePagination, hierarchy } = this.props;

        const groupId = allOrdersService.getGroupId(hierarchy);
        const filters = allOrdersService.mapFiltersToAPI(user.settings.allOrdersPage.filter.filters.filter(x => x.group === groupId));

        if (tableFilters && tableFilters.length > 0) {
            filters.push(...tableFilters);
        }

        const skip = resetPagination
            ? 0
            : tablePagination.skip;

        batch(() => {
            this.props.setAllOrdersLoading(true);
            this.props.setAllOrdersFilters(tableFilters);
            if (resetPagination) {
                this.props.setAllOrdersPagination(tablePagination.size, skip);
            }
        })

        this.source.current?.cancel?.();
        this.source.current = getCancelTokenSource();

        if ([Hierarchy.department, Hierarchy.firm].includes(hierarchy.by)) {
            if (hierarchy.by === Hierarchy.department) {
                filters.push(allOrdersService.createMandatoryFilter(hierarchy.name));
            }
            apiService
                .getJobOrders(filters, tablePagination.size, skip, tableSearchText, orderBy, this.source.current.token)
                .then(data => {
                    batch(() => {
                        this.props.setAllOrdersData(data);
                        this.props.setAllOrdersLoading(false);
                    })
                });
        } else {
            apiService
                .getJobOrdersByHierarchy(hierarchy.by, hierarchy.name, filters, tablePagination.size, skip, tableSearchText, orderBy, this.source.current.token)
                .then(data => {
                    batch(() => {
                        this.props.setAllOrdersData(data);
                        this.props.setAllOrdersLoading(false);
                    })
                });
        }
    }

    render() {
        const { column, displayName, sort, tableFilters, filterOptions } = this.props;

        return (
            <GridColumnHeader
                column={column}
                filterOptions={filterOptions}
                displayName={displayName}
                userSort={sort}
                tableFilters={tableFilters}
                settingsPage={Page.allOrders}
                onRefreshTableData={this.refreshTableData}
                onApplyPageFilters={this.applyPageFilters}
            />
        )
    }
}

const mapStatetoProps = state => ({
    user: state.session.user,
    sort: state.session.user.settings.allOrdersPage.sort,
    tableSearchText: state.allorders.searchText,
    tablePagination: state.allorders.pagination,
    tableFilters: state.allorders.filters,
    filterOptions: state.allorders.filterOptions,
    hierarchy: state.allorders.hierarchy
})

const mapDispatchToProps = (dispatch) => ({
    setUser: user => dispatch(setUser(user)),
    setAllOrdersLoading: isLoading => dispatch(setAllOrdersLoading(isLoading)),
    setAllOrdersData: data => dispatch(setAllOrdersData(data)),
    setAllOrdersPagination: (size, skip) => dispatch(setAllOrdersPagination(size, skip)),
    setAllOrdersColumns: columns => dispatch(setAllOrdersColumns(columns)),
    setAllOrdersFilters: filters => dispatch(setAllOrdersFilters(filters))
})

export default connect(mapStatetoProps, mapDispatchToProps)(ColumnHeader);